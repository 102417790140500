import CrudStore from '@/lib/crud-store.js';
var crud = new CrudStore('rctis');

const state = {
  ...crud.state
};

const getters = {
  ...crud.getters
};

const actions = {
  ...crud.actions,

  async doAction(store, obj) {
    let action = obj.action;
    let id = obj.id;
    let response = await crud.api.rctis.doAction(id, action);

    if (response) {
      store.commit('UPDATE', response);
    }
    return response;
  }
};

const mutations = {
  ...crud.mutations
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;
