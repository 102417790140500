<template>
  <div
    :class="[
      'c-accordion',
      { active: actuallyActive === true },
      { 'min-height': minHeight === true }
    ]"
  >
    <div class="c-accordion__header" @click="onToggle">
      <h2>{{ title }}</h2>
      <article v-if="description" v-html="description"></article>
      <chevron-down-icon size="1.5x"></chevron-down-icon>
    </div>
    <div class="c-accordion__body">
      <div class="c-accordion__body__inner">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon } from 'vue-feather-icons';

export default {
  components: {
    ChevronDownIcon
  },

  watch: {
    active: {
      handler: function() {
        if (this.active) {
          this.actuallyActive = true;
        }
      },
      immediate: true
    }
  },

  data: function() {
    return {
      actuallyActive: false
    };
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Accordion'
    },
    description: {
      type: String,
      default: null
    },
    minHeight: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onToggle: function() {
      this.actuallyActive = !this.actuallyActive;
    }
  }
};
</script>

<style lang="scss">
.c-accordion {
  margin: var(--space-lg) 0;

  &__header {
    cursor: pointer;
    padding: var(--space-sm) var(--space-md);
    padding-right: var(--space-xl);
    position: relative;
    user-select: none;

    display: flex;
    justify-content: center;
    flex-direction: column;

    background-color: var(--color-bg-3);
    border-radius: var(--border-radius-md);

    > article {
      margin-top: var(--margin-lg);
    }

    > h2 {
      font-size: var(--font-size-lg);
      font-weight: 600;
      line-height: 1.25;
      margin: 0;
    }

    svg {
      display: inline-block;
      position: absolute;
      top: calc(50% - 12px);
      right: var(--space-md);
      cursor: pointer;
      transform: rotate(0);
      transition: all var(--transition-time-lg) ease-out;
      opacity: 0.5;
    }
  }

  &__body {
    height: 0;
    overflow: hidden;
    border-bottom: transparent solid 1px;

    &__inner {
      padding: var(--space-md);

      .container-fluid {
        max-width: 66.666%;
        margin-left: 0;
      }
    }
  }

  &.active {
    .c-accordion__header {
      svg {
        opacity: 1;
        transform: rotate(180deg);
      }
    }
    .c-accordion__body {
      border-bottom-color: var(--g5);
      height: auto;
      overflow: visible;
    }
  }

  &.min-height {
    &.active {
      .c-accordion__body {
        min-height: 220px;
      }
    }
  }

  @media screen and (max-width: 428px) {
    &__header {
      padding: var(--margin-lg) 0;
    }
  }
}
</style>
