import CrudStore from '@/lib/crud-store.js';
var crud = new CrudStore('leads');

const state = {
  ...crud.state
};
const getters = {
  ...crud.getters,

  filtered: function(state) {
    let resources = state.resources;

    for (let i = 0; i < resources.length; i++) {
      let followupDate = null;
      let folloupUser = null;

      let dates = [];

      if (resources[i].notes) {
        for (let j = 0; j < resources[i].notes.length; j++) {
          if (resources[i].notes[j].followupDate) {
            dates.push({
              followupDate: resources[i].notes[j].followupDate,
              folloupUser: resources[i].notes[j].userId,
              createdAt: resources[i].notes[j].createdAt
            });
          }
        }
      }

      let now = new Date();
      now.setDate(now.getDate() - 1);

      // removes followupdates that were in the past
      dates = dates.filter(dateObj => {
        let temp = new Date(dateObj.followupDate);
        if (now >= temp) {
          return false;
        }
        return true;
      });

      // Sort by latest CREATEDAT date first, not followupdate
      if (dates.length > 0) {
        dates = dates.sort((a, b) => {
          let temp = new Date(b.createdAt) - new Date(a.createdAt);
          return temp;
        });

        followupDate = dates[0].followupDate;
        folloupUser = dates[0].folloupUser;
      }

      // assigning computed properties
      resources[i].followupDate = followupDate;
      resources[i].folloupUser = folloupUser;
    }

    return state.resources;
  },

  byOrganisation: function(state) {
    let resources = state.resources;

    return id => {
      let newResources = [];

      id = parseInt(id);

      if (isNaN(id)) {
        return null;
      }

      for (let i = 0; i < resources.length; i++) {
        if (resources[i].organisationId === id) {
          newResources.push(resources[i]);
        }
      }

      return newResources;
    };
  }
};
const actions = {
  ...crud.actions,

  async doAction(store, obj) {
    let action = obj.action;
    let id = obj.id;
    let response = await crud.api.leads.doAction(id, action);

    if (response) {
      store.commit('UPDATE', response);

      store.dispatch('message/onResourceUpdate', `Leads have been updated`, {
        root: true
      });
    }
    return response;
  }
};
const mutations = {
  ...crud.mutations
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;
