<template>
  <div class="form-group c-input-number">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <input
      class="form-control"
      :id="slug"
      type="number"
      :value="value"
      @change="onChange"
      :placeholder="placeholder"
      :min="min"
      :max="max"
    />
    <InputError v-if="hasError" :message="errorMessage"></InputError>
  </div>
</template>

<script>
import InputError from './input-error';
import slugify from '@/lib/slugify';

export default {
  components: {
    InputError
  },

  props: {
    label: {
      type: String,
      default: ''
    },
    value: {},
    placeholder: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 9999
    }
  },

  computed: {
    slug: function() {
      return slugify(this.label);
    }
  },

  methods: {
    onChange: function(e) {
      let value = e.target.value;

      value = parseInt(value);

      this.$emit('input', value);
    }
  }
};
</script>

<style lang="scss">
.c-input-number {
}
</style>
