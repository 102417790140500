<template>
  <div
    :class="[
      'form-group c-input-note-status',
      { 'form-group--inline': inline }
    ]"
  >
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <v-select
      :value="computedValue"
      :options="computedOptions"
      @input="onChange"
    >
      <template #selected-option="resource">
        <span :class="['c-input-note-status__color', resource.color]"></span>
        <span>{{ resource.label }}</span>
      </template>
      <template #option="resource">
        <span :class="['c-input-note-status__color', resource.color]"></span>
        <span>{{ resource.label }}</span>
      </template>
    </v-select>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
  </div>
</template>

<script>
import InputError from './input-error';
import slugify from '@/lib/slugify';
import InputDescription from './input-description';

import { metrics } from '@/lib/enums';

export default {
  components: {
    InputError,
    InputDescription
  },

  data: function() {
    return {
      enums: {
        metrics
      },
      enum: 'metrics'
    };
  },

  props: {
    description: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    options: {
      type: String,
      default: null
    },
    validation: {},
    value: {},
    inline: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    computedOptions: function() {
      if (this.enum) {
        return this.enums[this.enum];
      } else {
        return [];
      }
    },
    computedValue: function() {
      let options = this.computedOptions;
      let value = this.value;
      let val = null;
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === value) {
          val = options[i].label;
        }
      }
      return val;
    },
    slug: function() {
      return slugify(this.label);
    },
    errorMessage: function() {
      if (!this.hasError) {
        return '';
      }

      return 'Please select a value';
    }
  },

  methods: {
    onChange: function(data) {
      let val = null;

      if (typeof data === 'string') {
        val = data;
      } else {
        val = data.value;
      }

      this.$emit('input', val);
    }
  },

  mounted: function() {}
};
</script>

<style lang="scss">
.c-input-note-status {
  .v-select {
    font-size: var(--font-size-sm);
  }
  .vs__dropdown-option {
    padding: var(--space-xs) var(--space-xs);
  }
  &__color {
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-right: var(--space-xs);
    border-radius: 50%;
    background-color: var(--primary);
    align-self: center;

    &.SUCCESS {
      background-color: var(--color-success);
    }
    &.FAILURE {
      background-color: var(--color-failure);
    }
  }
}
</style>
