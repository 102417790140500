import api from '@/lib/api';
import { clear } from 'localforage';

export default class {
  resourceName = '';
  constructor(name) {
    this.api = api;
    this.resourceName = name;

    // STATE
    this.state = {
      resources: [],

      addId: null,
      editId: null,
      viewId: null
    };

    // GETTERS
    this.getters = {
      /**
       * Returns all resources
       */
      all: function(state) {
        return state.resources;
      },

      /**
       * Returns resources where deleted is null
       * Modify this to your liking if you want filteres to be done at the this level
       *
       * Generally, you should be pulling all resources inside your components via this getter
       */
      filtered: function(state) {
        let resources = state.resources;
        if (resources.length === 0) {
          return state.resources;
        }

        let resource = resources[0];

        // Seems to be empty anyways
        if (!resource) {
          return [];
        }

        // Deleted key isn't there so no need to filter
        if (!('deleted' in resource)) {
          return resources;
        }

        resources = resources.filter(resource => {
          if (!resource.deleted) {
            return true;
          } else {
            return false;
          }
        });

        if ('updatedAt' in resources[0]) {
          resources = resources.sort((a, b) => {
            return new Date(a.updatedAt) - new Date(b.updatedAt);
          });
        }

        return resources;
      },

      /**
       * Returns only a single resource
       */
      one: function(state) {
        let resources = state.resources;

        return id => {
          let resource = null;

          id = parseInt(id);

          if (isNaN(id)) {
            return null;
          }

          for (let i = 0; i < resources.length; i++) {
            if (resources[i].id === id) {
              resource = resources[i];
              break;
            }
          }

          return resource;
        };
      },

      addId: function(state) {
        return state.addId;
      },

      editId: function(state) {
        return state.editId;
      },

      viewId: function(state) {
        return state.viewId;
      }
    };

    // MUTATIONS
    this.mutations = {
      ADD: function(state, item) {
        try {
          state.resources.push(item);
        } catch (err) {
          console.log(err);
          state.resources = [];
          state.resources.push(item);
        }
      },
      UPDATE: function(state, resource) {
        let id = resource.id;

        // Updates object in array and this method will also trigger vuex to re-render - which is exactly what we want
        state.resources = [
          ...state.resources.filter(resource => resource.id !== id),
          resource
        ];
      },
      SET: function(state, resources) {
        state.resources = resources;
      },
      REMOVE: function(state, id) {
        let resources = state.resources;
        resources = resources.filter(res => {
          if (res.id === id) {
            return false;
          } else {
            return true;
          }
        });
        state.resources = resources;
      },

      SET_ADD: (state, id) => {
        state.addId = id;
      },

      SET_EDIT: (state, id) => {
        state.editId = id;
      },

      SET_VIEW: (state, id) => {
        state.viewId = id;
      }
    };

    // ACTIONS
    this.actions = {
      async init(store) {},

      async afterLogin(store) {
        if (store.rootGetters['users/isAuth']) {
          await store.dispatch('get');
        }
      },

      async afterLogout(store) {
        store.dispatch('clear');
      },

      /**
       * Clears the store's state
       */
      async clear(store) {
        store.commit('SET', []);
        store.commit('SET_ADD', null);
        store.commit('SET_EDIT', null);
        store.commit('SET_VIEW', null);
      },

      /**
       * Gets all resources from the server
       */
      get: async store => {
        let resources = [];
        try {
          resources = await this.api[this.resourceName].get();
        } catch (err) {
          throw err;
        }
        store.commit('SET', resources);
        return resources;
      },

      /**
       *
       * Adds a new resource
       */
      add: async (store, resource) => {
        resource = await this.api[this.resourceName].add(resource);
        if (resource) {
          store.commit('ADD', resource);

          store.dispatch(
            'message/onResourceUpdate',
            `${this.resourceName} has been created`,
            { root: true }
          );
        }

        return resource;
      },

      /**
       * Updates a resource
       */
      update: async (store, resource) => {
        try {
          resource = await this.api[this.resourceName].update(resource);
        } catch (err) {
          throw err;
        }
        if (resource) {
          store.commit('UPDATE', resource);

          store.dispatch(
            'message/onResourceUpdate',
            `${this.resourceName} has been updated`,
            { root: true }
          );
        }

        return resource;
      },

      /**
       * Deletes a record
       *
       * Needs a fix
       */
      async delete(store, id) {
        let condition = false;
        const resourceName = 'orders';
        try {
          condition = await api[resourceName].delete(id);
        } catch (err) {
          console.log(err);
          throw err;
        }
        console.log(condition);
        if (condition) {
          store.commit('REMOVE', id);
        }

        return condition;
      },

      /**
       * Updates a single resource's data
       * The equivalent of fresh() in laravel
       */
      async refresh() {},

      /**
       * Updates all resource data
       * Basically, calls get() underneath the hood
       */
      async refreshAll(store) {
        return await store.dispatch('get');
      },

      /**
       * Use these actions to toggle modals or other components that rely on using a resource's id to do something in a one way direction.
       * You can pass a null as a param too, to turn that bit of logic off
       */
      async setAdd(store, id) {
        store.commit('SET_ADD', id);
      },
      async setEdit(store, id) {
        store.commit('SET_EDIT', id);
      },
      async setView(store, id) {
        store.commit('SET_VIEW', id);
      }
    };
  }
}
