function parseCondition(condition, form) {
  if (form[condition.key]) {
    // check if array
    if (typeof condition.value === 'object') {
      if (condition.value.includes(form[condition.key])) {
        return true;
      } else {
        return false;
      }
    }

    if (typeof condition.value === 'boolean') {
      if (form[condition.key]) {
        return true;
      } else {
        return false;
      }
    }

    // If condition.value is invalid
    throw new Error('condition object needs both a key and a value');
  } else {
    return false;
  }
}

function parseConditions(conditions, form) {
  let condition = true;
  for (let i = 0; i < conditions.length; i++) {
    let currentCondition = parseCondition(conditions[i], form);
    if (!currentCondition) {
      condition = false;
      break;
    }
  }

  return condition;
}

function conditionChecker(field, form) {
  if (!field.conditions) {
    return true;
  }

  let condition = parseConditions(field.conditions, form);

  return condition;
}

export default conditionChecker;
