<template>
  <div class="c-item-stage">
    <div class="c-item-stage__item">
      <span
        v-for="stage in orderStages"
        :key="stage.value"
        :style="`background-color:${stage.color};`"
        :class="[{ active: stage.value === resource.status }]"
        :title="stage.label"
      >
        <span>
          {{ stage.label }}
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { orderStages } from '@/lib/enums';

export default {
  props: {
    resource: {
      type: Object,
      default: function() {
        return {};
      }
    },
    column: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  computed: {
    orderStages: function() {
      return orderStages;
    },
    klass: function() {
      if (this.resource['status']) {
        return this.resource['status'].toLowerCase();
      }
    },
    key: function() {
      return status;
    }
  }
};
</script>

<style lang="scss">
.c-item-stage {
  &__item {
    display: inline-flex;
    border-radius: var(--border-radius-lg);
    //overflow: hidden;

    > span {
      position: relative;
      display: inline-block;
      padding: var(--margin-sm) var(--margin-lg);
      color: var(--color-white);
      text-align: center;
      font-size: var(--font-size-xs);
      font-weight: 500;
      //overflow: hidden;
      opacity: 0.8;

      &:first-child {
        border-top-left-radius: var(--border-radius-lg);
        border-bottom-left-radius: var(--border-radius-lg);
      }

      &:last-child {
        border-top-right-radius: var(--border-radius-lg);
        border-bottom-right-radius: var(--border-radius-lg);
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.075;
        background: linear-gradient(
          90deg,
          #000 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      > span {
        position: relative;
        z-index: 1;
      }

      &.active {
        color: #fff;
        padding: var(--margin-sm) var(--margin-lg);
        //transform: scale(1.25);
        z-index: 2;
        box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--primary);
        opacity: 1;

        &:after {
          background: none;
          //background: rgba(255, 255, 255, 1) !important;
          opacity: 0.15;
        }

        > span {
          opacity: 1;
        }
      }
    }
  }
}
</style>
