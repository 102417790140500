<template>
  <div
    :class="['form-group c-input-products', { 'form-group--inline': inline }]"
  >
    <label class="form__label" :for="slug">{{ label }}</label>
    <v-select
      :disabled="readonly === true"
      :value="selectValue"
      :options="computedOptions"
      label="label"
      @input="onChange"
      :multiple="true"
    ></v-select>

    <ul class="c-input-products__list" v-if="selectValue.length > 0">
      <li v-for="(option, i) in selectValue" :key="i">
        <article>
          <h4>
            {{ option.label }}
          </h4>
          <span
            ><strong>SKU:&nbsp;</strong><em>{{ option.resource.sku }}</em></span
          >&nbsp;
          <span
            ><strong>Price:&nbsp;</strong
            ><em>${{ option.resource.price }}</em></span
          >
          &nbsp;
          <span
            ><strong>Total:&nbsp;</strong>
            <em>
              ${{
                calculateTotalPrice(option.resource.price, option.quantity)
              }}&nbsp;x&nbsp;{{ option.resource.billingPeriod }}
            </em>
          </span>
        </article>

        <InputNumber
          :min="1"
          :max="999"
          :value="option.quantity"
          @input="updateQuantity($event, option)"
        ></InputNumber>
      </li>
    </ul>

    <InputError v-if="hasError" :message="errorMessage"></InputError>
  </div>
</template>

<script>
import InputError from './input-error';
import slugify from '@/lib/slugify';
import InputNumber from './input-number';

export default {
  components: {
    InputError,
    InputNumber
  },

  data: function() {
    return {
      optionKey: 'name',
      selectValue: []
    };
  },

  watch: {
    value: {
      handler: function() {
        this.setValue(this.value);
      },
      immediate: true
    }
  },

  props: {
    label: {
      type: String,
      default: 'Label'
    },
    value: {},
    inline: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    options: function() {
      return this.$store.getters['products/all'];
    },
    computedOptions: function() {
      let options = this.options;

      if (!options) {
        throw new Error('Relation doenst exist');
      }

      if (options.length === 0) {
        return [];
      }

      let labelKey = this.optionKey;

      let newOptions = [];

      for (let i = 0; i < options.length; i++) {
        let obj = {
          label: options[i][labelKey],
          quantity: 1,
          resource: options[i],
          value: options[i].id
        };

        newOptions.push(obj);
      }

      return newOptions;
    },
    slug: function() {
      return slugify(this.label);
    }
  },

  methods: {
    updateQuantity: function(e, option) {
      let values = this.selectValue;

      for (let i = 0; i < values.length; i++) {
        if (values[i].value === option.value) {
          values[i].quantity = e;
        }
      }

      this.selectValue = values;

      this.onChange(this.selectValue);
    },
    calculateTotalPrice: function(price, quantity) {
      let val = price * quantity;
      val = Math.round((val + Number.EPSILON) * 100) / 100;
      return val;
    },
    setValue: function(values) {
      if (!values) {
        return;
      }
      let options = this.options;
      let option = null;
      let selected = [];

      for (let i = 0; i < options.length; i++) {
        for (let j = 0; j < values.length; j++) {
          if (options[i].id === values[j].id) {
            selected.push({
              label: `${options[i].sku} - ${options[i].name}`,
              quantity: values[j].pivotQuantity,
              resource: options[i],
              value: options[i].id
            });
            break;
          }
        }
      }

      if (!selected) {
        return;
      }

      this.selectValue = selected;

      return;
    },
    transformValues: function(arr) {
      arr = arr.map(item => {
        return {
          id: item.value,
          pivotQuantity: item.quantity
        };
      });

      return arr;
    },
    onChange: function(data) {
      this.selectValue = data;
      let values = this.transformValues(data);
      this.$emit('input', values);
    }
  }
};
</script>

<style lang="scss">
.c-input-products {
  &__list {
    margin: 0;
    padding: 0;
    margin-top: var(--space-xs);
    list-style: none;
    display: flex;
    flex-direction: column;
    border: var(--color-input-border) solid 1px;
    border-radius: var(--border-input);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.08);

    li {
      margin: 0;
      padding: var(--space-xs);
      width: 100%;
      justify-content: space-between;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        border-bottom: #ddd solid 1px;
      }
      h4 {
        font-weight: 500;
        text-transform: initial;
        margin-bottom: 0;
      }
      .form-group {
        margin-top: 0;

        input {
          margin-top: 0;
        }
      }

      strong {
        font-weight: 400;
      }

      em {
        font-style: normal;
        font-family: var(--font-family-monospace);
        font-weight: 600;
      }
    }
  }

  .v-select {
    .vs__selected {
      border-color: transparent;
      border-radius: var(--border-radius-sm);
      line-height: 1.4;
      margin: var(--space-xs) var(--space-xs) var(--space-xs) 0;
      padding: var(--margin);
    }
  }
}
</style>
