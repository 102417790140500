<template>
  <div class="c-locations">
    <Dashboard>
      <DashboardHeader title="Manage Locations">
        <router-link
          v-if="!isReadOnly"
          to="/locations/create"
          class="btn btn-primary"
          >Add New</router-link
        >
      </DashboardHeader>
      <DashboardBody>
        <List
          :options="listOptions"
          :resources="resources"
          @click="onClick"
          :columns="columns"
        ></List>
      </DashboardBody>
    </Dashboard>
  </div>
</template>

<script>
import Dashboard from '@/components/dashboard';
import DashboardHeader from '@/components/dashboard/header';
import DashboardBody from '@/components/dashboard/body';

import Button from '@/components/global/button';

import List from '@/components/list';

export default {
  components: {
    Dashboard,
    DashboardHeader,
    DashboardBody,

    Button,

    List
  },

  computed: {
    isReadOnly: function() {
      return this.$store.getters['users/isReadOnly'];
    },
    listOptions: function() {
      return {
        fullContainerWidth: true,
        pagination: 100,
        search: ['name', 'streetAddress', 'postalCode', 'state'],
        filter: null
      };
    },
    resources: function() {
      return this.$store.getters['locations/filtered'];
    },
    columns: function() {
      return [
        {
          label: 'Name',
          key: 'name',
          sortable: true
        },
        {
          label: 'Street Address',
          key: 'streetAddress',
          clickable: true,
          sortable: true
        },
        {
          label: 'Postal Code',
          key: 'postalCode',
          sortable: true
        },
        {
          label: 'State',
          key: 'state',
          sortable: true,
          renderer: 'ItemEnum'
        },
        {
          label: 'Landline',
          key: 'landline'
        },
        {
          label: 'Organisation',
          key: 'organisationId',
          clickable: true,
          formatter: (resource, column) => {
            let organisation = this.$store.getters['organisations/one'](
              resource.organisationId
            );
            return organisation.name;
          }
        },
        {
          label: 'Created at',
          key: 'createdAt',
          renderer: 'ItemDate',
          sortable: true,
          sortType: 'date'
        },
        {
          label: 'Action',
          key: '_action',
          sortable: false,
          clickable: false,
          actions: ['V', 'E']
        }
      ];
    }
  },

  methods: {
    onClick: function(payload) {
      if (payload.action && payload.action === 'view') {
        if (payload.key && payload.key === 'organisationId') {
          let resource = this.$store.getters['locations/one'](payload.id);
          this.$store.dispatch('app/setInfoWindow', {
            type: 'organisation',
            id: resource.organisationId
          });
        } else {
          this.$store.dispatch('app/setInfoWindow', {
            type: 'location',
            id: payload.id
          });
        }
      }

      if (payload.action && payload.action === 'edit') {
        this.$router.push({
          name: 'LocationsUpdate',
          params: { id: payload.id }
        });
      }

      if (payload.action && payload.action === 'delete') {
        this.$store.dispatch('locations/delete', payload.id);
      }
    }
  }
};
</script>

<style></style>
