<template>
  <div class="c-stats">
    <div class="c-stats__section" v-for="(section, i) in sections" :key="i">
      <h5>
        {{ section.title }}
      </h5>
      <div
        class="c-stats__section__field"
        v-for="(field, j) in section.fields"
        :key="j"
      >
        <div class="c-stats__key">
          <span>
            {{ field.key }}
          </span>
        </div>
        <div :class="['c-stats__value', field.type]">
          <span>
            {{ field.value }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import generateSection from '@/lib/generate-section';

export default {
  /**
   * Example sections:
   * [{
   *  title : 'title',
   *  fields : [
   *   {
   *     key :'key',
   *     value : 'value,
   *     type : 'string'
   *   }
   *  ]
   * }]
   */
  props: {
    sections: {
      type: Array,
      default: function() {
        return [];
      }
    }
  }
};
</script>

<style lang="scss">
.c-stats {
  --gap: 5px;
  font-size: var(--font-size-sm);

  h5 {
    font-size: var(--font-size);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 600;
  }

  &__section {
    padding: var(--gap) 0;
    border-top: var(--color-bg-3) solid 1px;
    margin-bottom: calc(var(--gap) * 4);

    &__field {
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-bottom: var(--color-bg-3) solid 1px;

      &:first-of-type {
        border-top: var(--color-bg-3) solid 1px;
      }
    }
  }

  &__key {
    padding: var(--gap) 0;
    padding-left: var(--gap);
    background-color: var(--color-bg-2);
    border-right: var(--color-bg-3) solid 1px;

    span {
      opacity: 0.65;
    }
  }

  &__value {
    padding: var(--gap);

    &.object {
      span {
        font-size: var(--font-size-xs);
        font-family: var(--font-family-monospace);
        line-height: 1;
      }
    }
  }
}
</style>
