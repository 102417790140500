import { statuses } from '@/lib/form-data';

const schema = [
  {
    type: 'accordion',
    label: 'Order Details',
    fields: {
      bundleId: {
        component: 'InputText',
        label: 'BundleId',
        description: 'Bundle Id will be created automatically',
        readonly: false,
        properties: {
          columnSize: 6
        },
        validations: {}
      },
      // location: {
      //   component: 'InputLocations',
      //   label: 'Location',
      //   properties: {
      //     columnSize: 6
      //   },
      //   validations: {
      //     required: {
      //       params: null,
      //       message: 'A location is required'
      //     }
      //   }
      // },
      organisation: {
        component: 'InputRelation',
        label: 'Organisation',
        relation: 'organisations/all',
        optionKey: 'name',
        properties: {
          columnSize: 6
        },
        validations: {
          required: {
            params: null,
            message: 'A organisation is required'
          }
        }
      },
      mhr_active: {
        component: 'InputBoolean',
        label: 'Is MHR Active?',
        properties: {
          columnSize: 6
        },
        validations: {}
      },
      lead: {
        component: 'InputRelation',
        relation: 'leads/all',
        optionKey: 'firstName',
        label: 'Lead',
        properties: {
          columnSize: 6
        },
        validations: {
          required: {
            params: null,
            message: 'A lead is required'
          }
        }
      },
      purchaseOption: {
        component: 'InputBoolean',
        label: 'Is this order purchase outright?'
      },
      billingMethod: {
        component: 'InputEnum',
        enum: 'billingMethod',
        label: 'Select a billing method for this order',
        description:
          '[IMP] This controls what payment flow this order will take.',
        validations: {
          required: {
            params: null,
            message: 'Set a billing method for this order'
          }
        }
      },
      status: {
        component: 'InputEnum',
        enum: 'orderStages',
        label: 'Order status',
        description:
          'This field denotes where the order is right now in the workflow',
        validations: {
          required: {
            params: null,
            message: 'A stage is mandatory'
          }
        }
      },
      products: {
        component: 'InputProducts',
        label: 'Products',
        validations: {
          required: {
            params: null,
            message: 'At least 1 product is required'
          }
        }
      }
    }
  }
];

export default schema;
