<template>
  <div :class="['c-note-compact-label', { editable: editable === true }]">
    <div class="c-note-compact-label__header">
      <article
        v-if="getMetric(currentValue)"
        :class="['c-note-compact-label__label', getMetric(currentValue).color]"
      >
        <span>{{ getMetric(currentValue).label }}</span>
      </article>
      <article v-else class="c-note-compact-label__label" @click="onDropdown">
        <span>(Select a note status)</span>
      </article>
      <chevron-down-icon
        v-if="editable"
        @click="onDropdown"
        size="1.25x"
      ></chevron-down-icon>
    </div>
    <div class="c-note-compact-label__list" v-if="dropdown">
      <article
        :class="['c-note-compact-label__label', metric.color]"
        v-for="metric in metrics"
        :key="metric.value"
        @click="setMetric(metric)"
      >
        <!-- <EmailIcon></EmailIcon> -->
        <span>
          {{ metric.label }}
        </span>
      </article>
    </div>
  </div>
</template>

<script>
import { metrics } from '@/lib/enums';

import { ChevronDownIcon } from 'vue-feather-icons';

import EmailIcon from '@/components/global/icons/email';

export default {
  components: {
    EmailIcon,
    ChevronDownIcon
  },

  data: function() {
    return {
      currentValue: '',
      dropdown: false
    };
  },

  watch: {
    value: {
      handler: function() {
        if (this.value) {
          this.currentValue = this.value;
        } else {
          this.currentValue = '';
        }
      },
      immediate: true
    }
  },

  props: {
    value: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    metrics: function() {
      return metrics;
    }
  },

  methods: {
    onDropdown: function() {
      if (!this.editable) {
        return;
      }

      this.dropdown = !this.dropdown;
    },
    getMetric: function(val) {
      let metric = null;
      let metrics = this.metrics;
      for (let i = 0; i < metrics.length; i++) {
        if (metrics[i].value === val) {
          metric = metrics[i];
          break;
        }
      }

      return metric;
    },
    setMetric: function(metric) {
      this.dropdown = false;
      this.currentValue = metric.value;
      this.$emit('input', this.currentValue);
    }
  }
};
</script>

<style lang="scss">
.c-note-compact-label {
  position: relative;

  --DANGER: #ff3b30;
  --WARNING: #ffcc00;
  --SUCCESS: #34c759;
  --NEUTRAL: #aeaeb2;

  &:not(.editable) {
    display: inline-block;
    width: 100%;
  }

  &.editable {
    margin-top: var(--space-xs);
  }

  &__label {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: var(--margin) var(--space-xs);
    padding-left: var(--space-md);
    font-weight: 500;
    font-size: var(--font-size-sm);
    line-height: 1;
    color: rgba(0, 0, 0, 0.5);

    border-radius: var(--border-radius-sm);
    //background-color: var(--NEUTRAL);
    border: var(--color-grey-light) solid 1px;
    --primary: var(var(--NEUTRAL));
    user-select: none;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      top: 4.5px;
      left: 5px;
      width: 12px;
      height: 12px;
      border-radius: 10px;
      background-color: var(--primary);
    }

    // display: inline-block;
    // padding: var(--space-xs) var(--space-xs);
    // border-radius: 14px;
    // background-color: var(--NEUTRAL);
    // color: #fff;
    // font-size: var(--font-size-xs);
    // font-weight: 500;
    // // letter-spacing: 0.5px;
    // line-height: 1.2;

    // user-select: none;

    svg {
      display: inline-block;
      width: var(--space-sm);
      height: var(--space-sm);
      margin-right: var(--space-xxs);
      fill: currentColor;

      &:hover,
      &:active {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    &.DANGER {
      --primary: var(--DANGER);
    }

    &.WARNING {
      --primary: var(--WARNING);
    }

    &.SUCCESS {
      --primary: var(--SUCCESS);
    }

    &.NEUTRAL {
      --primary: var(--NEUTRAL);
    }
  }

  &__header {
    display: flex;
    position: relative;
    padding: var(--margin);
    //background-color: #f1f1f1;
    border-radius: var(--border-radius-lg);

    .c-note-compact-label__label {
      width: calc(100% - 32px);
    }

    svg {
      position: absolute;
      top: calc(50% - 10px);
      right: 10px;
      border-radius: var(--border-radius-lg);

      cursor: pointer;

      &:hover,
      &:active {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  &__list {
    position: absolute;
    z-index: 10;
    top: 32px;
    left: 0;
    width: 100%;
    padding: var(--space-xs);
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    border-radius: var(--border-input);
    max-height: 300px;
    overflow-y: auto;

    box-shadow: 0px 0px 14px #00000029;

    .c-note-compact-label__label {
      margin-bottom: var(--margin);
      cursor: pointer;
    }
  }
}
</style>
