<template>
  <div class="c-receipt">
    <article>
      <h3>
        Receipts
      </h3>
      <p>
        Once the order starts interacting with Winthop, this section will
        populate with Winthrop's invoices.
      </p>
    </article>
    <div
      v-for="resource in receipts"
      :key="resource.id"
      class="c-receipt__item"
    >
      <div class="c-receipt__item__header">
        <strong>{{ resource.action }}</strong> | <span>{{ resource.id }}</span>
      </div>
      <div class="c-receipt__item__body">
        <span>{{ formatDate(resource.createdAt) }}</span
        >&nbsp;<span>({{ formatDateDistance(resource.createdAt) }})</span>
      </div>
    </div>
  </div>
</template>

<script>
import { parseISO, format, formatDistance } from 'date-fns';

export default {
  props: {
    receipts: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },

  methods: {
    formatDate: function(date) {
      date = parseISO(date);

      if (!date) {
        return false;
      }

      let str = format(date, 'do MMM, yyy, h:m:aaa');

      return str;
    },
    formatDateDistance: function(date) {
      date = parseISO(date);

      if (!date) {
        return false;
      }

      let str = formatDistance(date, new Date(), { addSuffix: true });

      return str;
    }
  }
};
</script>

<style lang="scss">
.c-receipt {
  margin: var(--space-sm) 0;

  > article {
    margin-bottom: var(--space-sm);
  }

  &__item {
    padding: var(--space-xs);
    border: #ddd solid 1px;
    border-bottom-style: none;
    font-size: var(--font-size-sm);

    &:first-of-type {
      border-top-left-radius: var(--border-radius-sm);
      border-top-right-radius: var(--border-radius-sm);
    }

    &:last-of-type {
      border-bottom-style: solid;
      border-bottom-left-radius: var(--border-radius-sm);
      border-bottom-right-radius: var(--border-radius-sm);
    }

    &__header {
      font-family: var(--font-family-monospace);

      > strong {
        padding: var(--margin) var(--margin-lg);
        font-size: var(--font-size-xs);
        border-radius: var(--border-radius-sm);
        border: #ddd solid 1px;
        letter-spacing: 0.5px;
      }
    }

    &__body {
      padding-top: var(--space-xs);
    }
  }
}
</style>
