<template>
  <div class="c-item-date">
    <Date :date="date"></Date>
  </div>
</template>

<script>
import Date from '@/components/global/date';

export default {
  components: {
    Date
  },

  props: {
    resource: {
      type: Object,
      default: function() {
        return {};
      }
    },
    column: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  computed: {
    date: function() {
      return this.resource[this.column.key];
    }
  }
};
</script>

<style lang="scss">
.c-item-date {
  overflow: hidden;
  padding-right: var(--space-sm);
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  letter-spacing: 0.5px;
  font-size: var(--font-size-sm);
}
</style>
