import defaults from '@/lib/defaults.js';

const schema = [
  {
    type: 'accordion',
    label: 'Fields',
    fields: {
      name: {
        component: 'InputText',
        label: 'Name',
        description: 'Location name',
        properties: {
          columnSize: 12
        },
        validations: {
          maxLength: {
            params: defaults.maxStringInput,
            message: 'No more than 60 letters allowed'
          }
        }
      },
      streetAddress: {
        component: 'InputText',
        label: 'Street Address',
        properties: {
          columnSize: 6
        },
        validations: {
          required: {
            params: null,
            message: 'This field is required'
          }
        }
      },
      suburb: {
        component: 'InputText',
        label: 'Suburb',
        properties: {
          columnSize: 6
        },
        validations: {
          maxLength: {
            params: defaults.maxStringInput,
            message: 'No more than 60 letters allowed'
          },
          required: {
            params: null,
            message: 'This field is required'
          }
        }
      },
      postalCode: {
        component: 'InputText',
        label: 'Postal Code',
        properties: {},
        validations: {
          maxLength: {
            params: 8,
            message: 'No more than 8 letters allowed'
          }
        }
      },
      state: {
        component: 'InputEnum',
        label: 'State',
        enum: 'states',
        properties: {
          columnSize: 12
        },
        options: 'status',
        validations: {
          required: {
            params: null,
            message: 'This field is required'
          }
        }
      },
      landline: {
        component: 'InputText',
        label: 'Landline  ',
        description: 'Add a location specific landline number',
        properties: {
          columnSize: 6
        },
        validations: {
          maxLength: {
            params: defaults.maxStringInput,
            message: 'No more than 60 letters allowed'
          }
        }
      },
      email: {
        component: 'InputText',
        label: 'Email',
        description: 'Add a location specific email',
        properties: {
          columnSize: 6
        },
        validations: {
          maxLength: {
            params: defaults.maxStringInput,
            message: 'No more than 60 letters allowed'
          }
        }
      },
      organisationId: {
        component: 'InputRelation',
        relation: 'organisations/all',
        optionKey: 'name',
        label: 'Organisation',
        returnId: true,
        validations: {
          required: {
            params: null,
            message: 'This field is required'
          }
        }
      }
    }
  }
];

export default schema;
