<template>
  <div class="c-info-window-section">
    <div class="c-info-window-section__header">
      <h3>
        {{ title }}
      </h3>
    </div>
    <div class="c-info-window-section__body">
      <slot> </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'HEADER'
    }
  }
};
</script>

<style lang="scss">
.c-info-window-section {
  margin: var(--space-md) 0;

  &__header {
    background-color: var(--silver);
    padding: var(--space-xs) var(--space-md);
    border-radius: var(--border-radius-md);

    h3 {
      font-size: var(--text-md);
      margin: 0;
    }
  }

  &__body {
    margin: var(--space-sm) var(--space-md) var(--space-lg);
  }
}
</style>
