import * as Validators from 'vuelidate/lib/validators';

/**
 * Generates a validations object as defined over here:
 * https://vuelidate.js.org/#sub-basic-form
 *
 * Pass a form schema
 * @param {Object} schema
 */
const validationRules = function(schema) {
  let validations = Object.keys(schema).reduce((rules, elementName) => {
    const item = schema[elementName];
    if (!item.hasOwnProperty('validations')) return rules;

    const validations = {};
    for (let rule in item.validations) {
      const params = item.validations[rule].params;
      if (params) {
        validations[rule] = Validators[rule](params);
      } else {
        validations[rule] = Validators[rule];
      }
    }

    rules[elementName] = validations;
    return rules;
  }, {});

  return validations;
};

export default validationRules;
