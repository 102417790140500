<template>
  <form @submit.prevent="onSubmit">
    <Form
      ref="form"
      v-model="form"
      :schema="schema"
      :fields="fields"
      @status="saveStatus"
    ></Form>
    <InputButton class="btn btn-primary" type="submit">
      Update RCTI
    </InputButton>
  </form>
</template>

<script>
import Form from '@/components/global/form';

import schema from './schema.js';

import InputButton from '@/components/global/button';

import parseFormFields from '@/lib/parse-form-fields';

export default {
  components: {
    InputButton,

    Form
  },

  data: function() {
    return {
      status: null,

      schema: schema,

      form: {}
    };
  },

  props: {
    resource: {
      type: Object,
      default: function() {
        return null;
      }
    }
  },

  computed: {
    fields: function() {
      return [];
    }
  },

  methods: {
    saveStatus(status) {
      this.status = status;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      let form = parseFormFields(this.form);

      let resource = await this.$store.dispatch('rctis/update', form);

      if (resource) {
        this.$router.push('/orders/rctis');
      }
    },

    destructureCustomFields: function(form) {
      let fields = {};
      let newForm = {};

      if (!form) {
        return {};
      }

      for (let key in form) {
        if (key !== 'notes' && key !== 'organisation' && key !== 'fields') {
          newForm[key] = form[key];
        }
      }

      return newForm;
    }
  },

  created: function() {
    let form = this.destructureCustomFields(this.resource);

    this.form = form;
  }
};
</script>

<style></style>
