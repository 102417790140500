<template>
  <div class="c-leads-create">
    <BreadCrumb></BreadCrumb>
    <h1>
      Update Person
    </h1>
    <FormUpdate :resource="resource"></FormUpdate>
  </div>
</template>

<script>
import BreadCrumb from '@/components/global/breadcrumb';
import FormUpdate from '@/components/people/form-update';

export default {
  components: {
    BreadCrumb,
    FormUpdate
  },

  computed: {
    resource: function() {
      let id = this.$route.params.id;
      let resource = this.$store.getters['leads/one'](id);
      return resource;
    }
  }
};
</script>

<style lang="scss">
.c-leads-create {
}
</style>
