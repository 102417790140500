var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['form-group c-input-orders', { 'form-group--inline': _vm.inline }]},[_c('label',{staticClass:"form__label",attrs:{"for":_vm.slug}},[_vm._v(_vm._s(_vm.label))]),_c('v-select',{attrs:{"value":_vm.selectValue,"options":_vm.computedOptions,"label":"label","multiple":true},on:{"input":_vm.onChange},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var resource = ref.resource;
return [_c('span',[_vm._v("#"+_vm._s(resource.id)+" - "),(resource.location && resource.location.organisation)?[_vm._v(_vm._s(resource.location.organisation.name))]:_vm._e()],2),_c('em',[_vm._v(_vm._s(resource.status))])]}},{key:"option",fn:function(ref){
var resource = ref.resource;
return [_c('span',[_vm._v("#"+_vm._s(resource.id)+" - "),(resource.location && resource.location.organisation)?[_vm._v(_vm._s(resource.location.organisation.name))]:_vm._e()],2),_c('em',[_vm._v(_vm._s(resource.status))])]}}])}),(_vm.selectValue.length > 0)?_c('ul',{staticClass:"c-input-orders__list"},_vm._l((_vm.selectValue),function(option,i){return _c('li',{key:i},[_c('article',[_c('span',[_vm._v("#"+_vm._s(option.resource.id)+" - "),(
              option.resource.location &&
                option.resource.location.organisation
            )?[_vm._v(_vm._s(option.resource.location.organisation.name))]:_vm._e()],2),_vm._v(" | "),_c('em',[_vm._v(_vm._s(option.resource.status))])])])}),0):_vm._e(),(_vm.hasError)?_c('InputError',{attrs:{"message":_vm.errorMessage}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }