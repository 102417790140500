<template>
  <div class="c-rctis">
    <Dashboard>
      <DashboardHeader title="Manage RCTIs">
        <router-link to="/orders/rctis/create" class="btn btn-primary"
          >Add New</router-link
        >
      </DashboardHeader>
      <DashboardBody>
        <p>
          RCTIs are Reverse Invoices. They are created at the end of every month
          and sent to Nicole from COPE after approval.
        </p>
        <p>
          Some RCTIs(like the public ones) are created automatically by the
          system. Someone still needs to go in the system and manually approve
          them.
        </p>
        <List
          :options="listOptions"
          :resources="resources"
          @click="onClick"
          :columns="columns"
        ></List>
      </DashboardBody>
    </Dashboard>
    <LeadInfoOrder
      :leadId="currentLeadId"
      @close="onInfoWindowClose"
    ></LeadInfoOrder>
  </div>
</template>

<script>
import Dashboard from '@/components/dashboard';
import DashboardHeader from '@/components/dashboard/header';
import DashboardBody from '@/components/dashboard/body';

import Button from '@/components/global/button';

import List from '@/components/list';

import LeadInfoOrder from './info-window-order';

export default {
  components: {
    Dashboard,
    DashboardHeader,
    DashboardBody,

    Button,

    List,

    LeadInfoOrder
  },

  data: function() {
    return {
      currentLeadId: null,
      listOptions: {
        fullContainerWidth: true,
        pagination: 20,
        search: ['name']
      }
    };
  },

  computed: {
    resources: function() {
      return this.$store.getters['rctis/filtered'];
    },
    columns: function() {
      return [
        // {
        //   label: 'Checkbox',
        //   key: '_checkbox',
        //   sortable: false,
        //   clickable: false,
        //   size: 'f4'
        // },
        {
          label: 'ID',
          key: 'id',
          renderer: 'ItemId'
        },
        {
          label: 'Assigned by',
          key: 'userId',
          renderer: 'ItemUser'
        },
        {
          label: 'Reference number',
          key: 'referenceNumber'
        },
        {
          label: 'No. of attached orders',
          key: 'orders',
          formatter: function(resource, column) {
            return resource.orders.length || 0;
          }
        },
        {
          label: 'Date created',
          key: 'createdAt',
          sortable: true,
          sortType: 'date',
          renderer: 'ItemDate'
        },
        {
          label: 'Action',
          key: '_action',
          sortable: false,
          clickable: false,
          actions: ['V', 'E']
        }
      ];
    }
  },

  methods: {
    onClick: function(payload) {
      if (payload.action && payload.action === 'view') {
        this.currentLeadId = payload.id;
      }
      if (payload.action && payload.action === 'edit') {
        this.$router.push({
          name: 'RctisUpdate',
          params: { id: payload.id }
        });
      }
    },
    onInfoWindowClose: function() {
      this.currentLeadId = null;
    }
  }
};
</script>

<style></style>
