<template>
  <div class="form-group c-input-boolean">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <div class="c-input-boolean__inner">
      <!-- <button
        type="button"
        :class="['btn', { 'btn-secondary': value === true }]"
        @click="onChange(true)"
      >
        Yes
      </button>
      <button
        type="button"
        :class="['btn', { 'btn-secondary': value !== true }]"
        @click="onChange(false)"
      >
        No
      </button> -->
      <div class="onoffswitch">
        <input
          type="checkbox"
          name="onoffswitch"
          class="onoffswitch-checkbox"
          :id="`switch-${_uid}`"
          v-model="boolean"
        />
        <label class="onoffswitch-label" :for="`switch-${_uid}`">
          <span class="onoffswitch-inner"></span>
          <span class="onoffswitch-switch"></span>
        </label>
      </div>
    </div>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
  </div>
</template>

<script>
import InputError from './input-error';
import InputDescription from './input-description';
import slugify from '@/lib/slugify';

export default {
  components: {
    InputError,
    InputDescription
  },

  data: function() {
    return {
      boolean: false
    };
  },

  watch: {
    value: {
      handler: function() {
        this.boolean = this.value;
      },
      immediate: true
    },
    boolean: function() {
      this.onChange(this.boolean);
    }
  },

  props: {
    label: {
      type: String,
      default: ''
    },
    value: {},
    placeholder: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: null
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },

  computed: {
    slug: function() {
      return slugify(this.label);
    }
  },

  methods: {
    onChange: function(value) {
      this.$emit('input', value);
    }
  }
};
</script>

<style lang="scss">
.c-input-boolean {
  &__inner {
    display: inline-flex;
    //background-color: var(--silver);
    border-radius: var(--border-radius-md);

    .btn {
      margin: var(--margin-lg);
    }
  }

  .onoffswitch {
    position: relative;
    width: 100px;
    user-select: none;
  }
  .onoffswitch-checkbox {
    display: none;
  }
  .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 20px;
  }
  .onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 200ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  }
  .onoffswitch-inner:before,
  .onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    line-height: 30px;
    font-size: 14px;
    color: white;
    font-weight: 600;
    box-sizing: border-box;
  }
  .onoffswitch-inner:before {
    content: 'Yes';
    padding-left: 14px;
    background-color: var(--primary);
    color: #ffffff;
  }
  .onoffswitch-inner:after {
    content: 'No';
    padding-right: 14px;
    background-color: #5b5d62;
    color: #ffffff;
    text-align: right;
  }
  .onoffswitch-switch {
    display: block;
    width: 30px;
    margin: 2px;
    background: #ffffff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 66px;
    border: 4px solid #5b5d62;
    border-radius: 20px;
    transition: all 200ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  }
  .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
  }
  .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    border-color: var(--primary);
    right: 0px;
  }
}
</style>
