<template>
  <div class="c-dashboard-header">
    <h1 class="c-dashboard-header__heading">{{ title }}</h1>
    <div class="c-dashboard-header__inner">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Dashboard header'
    }
  }
};
</script>

<style lang="scss">
.c-dashboard-header {
  align-items: center;
  display: flex;
  margin-bottom: var(--space-md);

  &__heading {
    margin: 0 var(--space-md) 0 0;
  }

  &__inner {
    margin-left: var(--margin-lg);
  }

  .btn {
    margin-top: 0;
  }
}
</style>
