<template>
  <form @submit.prevent="onSubmit">
    <Form
      ref="form"
      v-model="form"
      :schema="schema"
      :fields="fields"
      @status="saveStatus"
    ></Form>
    <InputButton :processing="processing" class="btn btn-primary" type="submit">
      Update Organisation
    </InputButton>
  </form>
</template>

<script>
import Form from '@/components/global/form';

import schema from './schema.js';

import InputButton from '@/components/global/button';

import parseFormFields from '@/lib/parse-form-fields';

import destructureForm from '@/lib/destructure-form';

export default {
  components: {
    InputButton,

    Form
  },

  data: function() {
    return {
      status: null,

      schema: schema,

      processing: false,

      form: {}
    };
  },

  props: {
    resource: {
      type: Object,
      default: function() {
        return null;
      }
    }
  },

  computed: {
    fields: function() {
      return this.$store.getters['fields/all'];
    }
  },

  methods: {
    saveStatus(status) {
      this.status = status;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = parseFormFields(this.form);

      let resource = await this.$store.dispatch('organisations/update', form);

      this.processing = false;

      if (resource) {
        this.$router.push('/organisations');
      }
    }
  },

  created: function() {
    let temp = destructureForm(this.resource);

    this.form = temp;

    // if (temp.fields) {
    //   let obj = {};
    //   temp.fields.map((field) => {
    //     obj[`field_${field.}`]
    //   });
    // } else {
    //   temp.fields = [];
    // }
  }
};
</script>

<style lang="scss"></style>
