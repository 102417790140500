<template>
  <form @submit.prevent="onSubmit">
    <Form
      ref="form"
      v-model="form"
      :schema="schema"
      @status="saveStatus"
    ></Form>
    {{ query }}
    <InputButton class="btn btn-primary" type="submit">
      Add Location
    </InputButton>
  </form>
</template>

<script>
import Form from '@/components/global/form';

import schema from './schema.js';

import InputButton from '@/components/global/button';

import parseFormFields from '@/lib/parse-form-fields';

export default {
  components: {
    InputButton,
    Form
  },

  data: function() {
    return {
      status: null,

      schema: schema,

      form: {
        streetAddress: '',
        suburb: '',
        postalCode: '',
        state: '',
        organisationId: null
      }
    };
  },

  computed: {
    query: function() {
      return this.$route.query || {};
    }
  },

  methods: {
    saveStatus(status) {
      this.status = status;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      let form = parseFormFields(this.form);

      let resource = await this.$store.dispatch('locations/add', form);

      if (resource) {
        this.$router.push('/locations');
      }
    }
  },

  created: function() {
    let query = this.query;

    if (query.organisationId) {
      this.form.organisationId = parseInt(query.organisationId);
    }
  },

  mounted: function() {}
};
</script>

<style></style>
