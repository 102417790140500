import Fuse from 'fuse.js';

const search = function(resources, listOptions, query) {
  if (!query) {
    return resources;
  }

  const options = {
    // isCaseSensitive: false,
    // includeScore: false,
    // shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    // minMatchCharLength: 1,
    // location: 0,
    // threshold: 0.6,
    // distance: 100,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    threshold: 0.3,
    keys: listOptions.search
  };

  const fuse = new Fuse(resources, options);

  let results = fuse.search(query);

  results = results.map(result => {
    return result.item;
  });

  return results;
};

export default search;
