import CrudStore from '@/lib/crud-store.js';
var crud = new CrudStore('locations');

const state = {
  ...crud.state
};
const getters = {
  ...crud.getters,

  byOrganisation: function(state) {
    let resources = state.resources;

    return id => {
      let newResources = [];

      id = parseInt(id);

      if (isNaN(id)) {
        return null;
      }

      for (let i = 0; i < resources.length; i++) {
        if (resources[i].organisationId === id) {
          newResources.push(resources[i]);
        }
      }

      return newResources;
    };
  }
};
const actions = {
  ...crud.actions
};
const mutations = {
  ...crud.mutations
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;
