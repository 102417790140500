<template>
  <div class="form-group c-input-notes">
    <div class="c-input-notes__header">
      <Button @click="onAddNote" icon="plus-white" type="button"
        >Add New Note</Button
      >
    </div>
    <div class="c-input-notes__body">
      <List
        v-if="notes.length"
        :resources="notes"
        :options="options"
        :columns="columns"
      ></List>
    </div>
    <ModalCreate ref="modalCreate" @onCreate="onCreate"></ModalCreate>
  </div>
</template>

<script>
import InputError from './input-error';
import slugify from '@/lib/slugify';

import Button from '@/components/global/button';
import List from '@/components/list';

import ModalCreate from '@/components/notes/modal-create';

export default {
  components: {
    Button,
    InputError,
    List,
    ModalCreate
  },

  data: function() {
    return {
      notes: [],
      options: {
        search: false,
        sort: false
      }
    };
  },

  watch: {
    value: {
      handler: function() {
        if (
          typeof this.value === 'object' &&
          this.value &&
          this.value.length > 0
        ) {
          this.onUpdate(this.value);
        }
      },
      immediate: true
    }
  },

  props: {
    label: {
      type: String,
      default: 'Label'
    },
    value: {
      default: []
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },

  computed: {
    slug: function() {
      return slugify(this.label);
    },
    columns: function() {
      return [
        {
          label: 'Comments',
          key: 'html',
          html: true
        },
        {
          label: 'Date created',
          key: '_created_at',
          sortable: true,
          clickable: true
        },
        {
          label: 'Action',
          key: '_action',
          sortable: false,
          clickable: false
        }
      ];
    }
  },

  methods: {
    onUpdate: function(notes) {
      this.notes = notes;
    },
    onAddNote: function() {
      // let note = {
      //   html: '<p>test</p>'
      // };
      // this.notes.push(note);
      // this.onChange();
      this.$refs.modalCreate.activate();
    },
    onCreate: function(note) {
      this.notes.push(note);
      this.onChange();
    },
    onChange: function() {
      this.$emit('input', this.notes);
    }
  }
};
</script>

<style lang="scss">
.c-input-notes {
  margin: var(--space-md) 0;

  &__header {
    margin-bottom: var(--space-md);
  }

  .btn {
    margin-top: 0;
  }
}
</style>
