<template>
  <div :class="['form-group c-input-enum', { 'form-group--inline': inline }]">
    <label class="form__label" :for="slug">{{ label }}</label>
    <v-select
      :disabled="readonly === true"
      :value="computedValue"
      :options="computedOptions"
      @input="onChange"
    ></v-select>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
  </div>
</template>

<script>
import InputError from './input-error';
import slugify from '@/lib/slugify';
import InputDescription from './input-description';

import {
  period,
  status,
  stage,
  category,
  states,
  facilityTypeCodes,
  practiceSettingTypes,
  organisationType,
  billingMethod,
  orderStages
} from '@/lib/enums';

export default {
  components: {
    InputError,
    InputDescription
  },

  data: function() {
    return {
      enums: {
        period,
        status,
        stage,
        category,
        states,
        facilityTypeCodes,
        practiceSettingTypes,
        organisationType,
        billingMethod,
        orderStages
      }
    };
  },

  props: {
    description: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'Label'
    },
    options: {
      type: String,
      default: null
    },
    enum: {
      type: String,
      default: null
    },
    validation: {},
    value: {},
    inline: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    computedOptions: function() {
      if (this.enum) {
        return this.enums[this.enum];
      } else {
        return [];
      }
    },
    computedValue: function() {
      let options = this.computedOptions;
      let value = this.value;
      let val = null;
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === value) {
          val = options[i].label;
        }
      }
      return val;
    },
    slug: function() {
      return slugify(this.label);
    },
    errorMessage: function() {
      if (!this.hasError) {
        return '';
      }

      return 'Please select a value';
    }
  },

  methods: {
    onChange: function(data) {
      if (typeof data === 'string') {
        this.$emit('input', data);
      } else {
        this.$emit('input', data.value);
      }
    }
  },

  mounted: function() {}
};
</script>

<style lang="scss">
.c-input-enum {
  .v-select {
    appearance: none;
    background-color: var(--g1);
    border: 1px solid var(--color-input-border);
    border-radius: var(--border-input);
    box-shadow: var(--box-shadow-input);
    display: inline-block;
    font-size: var(--font-size-md);
    margin-top: var(--space-xs);
    transition: all 50ms ease-out;
    padding: var(--space-xs) var(--space-sm);
    width: 100%;

    &.vs--open {
      box-shadow: 0 0 0 3px rgba(70, 132, 248, 0.6) !important;
    }

    .vs__selected-options {
      padding: 0;
    }

    .vs__selected {
      border-radius: 0;
      line-height: 1.4;
      margin: 0 var(--space-xs) 0 0;
      padding: 0;
    }

    .vs__dropdown-toggle {
      border: none;
      border-radius: 0;
      padding: 0;
    }

    .vs__search {
      appearance: none;
      border: 0;
      font-size: var(--font-size-md);
      line-height: 1.5;
      margin: 0;
      padding: 0;
    }

    .vs__actions {
      padding: 0;
    }

    &:active,
    &:focus {
      @include focus();
    }

    ul {
    }
  }
}
</style>
