<template>
  <div
    :class="[
      'c-message-alert',
      'c-message-alert--' + (message.status || 'info')
    ]"
    @click="onClick"
  >
    <h2>{{ message.message }}</h2>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      default: null,
      animation: null
    }
  },

  watch: {
    message: {
      handler: function() {
        this.startTimer();
      },
      immediate: true
    }
  },

  methods: {
    onClick: function() {
      this.close();
    },
    close: function() {
      this.$emit('onClose');
    },
    startTimer: function() {
      if (!this.message.timer) {
        return;
      }
      setTimeout(() => {
        this.close();
      }, this.message.timer);
    }
  },

  mounted: function() {}
};
</script>

<style lang="scss">
.c-message-alert {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  color: var(--color-white);
  border-radius: 12px;
  position: relative;
  width: 400px;
  margin: 0 16px 16px auto;
  padding: 16px 48px 16px 16px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.125), 0 0 5px rgba(0, 0, 0, 0.125);

  pointer-events: all;

  cursor: pointer;

  &--info {
    background-color: var(--color-blue);
  }

  &--danger {
    background-color: var(--color-danger);
  }

  &--warning {
    background-color: var(--color-warning);
  }

  &--success {
    background-color: var(--color-success);
  }

  &::after {
    background-image: url('/images/icon-close-white.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    content: '';
    height: 12px;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    opacity: 0.5;
  }

  h2 {
    font-size: 16px;
    margin: 0;
  }

  &:hover,
  &:active {
    &::after {
      opacity: 1;
    }
  }
}
</style>
