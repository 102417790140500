<template>
  <fragment>
    <dt class="c-info-window-list__title">{{ item.label }}</dt>
    <dd class="c-info-window-list__content">{{ item.value }}</dd>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-fragment';

export default {
  components: { Fragment },
  props: {
    item: {
      type: Object,
      default: function() {
        return {};
      }
    }
  }
};
</script>

<style lang="scss"></style>
