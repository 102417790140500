<template>
  <div class="c-list-footer">
    <div class="c-list-footer__inner">
      <ListStats :total="total" :currentCount="currentCount"> </ListStats>
      <ListPagination
        v-if="pagination"
        :count="count"
        :limit="pagination"
        @onOffset="onOffset"
      />
    </div>
  </div>
</template>

<script>
import ListPagination from './list-pagination';
import ListStats from './list-stats';

export default {
  components: {
    ListPagination,
    ListStats
  },

  props: {
    total: {
      type: Number,
      default: 1
    },
    currentCount: {
      type: Number,
      default: 1
    },
    count: {
      type: Number,
      default: 1
    },
    pagination: {}
  },

  methods: {
    onToggle: function(e) {
      this.$emit('onToggle', e.target.checked);
    },
    onOffset: function(offset) {
      this.$emit('onOffset', offset);
    }
  }
};
</script>

<style lang="scss">
.c-list-footer {
  // c-list-row to be optimised
  display: block;
  height: 36px;
  width: 100%;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
  }
}
</style>
