var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'c-list-header',
    { 'no-rounded-border': _vm.fullContainerWidth === true }
  ]},[_vm._l((_vm.columns),function(column){return [(column.key === '_checkbox')?_c('div',{key:column.key,staticClass:"c-list-item c-list-item--checkbox"},[_c('InputCheckbox',{attrs:{"label":column.key,"method":_vm.onToggle}})],1):_c('div',{key:column.key,class:[
        'c-list-item',
        { sortable: column.sortable || false },
        { active: _vm.sort === column.key },
        _vm.sortDirection
      ],attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.onClick(column)}}},[_c('span',[_vm._v(" "+_vm._s(column.label)+" ")]),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","width":"20","height":"20"}},[_c('path',{attrs:{"fill":"currentColor","d":"M13 16.172l5.364-5.364 1.414 1.414L12 20l-7.778-7.778 1.414-1.414L11 16.172V4h2v12.172z"}})])])]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }