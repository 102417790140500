<template>
  <div class="c-modal-login">
    <Modal v-model="options.active" :closable="closable">
      <h2>
        Add Note
      </h2>
      <FormCreate @submit="onSubmit"></FormCreate>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/global/modal';
import FormCreate from '@/components/notes/form-create';

export default {
  components: {
    Modal,
    FormCreate
  },

  data: function() {
    return {
      options: {
        name: 'modal-terms',
        active: false
      }
    };
  },

  props: {
    closable: {
      type: Boolean,
      default: true
    }
  },

  watch: {
    'options.active': {
      handler: function() {}
    }
  },

  methods: {
    onSubmit: function(note) {
      this.$emit('onCreate', note);
      this.options.active = false;
    },

    activate: function() {
      this.options.active = true;
    }
  }
};
</script>

<style lang="scss"></style>
