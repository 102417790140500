<template>
  <form @submit.prevent="onSubmit">
    <article class="article-text">
      <p>
        This is the update form for the Winthrop billing method.
      </p>
    </article>
    <Form
      ref="form"
      v-model="form"
      :schema="schema"
      :fields="fields"
      @status="saveStatus"
    ></Form>
    <InputButton :processing="processing" class="btn btn-primary" type="submit">
      Update Order
    </InputButton>
  </form>
</template>

<script>
import Form from '@/components/global/form';

import schema from './schema.js';

import InputButton from '@/components/global/button';

import parseFormFields from '@/lib/parse-form-fields';

export default {
  components: {
    InputButton,

    Form
  },

  data: function() {
    return {
      status: null,

      processing: false,

      form: {}
    };
  },

  props: {
    resource: {
      type: Object,
      default: function() {
        return null;
      }
    }
  },

  computed: {
    schema: function() {
      let currentSchema = JSON.parse(JSON.stringify(schema));
      let form = this.form;

      let fields = currentSchema[0].fields;

      fields.products.readonly = true;

      if (!form.location) {
        fields.lead.readonly = true;
        fields.lead.description = 'Set a location to select a lead';

        form.lead = null;
      }

      if (form.location) {
        let organisation = this.$store.getters['organisations/one'](
          form.location.organisationId
        );

        fields.lead.filter = function(options) {
          options = options.filter(option => {
            if (option.organisationId === organisation.id) {
              return true;
            }
            return false;
          });
          return options;
        };
      }

      fields.lead.readonly = true;

      fields.location.readonly = true;

      fields.billingMethod.readonly = true;

      currentSchema[0].fields = fields;

      return currentSchema;
    },
    fields: function() {
      return [];
    }
  },

  methods: {
    saveStatus(status) {
      this.status = status;
    },
    async onSubmit() {
      this.processing = true;
      this.$refs.form.validate();

      if (this.status.invalid) {
        this.processing = false;
        return;
      }

      let form = parseFormFields(this.form);

      let resource = await this.$store.dispatch('orders/update', form);

      this.processing = false;

      if (resource) {
        this.$router.push('/orders');
      }
    },

    destructureCustomFields: function(form) {
      let fields = {};
      let newForm = {};

      if (!form) {
        return {};
      }

      for (let key in form) {
        if (key !== 'notes' && key !== 'organisation' && key !== 'fields') {
          newForm[key] = form[key];
        }
      }

      return newForm;
    }
  },

  created: function() {
    let form = this.destructureCustomFields(this.resource);

    this.form = form;
  }
};
</script>

<style></style>
