import { statuses } from '@/lib/form-data';

import defaults from '@/lib/defaults.js';

const schema = [
  {
    type: 'accordion',
    label: 'Fields',
    fields: {
      initials: {
        component: 'InputText',
        label: 'Initials',
        description: 'Eg: Dr, Mr, Miss, etc.',
        properties: {
          columnSize: 4
        },
        validations: {
          maxLength: {
            params: 4,
            message: 'No more than 4 letters allowed'
          }
        }
      },
      firstName: {
        component: 'InputText',
        label: 'First name',
        properties: {
          columnSize: 4
        },
        validations: {
          maxLength: {
            params: defaults.maxStringInput,
            message: 'No more than 60 letters allowed'
          },
          required: {
            params: null,
            message: 'This field is required'
          }
        }
      },
      lastName: {
        component: 'InputText',
        label: 'Last name',
        properties: {
          columnSize: 4
        },
        validations: {
          maxLength: {
            params: defaults.maxStringInput,
            message: 'No more than 60 letters allowed'
          }
        }
      },
      role: {
        component: 'InputText',
        label: 'Role',
        properties: {},
        validations: {
          maxLength: {
            params: defaults.maxStringInput,
            message: 'No more than 60 letters allowed'
          }
        }
      },
      email: {
        component: 'InputEmail',
        label: 'Email',
        validations: {
          maxLength: {
            params: defaults.maxStringInput,
            message: 'No more than 60 letters allowed'
          }
        }
      },
      organisationId: {
        component: 'InputRelation',
        relation: 'organisations/all',
        optionKey: 'name',
        label: 'Organisation',
        returnId: true,
        validations: {}
      },
      hpii_name: {
        component: 'InputText',
        label: 'HPII Name',
        properties: {
          columnSize: 6
        },
        validations: {
          maxLength: {
            params: defaults.maxStringInput,
            message: 'No more than 60 letters allowed'
          }
        }
      },
      hpii_number: {
        component: 'InputText',
        label: 'HPII Number',
        properties: {
          columnSize: 6
        },
        validations: {
          maxLength: {
            params: defaults.maxStringInput,
            message: 'No more than 60 letters allowed'
          }
        }
      },
      practice_setting: {
        component: 'InputEnum',
        enum: 'practiceSettingTypes',
        label: 'Practice Setting',
        properties: {
          columnSize: 12
        }
      },
      mobile: {
        component: 'InputText',
        label: `Mobile number`,
        properties: {
          columnsSize: 6
        },
        validations: {
          maxLength: {
            params: defaults.maxStringInput,
            message: 'No more than 60 letters allowed'
          },
          required: {
            message: 'Mobile number is required'
          }
        }
      },
      landline: {
        component: 'InputText',
        label: `Landline number`,
        properties: {
          columnsSize: 6
        },
        validations: {
          maxLength: {
            params: defaults.maxStringInput,
            message: 'No more than 60 letters allowed'
          }
        }
      },
      location: {
        component: 'InputLocations',
        label: 'Location'
      }
    }
  }
];

export default schema;
