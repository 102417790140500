<template>
  <div class="c-organisation-info-window">
    <InfoWindow ref="infoWindow" :sections="sections" @onClose="onClose">
      <template v-slot:header v-if="currentOrganisation">
        <h2>{{ currentOrganisation.name }}</h2>
      </template>
    </InfoWindow>
  </div>
</template>

<script>
import InfoWindow from '@/components/global/info-window';
export default {
  components: {
    InfoWindow
  },

  props: {
    id: {}
  },

  watch: {
    currentOrganisation: function() {
      if (this.currentOrganisation) {
        this.$refs.infoWindow.activate();
      } else {
        this.$refs.infoWindow.deactivate();
      }
    }
  },

  computed: {
    active: function() {
      if (this.id) {
        return true;
      } else {
        return false;
      }
    },
    currentOrganisation: function() {
      if (!this.id) {
        return;
      }
      let organisation = this.$store.getters['organisations/one'](this.id);
      return organisation;
    },
    sections: function() {
      let organisation = this.currentOrganisation;

      let fields = [];
      if (organisation && organisation.fields) {
        fields = organisation.fields;
      }

      if (!organisation) {
        return [];
      }

      let sections = [];

      let items = [];
      let notes = [];

      fields = fields.map(field => {
        return {
          label: field.label,
          value: field.value
        };
      });

      items.push({
        label: 'Status',
        value: organisation.status
      });

      items.push({
        label: 'Facility Type',
        value: organisation.facility_type
      });

      items.push({
        label: 'HPIO Name',
        value: organisation.hpio_name
      });

      items.push({
        label: 'HPIO Number',
        value: organisation.hpio_number
      });

      items.push({
        label: 'Category',
        value: organisation.category
      });

      items.push({
        label: 'locations',
        value: organisation.locations.length
      });

      sections.push({
        name: 'Contact Details',
        items: items
      });

      sections.push({
        name: 'Custom Fields',
        items: fields
      });

      return sections;
    }
  },

  methods: {
    onClose: function() {
      this.$emit('onClose');
    }
  }
};
</script>

<style lang="scss">
.c-organisation-info-window {
  h2 {
    font-size: var(--font-size-xl);
    font-weight: 700;
  }
}
</style>
