<template>
  <a
    v-if="type === 'link'"
    :class="[
      'btn btn-primary',
      { disabled: disabled === true || processing === true },
      icon ? `icon icon--${icon}` : ''
    ]"
    :href="href"
    @click="$emit('onClick', $event)"
  >
    <span class="spinner" v-if="processing"></span><slot></slot
  ></a>
  <button
    v-else
    :class="[
      'btn btn-primary',
      { disabled: disabled === true || processing === true },
      icon ? `icon icon--${icon}` : ''
    ]"
    @click="$emit('onClick', $event)"
    :disabled="disabled"
    :type="type"
  >
    <span class="spinner" v-if="processing"></span>
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'button'
    },
    href: {
      type: String,
      default: '#'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String
    }
  }
};
</script>

<style lang="scss">
.btn {
  background-color: var(--color-grey-lightest);
  border: 1px solid transparent !important;
  border-radius: var(--border-radius-md);
  color: var(--color-white);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-md);
  font-weight: 500;
  letter-spacing: 0.2px;
  color: var(--font-color);
  transition: all var(--transition-time) ease-out;
  padding: calc(var(--space-xs) * 1.25) var(--space-md);

  &:hover,
  &:active {
    background-color: var(--color-grey-lighter) !important;
  }

  &.btn-primary {
    background-color: var(--color-button-bg);
    color: var(--color-white);

    &:hover,
    &:active {
      background-color: var(--color-button-bg) !important;
    }
  }

  &.btn-secondary {
    background-color: var(--color-button-bg-secondary) !important;
    color: var(--color-white);

    &:hover,
    &:active {
      background-color: var(--color-button-bg-secondary) !important;
    }
  }

  &.btn-danger {
    background-color: var(--danger);

    &:hover,
    &:active {
      background-color: var(--danger) !important;
    }
  }

  &.btn-fancy {
    display: inline-flex;
    align-items: center;
    padding: var(--margin-lg) var(--space-md);
    background-color: rgba(0, 0, 0, 0.4);
    font-size: var(--font-size-md);
    background-image: none;
    border-radius: 50px;

    &:hover,
    &:active {
      background-image: none;
    }

    &.btn-primary {
      background-color: var(--p3);

      &:hover,
      &:active {
        background-color: var(--p4);
      }
    }
  }

  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: 0 0 0 3px var(--primary);

    @include focus();
  }

  &.btn-secondary {
    background-color: var(--p4);
  }

  &.btn-sm {
    padding: var(--margin) var(--margin-lg);
    font-size: var(--font-size-sm);
  }

  &.btn-flat {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: none !important;

    // &:focus {
    //   box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 1px 8px 0 rgba(0, 0, 0, 0.2),
    //     0 0 0 4px rgba($primary, 0.6) !important;
    // }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.icon {
    align-items: center;

    &::before {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 80%;
      content: '';
      display: inline-block;
      height: var(--space-sm);
      margin-left: calc(var(--space-xs) * -1);
      margin-right: var(--space-xs);
      width: var(--space-sm);
    }

    &.icon--plus-white::before {
      background-image: url('/images/icon-plus-white.svg');
    }
  }
}

.btn-lg,
.btn-group-lg > .btn {
  font-size: 16px !important;
  letter-spacing: 0.5px;
  padding: 0.4rem 1.25rem;
}

.btn-link {
  &:hover,
  &:focus {
    text-decoration: none;
  }

  &.btn-primary {
    background-color: var(--p3) !important;
    border-color: var(--p2) !important;
  }
}
</style>
