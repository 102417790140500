import api from '../lib/api';

import CrudStore from '@/lib/crud-store.js';
var crud = new CrudStore('users');

const state = {
  ...crud.state,

  user: {}
};

const getters = {
  ...crud.getters,

  user: function(state) {
    return state.user;
  },
  isReadOnly: function(state) {
    if (state.user && state.user.email === 'nicole.highet@cope.org.au') {
      return true;
    }
    return false;
  },
  isAuth: function(state) {
    if (state.user && state.user.id) {
      return true;
    }
    return false;
  }
};

const mutations = {
  ...crud.mutations,
  SET_ONE: function(state, user) {
    if (user) {
      state.user = user;
    } else {
      state.user = {};
    }
  }
};

const actions = {
  ...crud.actions,

  async init(store) {
    return await store.dispatch('headlessLogin');
  },

  async getUser(store) {
    let users = await api.users.get();
    if (users.length > 0) {
      store.commit('SET', users[0]);
    }
  },

  /**
   * Basically, gets the user if they are logged in
   * If not logged in, returns null
   * If logged in, sets the user
   *
   * Run this ideally, before init
   */
  async headlessLogin(store) {
    let user = await api.users.headlessLogin();

    if (user) {
      store.commit('SET_ONE', user);
    }

    return user;
  },

  /**
   * Reset password functionality
   */
  async resetPassword(store, form) {
    let user = await api.users.resetPassword(form);

    if (user.id) {
      store.commit('SET_ONE', user);
      return true;
    } else {
      return false;
    }
  },

  async resetPasswordRequest(store, form) {
    let payload = await api.users.resetPasswordRequest(form);

    return true;
  },

  /**
   * Logs in user using the form pass as payload
   * If user logs in successfully, they are committed to the store
   */
  async login(store, form) {
    let user = await api.users.login(form);

    if (user) {
      store.commit('SET_ONE', user);
    }

    return user;
  },

  async logout(store) {
    // Sometimes the server returns a 403, not sure how to handle it for now but the frontend assumes that the cookie is now invalid/user's session has been destroyed successfully
    let success = await api.users.logout();

    // Somewhere over here also call afterLogout on the main store so other modules can flush their state out
    //if (success) {
    store.dispatch('afterLogout', null, { root: true });
    store.commit('SET_ONE');
    //}
  }
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;
