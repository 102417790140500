<template>
  <div class="c-leads">
    <Dashboard>
      <DashboardHeader title="Manage People">
        <router-link
          v-if="!isReadOnly"
          to="/people/create"
          class="btn btn-primary"
          >Add New</router-link
        >
      </DashboardHeader>
      <DashboardBody>
        <List
          :options="listOptions"
          :resources="leads"
          @click="onClick"
          :columns="columns"
        ></List>
      </DashboardBody>
    </Dashboard>
    <LeadInfoWindow
      :leadId="currentLeadId"
      @close="onInfoWindowClose"
    ></LeadInfoWindow>
    <NotesPopup></NotesPopup>
  </div>
</template>

<script>
import Dashboard from '@/components/dashboard';
import DashboardHeader from '@/components/dashboard/header';
import DashboardBody from '@/components/dashboard/body';

import Button from '@/components/global/button';

import List from '@/components/list';

import LeadInfoWindow from './info-window-lead';

import NotesPopup from '@/components/notes/popup';

export default {
  components: {
    Dashboard,
    DashboardHeader,
    DashboardBody,

    Button,

    List,

    LeadInfoWindow,

    NotesPopup
  },

  data: function() {
    return {
      currentLeadId: null
    };
  },

  computed: {
    isReadOnly: function() {
      return this.$store.getters['users/isReadOnly'];
    },
    leads: function() {
      return this.$store.getters['leads/filtered'];
    },
    listOptions: function() {
      return {
        fullContainerWidth: true,
        pagination: 100,
        search: [
          'firstName',
          'lastName',
          'email',
          'mobile',
          'landline',
          'role'
        ],
        filter: null
      };
    },
    columns: function() {
      return [
        {
          label: 'Name',
          key: 'firstName,lastName',
          sortable: true,
          clickable: true,
          formatter: (resource, column) => {
            return `${resource.firstName}  ${resource.lastName}`;
          }
        },
        {
          label: 'Organisation',
          key: 'organisationId',
          clickable: true,
          formatter: (resource, column) => {
            let organisation = this.$store.getters['organisations/one'](
              resource.organisationId
            );
            if (!organisation) {
              return '';
            }
            return organisation.name;
          }
        },
        {
          label: 'Email',
          key: 'email',
          sortable: true
        },
        {
          label: 'Mobile',
          key: 'mobile'
        },
        {
          label: 'Landline',
          key: 'landline'
        },
        {
          label: 'Role/Title',
          key: 'role',
          sortable: false,
          clickable: false
        },
        {
          label: 'Last contacted by',
          key: '12345',
          formatter: (resource, column) => {
            let notes = this.$store.getters['notes/byLead'](resource.id);

            let note = null;

            if (notes.length > 0) {
              note = notes[0];
            }

            if (!note) {
              return '';
            }

            return `${note.user.firstName} ${note.user.lastName}`;
          }
        },
        {
          label: 'Latest follow up date',
          key: 'followupDate',
          renderer: 'ItemDate',
          sortable: true,
          sortType: 'string'
        },
        {
          label: 'Latest follow up person',
          key: 'followupUser',
          renderer: 'ItemUser'
        },
        {
          label: 'Notes',
          renderer: 'ItemNote'
        },
        {
          label: 'Created at',
          key: 'createdAt',
          renderer: 'ItemDate',
          sortable: true,
          sortType: 'date'
        },
        {
          label: 'Action',
          key: '_action',
          sortable: false,
          clickable: false,
          actions: ['V', 'E']
        }
      ];
    }
  },

  methods: {
    onClick: function(payload) {
      if (payload.action && payload.action === 'view') {
        if (payload.key && payload.key === 'organisationId') {
          let resource = this.$store.getters['leads/one'](payload.id);
          this.$store.dispatch('app/setInfoWindow', {
            type: 'organisation',
            id: resource.organisationId
          });
        } else {
          this.$store.dispatch('app/setInfoWindow', {
            type: 'lead',
            id: payload.id
          });
        }
      }

      if (payload.action && payload.action === 'edit') {
        this.$router.push({
          name: 'PeopleUpdate',
          params: { id: payload.id }
        });
      }

      if (payload.action && payload.action === 'delete') {
        this.$store.dispatch('leads/delete', payload.id);
      }
    },
    onInfoWindowClose: function() {
      this.currentLeadId = null;
    }
  }
};
</script>

<style></style>
