<template>
  <div
    :class="[
      'form-group c-input-select-other',
      { 'form-group--inline': inline }
    ]"
  >
    <label class="form__label" :for="slug">{{ label }}</label>
    <div class="input-radio" v-for="item in computedOptions" :key="item.value">
      <input
        :id="slugify(item.value)"
        type="radio"
        :value="item.value"
        v-model="content"
      />
      <label :for="slugify(item.value)">
        {{ item.label }}
      </label>
    </div>
    <div class="c-input-select-other__text" v-if="content === `other-${uid}`">
      <InputText v-model="text" placeholder="other"></InputText>
    </div>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
  </div>
</template>

<script>
import InputError from './input-error';
import slugify from '@/lib/slugify';
import InputDescription from './input-description';

import InputText from './input-text';

import {
  period,
  status,
  stage,
  category,
  states,
  facilityTypeCodes,
  practiceSettingTypes,
  organisationType,
  billingMethod
} from '@/lib/enums';

export default {
  components: {
    InputError,
    InputDescription,
    InputText
  },

  data: function() {
    return {
      text: '',
      content: '',
      enums: {
        period,
        status,
        stage,
        category,
        states,
        facilityTypeCodes,
        practiceSettingTypes,
        organisationType,
        billingMethod
      }
    };
  },

  watch: {
    content: function() {
      this.checkChange();
    },
    text: function() {
      if (this.content === `other-${this._uid}`) {
        this.checkChange();
      }
    }
  },

  props: {
    description: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'Label'
    },
    options: {},
    enum: {
      type: String,
      default: null
    },
    validation: {},
    value: {},
    inline: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    uid: function() {
      return this._uid;
    },
    computedOptions: function() {
      let options = [];
      if (this.options) {
        let temp = this.options.map(option => {
          if (!option.value) {
            option.value = option.label;
          }
          return option;
        });
        options = options.concat(temp);
      }

      for (let i = 0; i < options.length; i++) {
        if (options[i].value === 'other') {
          options[i].value = `other-${this._uid}`;
        }
      }

      return options;
    },
    computedValue: function() {
      let options = this.computedOptions;
      let value = this.value;
      let val = null;
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === value) {
          val = options[i].label;
        }
      }
      return val;
    },
    slug: function() {
      return slugify(this.label) + '-' + this._uid;
    },
    errorMessage: function() {
      if (!this.hasError) {
        return '';
      }

      return 'Please select a value';
    }
  },

  methods: {
    checkChange: function() {
      if (this.content) {
        let val = this.content;
        if (val === `other-${this._uid}`) {
          val = this.text;
        }
        this.$emit('input', val);
      }
    },
    slugify: function(text) {
      return slugify(text) + '-' + this._uid;
    }
  },

  mounted: function() {}
};
</script>

<style lang="scss">
.c-input-select-other {
  .form__label {
    margin-bottom: var(--space-md);
  }

  .input-radio {
    margin-bottom: var(--margin-lg);
    cursor: pointer;

    label {
      position: relative;
      padding-left: var(--space-md);
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        top: calc(50% - 8px);
        left: 0;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: transparent;
        box-shadow: 0 0 0 2px #aaa;
      }

      &:after {
        content: '';
        position: absolute;
        top: calc(50% - 6px);
        left: 2px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: transparent;
      }

      &:hover {
        &:after {
          background-color: #aaa;
        }
      }
    }
    input {
      display: none;
      &:checked ~ label {
        &:before {
          box-shadow: 0 0 0 2px var(--p3);
        }
        &:after {
          background-color: var(--p3);
        }
      }
    }
  }

  &__text {
    .form-group {
      margin-top: 0;
    }
  }
}
</style>
