<template>
  <div class="c-layout">
    <component :is="layoutComponent"> </component>
  </div>
</template>

<script>
import Default from './default';
import NoSidebar from './no-sidebar';

export default {
  components: {
    Default,
    NoSidebar
  },

  computed: {
    layoutComponent: function() {
      if (this.$route.meta && this.$route.meta.layout) {
        return this.$route.meta.layout;
      }
      return 'default';
    }
  }
};
</script>

<style lang="scss">
.c-layout {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>
