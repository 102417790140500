<template>
  <div class="c-location-info-window">
    <InfoWindow ref="infoWindow" :sections="sections" @onClose="onClose">
      <template v-slot:header v-if="currentLocation">
        <h3 v-if="currentLocation.name">{{ currentLocation.name }}</h3>
        <h3 v-else>
          {{ currentLocation.streetAddress }} - {{ organisation.name }}
        </h3>
        <p>
          {{ currentLocation.streetAddress }}, {{ currentLocation.suburb }},
          {{ currentLocation.postalCode }}, AU
        </p>
      </template>
      <!-- <template v-slot:section v-if="currentLead && currentLead.notes">
        <div class="c-info-window-section">
          <div class="c-info-window-section__header">
            Notes
          </div>
          <div class="c-info-window-section__body">
            <NotesCompact :resources="currentLead.notes"></NotesCompact>
          </div>
        </div>
      </template> -->
    </InfoWindow>
  </div>
</template>

<script>
import InfoWindow from '@/components/global/info-window';
import NotesCompact from '@/components/notes/compact';

export default {
  components: {
    InfoWindow,
    NotesCompact
  },

  props: {
    id: {
      type: Number,
      default: null
    }
  },

  watch: {
    currentLocation: function() {
      if (this.currentLocation) {
        this.$refs.infoWindow.activate();
      } else {
        this.$refs.infoWindow.deactivate();
      }
    }
  },

  computed: {
    active: function() {
      if (this.id) {
        return true;
      } else {
        return false;
      }
    },
    currentLocation: function() {
      if (!this.id) {
        return;
      }
      let location = this.$store.getters['locations/one'](this.id);
      return location;
    },
    organisation: function() {
      if (!this.currentLocation) {
        return null;
      }
      let organisation = this.$store.getters['organisations/one'](
        this.currentLocation.organisationId
      );
      return organisation;
    },
    sections: function() {
      let location = this.currentLocation;
      let organisation = this.organisation;

      if (!location) {
        return [];
      }

      let sections = [];

      let items = [];
      let notes = [];

      items.push({
        label: 'Street Address',
        value: location.streetAddress
      });

      items.push({
        label: 'Postcode',
        value: location.postalCode
      });

      items.push({
        label: 'State',
        value: location.state
      });

      items.push({
        label: 'Country',
        value: location.country
      });
      if (organisation) {
        items.push({
          label: 'Organisation',
          value: organisation.name
        });
      }
      items.push({
        label: 'Landline number',
        value: location.landline
      });

      items.push({
        label: 'Email Address',
        value: location.email
      });

      sections.push({
        name: 'Details',
        items: items
      });

      return sections;
    }
  },

  methods: {
    onClose: function() {
      this.$emit('onClose');
    }
  }
};
</script>

<style lang="scss">
.c-location-info-window {
  h2 {
    font-size: var(--font-size-xl);
    font-weight: 700;
  }
}
</style>
