<template>
  <form @submit.prevent="onSubmit">
    <Form
      ref="form"
      v-model="form"
      :schema="schema"
      :fields="fields"
      @status="saveStatus"
    ></Form>
    <InputButton class="btn btn-primary" type="submit">
      Add RCTI
    </InputButton>
  </form>
</template>

<script>
import Form from '@/components/global/form';

import schema from './schema.js';

import InputButton from '@/components/global/button';

import parseFormFields from '@/lib/parse-form-fields';

export default {
  components: {
    InputButton,

    Form
  },

  data: function() {
    return {
      status: null,

      schema: schema,

      form: {}
    };
  },

  computed: {
    fields: function() {
      return [];
    }
  },

  methods: {
    saveStatus(status) {
      this.status = status;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      let form = parseFormFields(this.form);

      let resource = await this.$store.dispatch('rctis/add', form);

      if (resource) {
        this.$router.push('/orders/rctis');
      }
    }
  }
};
</script>

<style></style>
