import { statuses } from '@/lib/form-data';

import defaults from '@/lib/defaults.js';

const schema = [
  {
    type: 'accordion',
    label: 'Organisation Details',
    fields: {
      name: {
        component: 'InputText',
        label: 'Organisation name',
        properties: {},
        validations: {
          maxLength: {
            params: defaults.maxStringInput,
            message: 'No more than 60 letters allowed'
          },
          required: {
            params: null,
            message: 'This field is required'
          }
        }
      },
      legalName: {
        component: 'InputText',
        label: 'Legal name',
        maxLength: {
          params: defaults.maxStringInput,
          message: 'No more than 60 letters allowed'
        },
        description:
          'Enter the legal business name for this organisation. It will be picked up by the ordering system'
      },
      category: {
        component: 'InputEnum',
        label: 'Category',
        enum: 'status',
        properties: {
          columnSize: 12
        },
        options: 'status',
        validations: {
          required: {
            params: null,
            message: 'This field is required'
          }
        }
      },
      abn: {
        component: 'InputText',
        label: 'ABN',
        description: 'Required for the ordering system.',
        validations: {
          maxLength: {
            params: defaults.maxStringInput,
            message: 'No more than 60 letters allowed'
          }
        }
      },
      hpio_name: {
        component: 'InputText',
        label: 'HPIO name',
        properties: {
          columnSize: 6
        }
      },
      hpio_number: {
        component: 'InputText',
        label: 'HPIO number',
        properties: {
          columnSize: 6
        }
      },
      facility_type: {
        component: 'InputEnum',
        enum: 'facilityTypeCodes',
        label: 'Facility type',
        properties: {
          columnSize: 6
        }
      },
      type: {
        component: 'InputEnum',
        enum: 'organisationType',
        label: 'Organisation type',
        properties: {
          columnSize: 6
        }
      },
      landline: {
        component: 'InputText',
        label: 'Landline',
        description: 'Add a company wide landline number',
        properties: {
          columnSize: 6
        },
        validations: {
          maxLength: {
            params: defaults.maxStringInput,
            message: 'No more than 60 letters allowed'
          }
        }
      },
      email: {
        component: 'InputText',
        label: 'Email',
        description: 'Add a company wide email',
        properties: {
          columnSize: 6
        },
        validations: {
          maxLength: {
            params: defaults.maxStringInput,
            message: 'No more than 60 letters allowed'
          }
        }
      },
      userId: {
        component: 'InputUsers',
        label: 'Assigned to'
      }
    }
  },
  {
    type: 'insert'
  }
];

export default schema;
