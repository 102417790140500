import CrudStore from '@/lib/crud-store.js';
var crud = new CrudStore('notes');

const state = {
  ...crud.state
};
const getters = {
  ...crud.getters,

  filtered: function(state) {
    let resources = state.resources;

    resources.sort(function compare(a, b) {
      var dateA = new Date(a.createdAt);
      var dateB = new Date(b.createdAt);
      return dateA - dateB;
    });

    return resources;
  },

  byLead: function(state, getters) {
    let resources = getters.filtered;

    return id => {
      let newResources = [];
      resources.map(resource => {
        if (resource.leadId === id) {
          newResources.push(resource);
        }
      });

      return newResources;
    };
  },

  byOrganisation: function(state, getters, rootState, rootGetters) {
    let resources = getters.filtered;

    return id => {
      let leads = rootGetters['leads/byOrganisation'](id);
      let ids = leads.map(lead => {
        return lead.id;
      });

      let newResources = [];

      resources.map(resource => {
        if (ids.includes(resource.leadId)) {
          newResources.push(resource);
        }
      });

      return newResources;
    };
  }
};
const actions = {
  ...crud.actions
};
const mutations = {
  ...crud.mutations
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;
