function generatePublicTemplate(order) {
  let str = `
  <p>
  Provisioning Fulfilment ${order.bundleId} : [Service Name]
  </p>
  <p>
  Hi ${order.lead.firstName} ${order.lead.lastName},
  </p>
  <p>
  Thank you for your time and looking forward to continuing the onboarding process for your iCOPE Screening.
  </p>
  <p>
  As discussed, we have issued the Provisioning Fulfillment ${order.bundleId} for the allocation of X (X) licences (which allow X people to simultaneously use the tool across your multiple perinatal services) for initiating the process to support your service to begin utilising the iCOPE perinatal mental health application on the Diginostic screening platform; which has been made freely available to you through the Australian Government's National Perinatal Mental Health Check initiative.
  </p>
  <p>
  We look forward to providing you with ongoing support to ensure you can begin using the iCOPE screen application on the Diginostic platform for you service to support alignment with the National Perinatal Mental Health Guidelines. As discussed, this allows for COVID safe screening in clinic and with telehealth appointments.
  </p>
  <p>
  Please note this email and the attached requires no action on your part, it is for your information only. This initiative is funded by the Australian Government and no charges apply.
  </p>
  <p>
  [Follow Up]
  </p>
  <p>
  If you have any questions, please feel free to call me on 0423 692 899.
  </p>
  `;

  return str;
}

export default generatePublicTemplate;
