<template>
  <div class="c-custom-forms">
    <transition name="slide">
      <div class="c-custom-forms__form" key="form" v-if="!submitted">
        <div class="c-custom-forms__form__header">
          <img v-if="meta.logo" :src="meta.logo" />
          <h1>
            {{ meta.title }}
          </h1>
        </div>
        <article v-if="meta.content" v-html="meta.content"></article>
        <form @submit.prevent="onSubmit">
          <Form
            ref="form"
            v-model="form"
            :schema="schema"
            :fields="fields"
            @status="saveStatus"
          ></Form>
          <p class="c-custom-forms__error-message" v-if="hasErrors">
            One or more form fields are incompleted. Please check this form for
            missing fields and resend.
          </p>
          <button ref="submitButton" type="submit" class="btn btn-primary">
            Send
          </button>
        </form>
      </div>
      <div class="c-custom-forms__submit" key="submit" v-if="submitted">
        <article v-html="submit.content || ''"></article>
      </div>
    </transition>
  </div>
</template>

<script>
import Form from '@/components/global/form';
import h2h from './h2h.js';
import eoi from './eoi.js';

import anime from 'animejs';

export default {
  components: { Form },

  data: function() {
    return {
      status: null,
      submitted: false,
      hasErrors: false,
      form: {},
      fields: []
    };
  },

  watch: {
    hasErrors: function() {
      if (this.hasErrors) {
        this.animateError();
      }
    }
  },

  computed: {
    meta: function() {
      let meta = {
        title: 'Custom form',
        description: ''
      };
      let schema = this.schema || [];
      for (let i = 0; i < schema.length; i++) {
        if (schema[i].type === 'meta') {
          meta = schema[i];
          break;
        }
      }
      return meta;
    },
    submit: function() {
      let submit = {
        title: 'Thank you',
        description: ''
      };
      let schema = this.schema || [];
      for (let i = 0; i < schema.length; i++) {
        if (schema[i].type === 'submit') {
          submit = {
            ...submit,
            ...schema[i]
          };
          break;
        }
      }
      return submit;
    },
    schema: function() {
      let slug = this.slug;
      if (slug === 'h2h') {
        return h2h;
      }
      if (slug === 'eoi') {
        return eoi;
      }
    },
    slug: function() {
      return this.$route.params.slug;
    }
  },

  methods: {
    animateError: function() {
      const xMax = 16;
      const shake = anime({
        targets: this.$refs.submitButton,
        easing: 'easeInOutSine',
        duration: 500,
        translateX: [
          {
            value: xMax * -1
          },
          {
            value: xMax
          },
          {
            value: xMax / -2
          },
          {
            value: xMax / 2
          },
          {
            value: 0
          }
        ],
        autoplay: true
      });
    },
    saveStatus: function(status) {
      this.status = status;
    },
    async onSubmit() {
      this.$refs.form.validate();

      console.log(this.status);

      if (this.status.invalid) {
        this.hasErrors = true;
        return;
      }
      let form = this.form;

      let payload = {
        form,
        id: this.slug,
        action: 'submit'
      };

      let resource = await this.$store.dispatch(
        'customForms/doAction',
        payload
      );

      if (resource) {
        this.submitted = true;
      }
      return;
    }
  }
};
</script>

<style lang="scss">
.c-custom-forms {
  &__error-message {
    margin-bottom: var(--space-md);
    color: var(--danger-color);
  }
  .slide-enter-active {
    transition: all 250ms ease 250ms;
  }
  .slide-leave-active {
    transition: all 250ms cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-enter, .slide-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  article {
    p {
      margin-bottom: var(--margin-lg);
    }
  }

  &__form {
    &__header {
      display: flex;
      align-items: center;

      img {
        width: 128px;
        height: 128px;
        object-fit: contain;
        object-position: center center;
        margin-right: var(--space-md);
      }
    }

    .c-input-error {
      position: relative;
      margin: var(--margin-lg) 0 0;
      font-size: var(--font-size-sm);
      font-weight: 500;
      color: var(--danger-color);

      &:before {
        content: '';
        position: absolute;
        top: 7px;
        left: -74px;
        width: 70px;
        height: 2px;
        background-color: var(--danger-color);
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: -92px;
        width: var(--space-sm);
        height: var(--space-sm);
        background-color: var(--danger-color);
        border-radius: 100%;
        box-shadow: 0 0 0 0 hsla(354, 65%, 56%, 1);
        animation: pulse 2s infinite;
      }
    }

    @keyframes pulse {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 hsla(354, 65%, 56%, 0.7);
      }

      70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px hsla(354, 65%, 56%, 0);
      }

      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 hsla(354, 65%, 56%, 0);
      }
    }
  }
}
</style>
