<template>
  <form @submit.prevent="onSubmit">
    <Form
      ref="form"
      v-model="form"
      :schema="schema"
      :fields="fields"
      @status="saveStatus"
    ></Form>
    <InputButton :processing="processing" class="btn btn-primary" type="submit">
      Add Organisation
    </InputButton>
  </form>
</template>

<script>
import Form from '@/components/global/form';

import schema from './schema.js';

import InputButton from '@/components/global/button';

import parseFormFields from '@/lib/parse-form-fields';

export default {
  components: {
    InputButton,

    Form
  },

  data: function() {
    return {
      status: null,

      schema: schema,

      processing: false,

      form: {}
    };
  },

  computed: {
    fields: function() {
      return this.$store.getters['fields/all'];
    }
  },

  methods: {
    saveStatus(status) {
      this.status = status;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = parseFormFields(this.form);

      let organisation = await this.$store.dispatch('organisations/add', form);

      this.processing = false;

      if (organisation) {
        this.$router.push('/organisations');
      }
    }
  }
};
</script>

<style></style>
