/**
 * Basically, merges a resource object to another object and in the process, removes a few keys if needed
 */
function destructureForm(resource, keys = []) {
  let newForm = {};

  if (!resource) {
    return {};
  }

  for (let key in resource) {
    if (!keys.includes(key)) {
      newForm[key] = resource[key];
    }
  }

  return newForm;
}

export default destructureForm;
