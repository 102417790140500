<template>
  <div :class="['c-list-row', { selected: isSelected === true }]">
    <template v-for="column in columns">
      <div
        class="c-list-item"
        :key="column.key"
        :class="column.key === '_checkbox' ? 'c-list-item--checkbox' : ''"
      >
        <component
          :is="getComponent(column)"
          :resource="resource"
          :column="column"
          :selected="isSelected"
          @click="onClick"
        ></component>
      </div>
    </template>
  </div>
</template>

<script>
import InputCheckbox from '@/components/global/forms/input-checkbox';
import ItemBadge from './item-badge';
import ItemText from './item-text';
import ItemCheckbox from './item-checkbox';
import ItemStatus from './item-status';
import ItemAction from './item-action';
import ItemStage from './item-stage';
import ItemDate from './item-date';
import ItemNote from './item-note';
import ItemId from './item-id';
import ItemUser from './item-user';
import ItemEnum from './item-enum';
import ItemFollowupDate from './item-followup-date';
import ItemOrganisation from './item-organisation';

export default {
  components: {
    InputCheckbox,
    ItemBadge,
    ItemText,
    ItemCheckbox,
    ItemStatus,
    ItemAction,
    ItemStage,
    ItemDate,
    ItemNote,
    ItemId,
    ItemUser,
    ItemEnum,
    ItemFollowupDate,
    ItemOrganisation
  },

  props: {
    columns: {
      type: Array,
      default: function() {
        return [];
      }
    },
    resource: {
      type: Object,
      default: function() {
        return {};
      }
    },
    selected: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },

  computed: {
    isSelected: function() {
      let selected = this.selected;
      let resourceId = this.resource.id;

      if (selected.length === 0) {
        return false;
      }

      if (!selected.includes(resourceId)) {
        return false;
      }

      return true;
    }
  },

  methods: {
    onClick: function(action, id, key) {
      this.$emit('click', { action, id, key });
    },
    getComponent: function(column) {
      // If a renderer is specifically mentioned
      if (column.renderer) {
        return column.renderer;
      }

      // Special column types
      if (column.key === '_checkbox') {
        return 'ItemCheckbox';
      }
      if (column.key === '_status') {
        return 'ItemStatus';
      }
      if (column.key === '_action') {
        return 'ItemAction';
      }

      // Finally, if nothing matches, throw the itemtext component at it
      return 'ItemText';
    }
  }
};
</script>

<style lang="scss">
.c-list-row {
  display: table-row;

  &.selected {
    background-color: var(--color-grey-lightest);
  }

  &::before,
  &::after {
    content: '';
  }
}

.c-list-item {
  border-bottom: 1px solid var(--color-bg-4);
  display: table-cell;
  padding: var(--space-xs);
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;

  &--checkbox {
    padding-left: 0;
    padding-right: 0;
  }

  a {
    display: inline-block;
    max-width: 20rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
