<template>
  <div :class="['c-popup', { active: currentlyActive === true }]" ref="popup">
    <div class="c-popup__arrow" data-popper-arrow></div>
    <slot></slot>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';

export default {
  data: function() {
    return {
      popper: null,
      currentlyActive: false
    };
  },

  watch: {
    target: {
      handler: function() {
        this.$nextTick(() => {
          this.setup();
        });
      },
      immediate: true
    },
    active: {
      handler: function() {
        this.currentlyActive = this.active;
      },
      immediate: true
    }
  },

  props: {
    target: {
      default: null
    },
    active: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    modifiers: function() {
      let modifiers = [
        {
          name: 'offset',
          options: {
            offset: [0, 16]
          }
        },
        {
          name: 'arrow',
          options: {
            padding: 6
          }
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top'],
            allowedAutoPlacements: ['top']
          }
        }
      ];
      return modifiers;
    },
    virtualElement: function() {
      let el = this.target;

      if (!el) {
        return null;
      }

      if (typeof el === 'string') {
        return document.querySelector(el);
      }

      return el;
    }
  },

  methods: {
    generateGetBoundingClientRect: function(x = 0, y = 0) {
      return () => ({
        width: 0,
        height: 0,
        top: y,
        right: x,
        bottom: y,
        left: x
      });
    },
    setup: function() {
      let popper = this.$refs.popup;
      let virtualElement = this.virtualElement;
      let options = {
        modifiers: this.modifiers
      };
      if (!virtualElement) {
        return;
      }
      this.popper = createPopper(virtualElement, popper, options);
    },
    stop: function() {
      this.$emit('onClose');
    },
    update: function() {},
    onKeydown: function(e) {
      if (e.code === 'Escape') {
        this.stop();
      }
    },
    onMousedown: function(e) {
      if (!this.active) {
        return;
      }

      let target = '.c-popup';

      if (e.target.closest(target)) {
      } else {
        this.stop();
      }
    },
    addEventListeners: function() {
      window.addEventListener('keydown', this.onKeydown);
      window.addEventListener('mousedown', this.onMousedown);
    },
    removeEventListeners: function() {
      window.removeEventListener('keydown', this.onKeydown);
      window.removeEventListener('mousedown', this.onMousedown);
    }
  },

  mounted: function() {
    this.addEventListeners();
  },
  beforeDestroy: function() {
    this.removeEventListeners();
  }
};
</script>

<style lang="scss">
.c-popup {
  position: absolute;
  width: 360px;
  z-index: var(--z-2);
  background-color: #fbfbfb;
  border-radius: var(--border-radius-lg);
  box-shadow: 0px 1px 16px 0 rgba(0, 0, 0, 0.15), 0 1px 40px rgba(0, 0, 0, 0.04);
  // transition: opacity 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
  //   transform 300ms cubic-bezier(0.17, 0.67, 0.58, 1.21);
  opacity: 0;
  transform: scale(0);
  pointer-events: none;

  &.active {
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
  }

  // .c-notes-compact {
  //   background-color: rgb(247, 247, 247);
  // }

  &__arrow {
    display: inline-block;
    width: 24px;
    height: 24px;
    //background-color: red;
    pointer-events: none;

    &:after {
      content: '';
      position: absolute;
      bottom: 11px;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fbfbfb;
      transform: rotate(90deg);
      mask-repeat: no-repeat;
      mask-image: url('~@/assets/notification-arrow.svg');
    }
  }
}
</style>
