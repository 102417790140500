var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.type === 'link')?_c('a',{class:[
    'btn btn-primary',
    { disabled: _vm.disabled === true || _vm.processing === true },
    _vm.icon ? ("icon icon--" + _vm.icon) : ''
  ],attrs:{"href":_vm.href},on:{"click":function($event){return _vm.$emit('onClick', $event)}}},[(_vm.processing)?_c('span',{staticClass:"spinner"}):_vm._e(),_vm._t("default")],2):_c('button',{class:[
    'btn btn-primary',
    { disabled: _vm.disabled === true || _vm.processing === true },
    _vm.icon ? ("icon icon--" + _vm.icon) : ''
  ],attrs:{"disabled":_vm.disabled,"type":_vm.type},on:{"click":function($event){return _vm.$emit('onClick', $event)}}},[(_vm.processing)?_c('span',{staticClass:"spinner"}):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }