<template>
  <div class="form-group c-input-text-multiple">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <div class="c-input-text-multiple__row" v-for="(row, i) in rows" :key="i">
      <input
        class="form-control"
        :id="slug"
        type="text"
        v-model="row.value"
        :placeholder="placeholder"
      />
      <button class="btn" @click="onRowRemove(i)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path
            fill="currentColor"
            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z"
          />
        </svg>
      </button>
    </div>
    <div class="c-input-text-multiple__row">
      <button class="btn btn-primary" @click="onRowAdd">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path
            fill="currentColor"
            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11H7v2h4v4h2v-4h4v-2h-4V7h-2v4z"
          />
        </svg>
      </button>
    </div>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
  </div>
</template>

<script>
import InputError from './input-error';
import InputDescription from './input-description';
import slugify from '@/lib/slugify';

export default {
  components: {
    InputDescription,
    InputError
  },

  data: function() {
    return {
      rows: []
    };
  },

  watch: {
    value: function() {},
    rows: {
      handler: function() {
        this.outputRows();
      },
      deep: true
    }
  },

  props: {
    label: {
      type: String,
      default: ''
    },
    value: {},
    placeholder: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: null
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },

  computed: {
    slug: function() {
      return slugify(this.label);
    }
  },

  methods: {
    onRowRemove: function(i) {
      this.rows.splice(i, 1);
    },
    onRowAdd: function() {
      this.rows.push({ value: '' });
    },
    onRowChange: function(e, i) {
      this.$set(this.rows, i, e.target.value);
    },
    onChange: function(row, i) {
      this.rows[i] = row;
      let value = row;

      this.$emit('input', value);
    },

    value2Rows: function() {
      let value = this.value;

      let rows = [];

      if (typeof value !== 'object') {
        // This shouldn't happen but oh well

        value = value + '';

        rows.push(value);
      } else if (value) {
        for (let i = 0; i < value.length; i++) {
          rows.push({
            value: value[i]
          });
        }
      }

      if (rows.length === 0) {
        rows.push({
          value: ''
        });
      }

      this.rows = rows;
    },
    outputRows: function() {
      let rows = this.rows;

      let textRows = [];

      for (let i = 0; i < rows.length; i++) {
        textRows.push(rows[i].value);
      }

      this.$emit('input', textRows);
    }
  },

  mounted: function() {
    this.value2Rows();
  }
};
</script>

<style lang="scss">
.c-input-text-multiple {
  .form__label {
    margin-bottom: var(--space-sm);
  }
  &__row {
    display: flex;
    margin-bottom: var(--margin-lg);

    input {
      margin: 0;
      margin-right: var(--margin-lg);
      flex-grow: 1;
    }

    button.btn-primary {
      width: 100%;
      color: var(--color-white);
    }
  }
}
</style>
