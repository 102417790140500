<template>
  <div class="c-toast"></div>
</template>

<script>
export default {
  watch: {
    eventTrigger: function() {
      this.showMessage(this.event);
    }
  },

  computed: {
    event: function() {
      return this.$store.getters['events/event'];
    },
    eventTrigger: function() {
      return this.$store.getters['events/eventTrigger'];
    }
  },

  methods: {
    showMessage(event) {
      let toast = this.$toasted.show(event.message, {
        theme: 'toasted-primary',
        position: 'bottom-right',
        duration: 5000
      });
    }
  },

  mounted: function() {
    this.$store.dispatch('events/onServerError');
  }
};
</script>

<style></style>
