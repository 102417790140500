<template>
  <div class="c-leads-create">
    <BreadCrumb></BreadCrumb>
    <h1>Update RCTI {{ resource.id }}</h1>
    <FormUpdate :resource="resource"></FormUpdate>
    <Button
      :processing="processing.generate_invoice"
      @onClick="onAction('generate_invoice')"
      >Generate RCTI</Button
    >
    <Button
      :processing="processing.send_invoice"
      @onClick="onAction('send_invoice')"
      >Send to client</Button
    >
    <JsonRenderer :json="JSON.stringify(resource, null, 2)"></JsonRenderer>
  </div>
</template>

<script>
import Button from '@/components/global/button';
import BreadCrumb from '@/components/global/breadcrumb';
import FormUpdate from './form-update';

import JsonRenderer from '@/components/global/json-renderer';

export default {
  components: {
    Button,
    BreadCrumb,
    FormUpdate,
    JsonRenderer
  },

  data: function() {
    return {
      processing: {
        generate_invoice: false,
        send_invoice: false
      }
    };
  },

  computed: {
    resource: function() {
      let id = this.$route.params.id;
      return this.$store.getters['rctis/one'](id);
    }
  },

  methods: {
    async onClick(action) {
      let id = this.resource.id;
      let obj = {
        id,
        action
      };
      let response = await this.$store.dispatch('rctis/doAction', obj);
    },
    async onAction(action) {
      this.processing[action] = true;

      let id = this.resource.id;
      let obj = {
        id,
        action
      };

      let response = await this.$store.dispatch('rctis/doAction', obj);

      this.processing[action] = false;
    }
  }
};
</script>

<style lang="scss">
.c-rctis-create {
}
</style>
