<template>
  <div class="c-reports">
    <h1>
      Reports
    </h1>

    <p>
      Awaiting metrics.
    </p>

    <Header></Header>
  </div>
</template>

<script>
import Header from './header';
import Item from './item';
import Button from '@/components/global/button';

export default {
  components: {
    Header,
    Item,
    Button
  },

  data: function() {
    return {
      isProcessing: false
    };
  },

  computed: {},

  methods: {}
};
</script>

<style lang="scss">
.c-reports {
  &__grid {
    display: grid;
    margin: var(--space-sm) 0;
    grid-template-columns: 1fr;
  }
}
</style>
