<template>
  <div class="c-form c-form-reset-password">
    <form @submit.prevent="onSubmit">
      <InputText label="Token" :value="token"> </InputText>
      <InputPassword
        label="New password"
        v-model="form.password"
        :validation="$v.form.password"
        description="Please type your new password"
      ></InputPassword>
      <Button :processing="processing" type="submit">Reset</Button>
    </form>
  </div>
</template>

<script>
import Button from '@/components/global/button';
import InputText from '@/components/global/forms/input-text';
import InputPassword from '@/components/global/forms/input-password';

import { validationMixin } from 'vuelidate';
import { required, email, minLength, between } from 'vuelidate/lib/validators';

export default {
  components: {
    Button,
    InputText,
    InputPassword
  },

  mixins: [validationMixin],

  data: function() {
    return {
      processing: false,
      form: {
        password: ''
      }
    };
  },

  props: {
    token: {
      type: String,
      default: ''
    }
  },

  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  },

  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.invalid) {
        return;
      }
      this.processing = true;
      let token = this.token;
      let form = {
        ...this.form,
        token
      };
      let condition = await this.$store.dispatch('users/resetPassword', form);

      if (condition) {
        await this.$store.dispatch('afterLogin');
        this.$emit('submit');
      }
      this.processing = false;
    }
  }
};
</script>

<style lang="scss">
.c-form-reset-password {
  .form-control {
    text-align: center;
  }

  .btn {
    margin-top: var(--space-lg);
  }

  .c-input-text {
    display: none;
  }
}
</style>
