<template>
  <form @submit.prevent="onSubmit" class="c-orders-form-update-public">
    <article class="article-text">
      <p>
        This is the Commonwealth public fund based Order. Also known as the
        Public workflow internally.
      </p>
    </article>
    <Form
      ref="form"
      v-model="form"
      :schema="schema"
      :fields="fields"
      @status="saveStatus"
    ></Form>
    <InputButton
      :processing="processing.form"
      class="btn btn-primary"
      type="submit"
    >
      Update Order
    </InputButton>

    <br />
    <br />

    <strong>Client ID in use: {{ clientId }}</strong>

    <h2>
      Actions
    </h2>
    <div class="c-card-container">
      <div class="c-card" v-if="resource">
        <article class="article-text">
          <p>
            Create a Sandbox
          </p>
        </article>
        <a
          v-if="!resource.sandboxManuallyCreatedDate"
          :disabled="processing.sandbox"
          :class="[
            'btn',
            { disabled: processing.sandbox === true },
            { 'btn-secondary': !resource.sandboxManuallyCreatedDate }
          ]"
          @click="createSandbox"
          ><spinner></spinner>Sandbox</a
        >
        <article class="c-error" v-if="errors.sandbox">
          {{ errors.sandbox }}
        </article>
        <article v-if="resource.sandboxManuallyCreatedDate">
          <p>
            Sandbox last created on
            <Date :date="resource.sandboxManuallyCreatedDate"></Date>
          </p>
        </article>
      </div>
      <div class="c-card" v-if="resource">
        <article class="article-text">
          <p>
            Send an invitation email to
            <router-link :to="`/people/update/${resource.lead.id}`"
              >{{ resource.lead.firstName }}(lead)</router-link
            >
          </p>
        </article>
        <a
          :disabled="processing.sendEmail"
          :class="['btn', { 'btn-secondary': !resource.emailSentDate }]"
          @click="sendEmail"
          >Send Email</a
        >
        <article v-if="resource.emailSentDate">
          <p>
            Email to lead last sent on
            <Date :date="resource.emailSentDate"></Date>
          </p>
        </article>
      </div>
    </div>
  </form>
</template>

<script>
import Form from '@/components/global/form';

import schema from './schema.js';

import InputButton from '@/components/global/button';

import parseFormFields from '@/lib/parse-form-fields';

import generatePublicTemplate from '@/lib/generate-public-template';

import Date from '@/components/global/date';

export default {
  components: {
    InputButton,

    Form,

    Date
  },

  data: function() {
    return {
      status: null,

      processing: {
        sandbox: false,
        form: false,
        sendEmail: false
      },

      errors: {
        sandbox: ''
      },

      form: {
        content: ''
      }
    };
  },

  props: {
    resource: {
      type: Object,
      default: function() {
        return null;
      }
    }
  },

  computed: {
    clientId: function() {
      if (this.resource.clientId) {
        return this.resource.clientId;
      } else if (
        this.resource.copePayload &&
        this.resource.copePayload.client_id
      ) {
        return this.resource.copePayload.client_id;
      }
      return;
    },
    schema: function() {
      let currentSchema = JSON.parse(JSON.stringify(schema));
      let form = this.form;

      let fields = currentSchema[0].fields;

      fields.products.readonly = true;

      fields.content = {
        component: 'InputEditor',
        label: 'Content',
        properties: {
          columnSize: 12
        },
        validations: {}
      };

      fields.adminTrainingDate = {
        component: 'InputDateTimePicker',
        label: 'Admin training date',
        description: `If not set, you'll be asked to create a reminder for admin training`,
        properties: {
          columnSize: 4
        },
        validations: {}
      };

      fields.teamTrainingDate = {
        component: 'InputDateTimePicker',
        label: 'Team training date',
        description: `If not set, you'll be asked to create a reminder for team training`,
        properties: {
          columnSize: 4
        },
        validations: {}
      };

      fields.hasFirstScreenOccuredDate = {
        component: 'InputDateTimePicker',
        label: 'Has first Screen occured date',
        description:
          'Not manually editable. Updates automatically when first screening of this order has happened',
        properties: {
          columnSize: 4
        },
        readonly: true
      };

      // Also make this available for private flow
      fields.clientId = {
        component: 'InputText',
        label: 'Client ID',
        description:
          'Set client ID manually if needed to. Otherwise create a sandbox using the actions below and a client ID will automatically be available for this order.'
      };

      if (!form.location) {
        fields.lead.readonly = true;
        fields.lead.description = 'Set a location to select a lead';

        form.lead = null;
      }

      if (form.location) {
        let organisation = this.$store.getters['organisations/one'](
          form.location.organisationId
        );

        fields.lead.filter = function(options) {
          options = options.filter(option => {
            if (option.organisationId === organisation.id) {
              return true;
            }
            return false;
          });
          return options;
        };
      }

      fields.lead.readonly = true;

      if (fields.location) {
        fields.location.readonly = true;
      }

      fields.billingMethod.readonly = true;

      currentSchema[0].fields = fields;

      return currentSchema;
    },
    fields: function() {
      return [];
    }
  },

  methods: {
    saveStatus(status) {
      this.status = status;
    },
    async onSubmit() {
      this.processing.form = true;
      this.$refs.form.validate();

      if (this.status.invalid) {
        this.processing = false;
        return;
      }

      let form = parseFormFields(this.form);

      let resource = await this.$store.dispatch('orders/update', form);

      this.processing.form = false;

      if (resource) {
        this.$router.push('/orders');
      }
    },

    destructureCustomFields: function(form) {
      let fields = {};
      let newForm = {};

      if (!form) {
        return {};
      }

      for (let key in form) {
        if (key !== 'notes' && key !== 'organisation' && key !== 'fields') {
          newForm[key] = form[key];
        }
      }

      return newForm;
    },

    async createSandbox() {
      this.processing.sandbox = true;
      let id = this.resource.id;
      let action = `create-sandbox`;
      let obj = {
        id,
        action
      };
      this.processing.sendEmail = true;
      let response = await this.$store.dispatch('orders/doAction', obj);

      if (!response) {
        this.errors.sandbox = `Make sure organisations, location and lead forms have been completely filled out.`;
      } else {
        this.errors.sandbox = ``;
      }

      this.processing.sandbox = false;
    },

    async sendEmail() {
      let id = this.resource.id;
      let action = `send-email`;
      let obj = {
        id,
        action
      };
      this.processing.sendEmail = true;
      let response = await this.$store.dispatch('orders/doAction', obj);
    }
  },

  created: function() {
    let form = this.destructureCustomFields(this.resource);

    this.form = form;

    if (!this.form.content) {
      this.form.content = generatePublicTemplate(this.resource);
    }
  }
};
</script>

<style lang="scss">
.c-orders-form-update-public {
  > h2 {
    margin-top: var(--space-md);
  }

  .c-card-container {
    display: grid;
    padding-bottom: var(--space-md);
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
  }
  .c-card {
    padding: var(--space-md);
    border-radius: var(--border-input);
    box-shadow: var(--box-shadow-input);

    .btn {
      margin-bottom: var(--margin);
    }
  }

  .c-error {
    color: var(--color-danger);
    background-color: rgb(255, 236, 236);
    padding: var(--margin-lg);
    border-radius: 10px;
  }
}
</style>
