<template>
  <div :class="['c-modal-login', { staging: staging === true }]">
    <Modal v-model="computedActive" :closable="false">
      <transition name="slide">
        <div class="c-modal-login__tab tab-1" v-if="tab === 1" key="tab1">
          <img src="/images/logo-ordering-website.png" />
          <h4>
            Welcome back!
          </h4>
          <FormLogin @submit="onSubmit"></FormLogin>
          <br />
          <a href="#" class="" @click="tab = 3">Forgot password?</a>
        </div>
        <div class="c-modal-login__tab tab-2" v-if="tab === 2" key="tab2">
          <h1>
            Reset password
          </h1>
          <FormResetPassword
            :token="token"
            @submit="onSubmit"
          ></FormResetPassword>
        </div>
        <div class="c-modal-login__tab tab-2" v-if="tab === 3" key="tab3">
          <h1>
            Reset your password
          </h1>
          <p>
            Enter your login email.<br />
            We'll send a reset password link to your inbox.
          </p>
          <FormResetPasswordRequest
            :token="token"
            @submit="onSubmit"
          ></FormResetPasswordRequest>
        </div>
      </transition>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/global/modal';
import FormLogin from '@/components/global/form-login';
import FormResetPassword from '@/components/global/form-reset-password';
import FormResetPasswordRequest from '@/components/global/form-reset-password-request';

export default {
  components: {
    Modal,
    FormLogin,
    FormResetPassword,
    FormResetPasswordRequest
  },

  data: function() {
    return {
      tab: 1,
      token: '',
      options: {
        name: 'modal-login',
        active: false
      }
    };
  },

  watch: {
    'options.active': {
      handler: function() {}
    }
  },

  computed: {
    routeName: function() {
      return this.$route.name;
    },
    computedActive: function() {
      let currentActive = this.options.active;

      // override if route matches custom forms
      if (this.routeName === 'CustomForms') {
        return false;
      }

      return currentActive;
    },
    staging: function() {
      return true;
    }
  },

  methods: {
    onSubmit: function() {
      if (this.tab === 2) {
        this.tab = 1;
      }
      this.options.active = false;
    },

    activate: function() {
      this.options.active = true;
    },
    checkQueryParams: function() {
      let search = location.search.substring(1);

      if (!search) {
        return;
      }

      search = JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );

      if (search.passwordtoken) {
        this.token = search.passwordtoken;
        this.tab = 2;
      }
    }
  },

  mounted: function() {
    this.checkQueryParams();
  }
};
</script>

<style lang="scss">
.c-modal-login {
  .modal__content {
    > h4 {
      margin-bottom: var(--space-sm);
      font-size: var(--font-size-sm);
      letter-spacing: 0.5px;
    }
    > h1 {
      font-weight: 300;
      letter-spacing: 1px;
    }
  }

  &__tab {
    > img {
      max-width: 200px;
      margin-bottom: var(--space-md);
    }
  }

  .vfm--overlay {
    background-color: var(--primary) !important;
    background-image: url('~@/assets/bg_prod2.jpg');
    background-size: cover;
    background-position: center center;
    background-blend-mode: multiply;
    //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%238cc1ef' fill-opacity='1'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
  }

  &.staging {
    .vfm--overlay {
      background-image: url('~@/assets/bg_staging2.jpg');
    }
  }

  .vfm__content {
    box-shadow: 0px 3.6px 5.3px rgba(0, 0, 0, 0.121),
      0px 12.1px 17.9px rgba(0, 0, 0, 0.179), 0px 54px 80px rgba(0, 0, 0, 0.3);
  }

  .slide-enter-active {
    transition: all 250ms ease 250ms;
  }
  .slide-leave-active {
    transition: all 250ms cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-enter, .slide-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
}
</style>
