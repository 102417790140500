<template>
  <div class="c-item-enum" :style="style">
    {{ text }}
  </div>
</template>

<script>
import {
  period,
  status,
  stage,
  category,
  states,
  facilityTypeCodes,
  practiceSettingTypes,
  organisationType,
  billingMethod,
  orderStages
} from '@/lib/enums';

export default {
  data: function() {
    return {
      enums: {
        period,
        status,
        stage,
        category,
        states,
        facilityTypeCodes,
        practiceSettingTypes,
        organisationType,
        billingMethod,
        orderStages
      }
    };
  },

  props: {
    resource: {
      type: Object,
      default: function() {
        return {};
      }
    },
    column: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  computed: {
    date: function() {
      return this.resource[this.column.key];
    },

    enumlist: function() {
      if (!this.column.enum) {
        return this.resource[this.column.key];
      }

      if (!this.enums[this.column.enum]) {
        return this.resource[this.column.key];
      }

      let enumh = this.enums[this.column.enum];

      return enumh;
    },

    currentEnum: function() {
      let enumlist = this.enumlist;
      let val = this.resource[this.column.key];

      if (typeof enumlist === 'string') {
        return enumlist;
      }

      let currentEnum = null;

      if (!enumlist) {
        return '';
      }

      for (let i = 0; i < enumlist.length; i++) {
        if (val === enumlist[i].label) {
          currentEnum = enumlist[i];
          break;
        }
      }

      if (!currentEnum) {
        return '';
      }

      return currentEnum;
    },

    text: function() {
      if (this.currentEnum && typeof this.currentEnum === 'string') {
        return this.currentEnum;
      }
      if (this.currentEnum) {
        return this.currentEnum.label;
      }
      return this.resource[this.column.key];
    },

    style: function() {
      if (!this.currentEnum) {
        return '';
      }
      if (typeof this.currentEnum === 'string') {
        return '';
      }
      if (!this.currentEnum.color) {
        return '';
      }

      let str = `color:${this.currentEnum.color};`;

      return str;
    }
  }
};
</script>

<style lang="scss">
.c-item-enum {
  display: inline-flex;
  align-items: center;
  padding: var(--margin) var(--margin);

  font-weight: 500;
  font-size: var(--font-size-sm);
  line-height: 1;
  color: rgba(0, 0, 0, 0.75);

  border: var(--color-grey-light) solid 1px;
  border-radius: var(--border-radius-sm);
}
</style>
