<template>
  <div class="c-leads-create">
    <BreadCrumb></BreadCrumb>
    <h1>Update Order {{ resource.id }}</h1>
    <div v-if="resource.billingMethod === 'WINTHROP'">
      <FormUpdate :resource="resource"></FormUpdate>

      <Action :steps="computedSteps" :status="resource.status">
        <Button
          :processing="processing"
          @onClick="onClick(computedButtonAction)"
          v-if="computedButtonLabel"
        >
          {{ computedButtonLabel }}</Button
        >
      </Action>
      <Receipt :receipts="winthropReceipts"></Receipt>
    </div>
    <div v-else>
      <FormUpdatePublic :resource="resource"></FormUpdatePublic>
    </div>

    <article>
      <h3>
        Estimated Completion date
      </h3>
      <p>
        {{ resource.estimatedCompletionDate }}
      </p>
    </article>

    <article>
      <h3>
        Debug information(for developers)
      </h3>
      <p>
        A typical order generates heaps of metadata. This panel is for
        developers to debug an order if it can't be processed properly.
      </p>
    </article>

    <JsonRenderer :json="JSON.stringify(resource, null, 2)"></JsonRenderer>
  </div>
</template>

<script>
import Button from '@/components/global/button';
import BreadCrumb from '@/components/global/breadcrumb';
import FormUpdate from './form-update';
import FormUpdatePublic from './form-update-public';

import Action from '@/components/global/action';
import Receipt from '@/components/global/receipt';
import JsonRenderer from '@/components/global/json-renderer';

export default {
  components: {
    Button,
    BreadCrumb,
    FormUpdate,
    FormUpdatePublic,
    Action,
    Receipt,
    JsonRenderer
  },

  data: function() {
    return {
      processing: false
    };
  },

  computed: {
    winthropReceipts: function() {
      let id = this.resource.id;

      if (!id) {
        return [];
      }

      return this.$store.getters['winthropReceipts/byOrganisation'](id);
    },
    resource: function() {
      let id = this.$route.params.id;
      return this.$store.getters['orders/one'](id);
    },
    computedSteps: function() {
      if (!this.resource) {
        return [];
      }

      let statuses = this.resource.statuses;

      statuses.reverse();

      return statuses;
    },
    computedButtonLabel: function() {
      let status = this.resource.status;

      if (status === 'DRAFT') {
        return 'Send Pfr';
      }
      if (status === 'PFR_RECEIVED') {
        return 'Send Documents';
      }
      if (status === 'DOCUMENTS_RECEIVED') {
        return 'Create Winthrop Invoice';
      }
      if (status === 'WINTHROP_INVOICE_GENERATED') {
        return 'Setup Sandbox';
      }
      if (status === 'SETUP_SANDBOX') {
        return 'Create Winthrop Order';
      }
      if (status === 'COMPLETED') {
        return 'Completed';
      }
      return null;
    },
    computedButtonAction: function() {
      let status = this.resource.status;

      if (status === 'DRAFT') {
        return 'send-pfr';
      }
      if (status === 'PFR_RECEIVED') {
        return 'send-documents';
      }
      if (status === 'DOCUMENTS_RECEIVED') {
        return 'create-winthrop-invoice';
      }
      if (status === 'WINTHROP_INVOICE_GENERATED') {
        return 'setup-sandbox';
      }
      if (status === 'SETUP_SANDBOX') {
        return 'create-winthrop-order';
      }
      if (status === 'CREATE_WINTHROPE_ORDER') {
        return 'completed';
      }
      if (status === 'COMPLETED') {
        return 'completed';
      }
      return null;
    }
  },

  methods: {
    async onClick(action) {
      let id = this.resource.id;
      let obj = {
        id,
        action
      };
      this.processing = true;
      let response = await this.$store.dispatch('orders/doAction', obj);

      this.processing = false;

      if (response.id) {
        this.$store.dispatch('orders/update', response);
      }
    }
  }
};
</script>

<style lang="scss">
.c-rctis-create {
}
</style>
