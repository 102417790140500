<template>
  <div class="c-layout-no-sidebar">
    <div class="c-layout-no-sidebar__inner">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  components: {}
};
</script>

<style lang="scss">
.c-layout-no-sidebar {
  min-height: 100%;
  padding: var(--space-xl) var(--space-xl) var(--space-lg);
  overflow: auto;
  background-image: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%);

  &__inner {
    width: 1000px;
    margin: 0 auto;
    background-color: var(--color-white);
    border-radius: var(--border-radius-xl);
    box-shadow: 0px 0px 14px #00000029;
    flex-grow: 1;
    padding: var(--space-xl);
    position: relative;
  }

  .c-user {
    margin-top: calc(var(--space-xl) * -1);
  }

  @media screen and (max-width: 600px) {
    padding: var(--space-md);
  }
}
</style>
