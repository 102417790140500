<template>
  <form @submit.prevent="onSubmit">
    <Form
      ref="form"
      v-model="form"
      :schema="schema"
      :fields="fields"
      @status="saveStatus"
    ></Form>
    <InputButton class="btn btn-primary" type="submit">
      Add Person
    </InputButton>
  </form>
</template>

<script>
import Form from '@/components/global/form/explicit';

import schema from './schema.js';

import InputButton from '@/components/global/button';

import parseFormFields from '@/lib/parse-form-fields';

export default {
  components: {
    InputButton,

    Form
  },

  data: function() {
    return {
      status: null,

      schema: schema,

      form: {
        firstName: '',
        lastName: '',
        email: ''
      }
    };
  },

  computed: {
    fields: function() {
      return this.$store.getters['fields/all'];
    },
    query: function() {
      return this.$route.query || {};
    }
  },

  methods: {
    saveStatus(status) {
      this.status = status;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      let form = parseFormFields(this.form);

      let resource = await this.$store.dispatch('leads/add', form);

      if (resource) {
        this.$router.push('/people');
      }
    }
  },

  created: function() {
    let query = this.query;

    if (query.organisationId) {
      this.form.organisationId = parseInt(query.organisationId);
    }
  }
};
</script>

<style></style>
