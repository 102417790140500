import axios from 'axios';
import api from '@/lib/api.js';

let http = axios.create();

let context = null;

http.defaults.timeout = 16000;

http.interceptors.response.use(
  config => {
    setHeaderFromConfig(config);
    return config;
  },
  err => {
    parseError(err);

    // This is a big wtf. It should be Promise.reject but I'll need to work out something.
    return Promise.resolve(err);
  }
);

function setHeaderFromConfig(config) {
  if (config && config.headers && config.headers['x-token']) {
    http.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${config.headers['x-token']}`;
    api.users.saveToken(config.headers['x-token']);
  }
}

function parseError(err) {
  console.log(err);
  if (err.response) {
    serverError(err);
  } else if (err.request) {
    clientError(err);
  } else {
    genericError(err);
  }
}

function clientError(err) {
  context.dispatch('message/onClientError', err, { root: true });
}

function serverError(err) {
  context.dispatch('message/onServerError', err, { root: true });
}

function genericError(err) {
  context.dispatch('message/onGenericError', err, { root: true });
}

function setHeader(key, value) {
  var type = 'common';
  http.defaults.headers[type][key] = value;
}

function setUrl(url) {
  http.defaults.baseURL = url;
}

function setContext(ctx) {
  context = ctx;
}

export default {
  setContext: setContext,
  setUrl: setUrl,
  setHeader: setHeader
};

export { http };
