import http from '@/lib/http.js';
import objectId from '@/lib/objectid.js';
import api from '@/lib/api.js';

var store = {
  namespaced: true,
  state: {
    env: process.env.NODE_ENV || 'development',
    baseUrl: process.env.VUE_APP_BASE_URL || 'http://localhost:4000',
    mode: 'LOCAL',
    browser: 'Other',
    sessionId: '',
    init: false,
    loaded: false,
    firstRun: false
  },
  getters: {
    env: function(state) {
      return state.env;
    },
    init: function(state) {
      return state.init;
    }
  },
  mutations: {
    SET_LOADED: loaded => {
      state.loaded = loaded;
    },
    SET_INIT: function(state) {
      state.init = true;
    }
  },
  actions: {
    async init(store) {
      await api.init(store.state.mode);

      await store.dispatch('getSessionId');

      http.setContext(store);
      http.setUrl(store.state.baseUrl);

      let token = await api.users.getToken();

      if (token) {
        http.setHeader('Authorization', `Bearer ${token}`);
      }
    },

    set: function(store, payload) {
      if (payload.key && payload.value) {
        store.state[payload.key] = payload.value;
      }
    },

    async setLoaded() {},

    async getSessionId(store) {
      let sessionId = await api.app.getSessionId();
      if (!sessionId) {
        sessionId = objectId();
      }
      await api.app.setSessionId(sessionId);
      store.state.sessionId = sessionId;
    },

    async setInit(store) {
      store.commit('SET_INIT');
    }
  }
};

export default store;
