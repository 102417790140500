let store = {
  namespaced: true,
  state: {
    events: {
      serverError: {
        message: 'Something went server side'
      },
      clientError: {
        message: "Couldn't handle your request"
      },
      genericError: {
        message: 'Something went wrong'
      }
    },
    event: null,
    eventTrigger: false
  },
  getters: {
    event: function(state) {
      return state.event;
    },
    eventTrigger: function(state) {
      return state.eventTrigger;
    }
  },
  mutations: {
    SET_EVENT: function(state, event) {
      state.event = event;
    },
    TRIGGER: function(state) {
      state.eventTrigger = !state.eventTrigger;
    }
  },
  actions: {
    async init(store) {},

    async sendMessage(store, name) {
      // Suppress errors until init
      if (!store.rootState.init) {
        return;
      }
      let event = store.state.events[name];
      store.commit('SET_EVENT', event);
      store.commit('TRIGGER');
    },

    // 400, 401, etc
    async onServerError(store, err) {
      store.dispatch('sendMessage', 'serverError');
    },

    // timeout, etc
    async onClientError(store, err) {
      store.dispatch('sendMessage', 'clientError');
    },

    // When yolo
    async onGenericError(store, err) {
      store.dispatch('sendMessage', 'genericError');
    }
  }
};

export default store;
