<template>
  <div class="c-notes-compact">
    <div class="c-notes-compact__header">{{ notes.length }} Notes</div>
    <div class="c-notes-compact__scrollable">
      <div
        class="c-notes-compact__item"
        v-for="resource in computedNotes"
        :key="resource.id"
      >
        <div class="c-notes-compact__item__header">
          <Avatar
            :title="
              `${resource.user.firstName} ${resource.user.lastName} | ${resource.user.email}`
            "
            :username="`${resource.user.firstName} ${resource.user.lastName}`"
            :size="24"
          ></Avatar>
          <span>
            {{ resource.user.firstName }}
          </span>
          <span
            class="divider"
            v-if="
              resource.assignedTo && resource.assignedTo.id !== resource.user.id
            "
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 12H19M19 12L13 6M19 12L13 18"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <Avatar
            v-if="
              resource.assignedTo && resource.assignedTo.id !== resource.user.id
            "
            :title="
              `${resource.assignedTo.firstName} ${resource.assignedTo.lastName} | ${resource.assignedTo.email}`
            "
            :username="
              `${resource.assignedTo.firstName} ${resource.assignedTo.lastName}`
            "
            :size="24"
          ></Avatar>
          <span
            v-if="
              resource.assignedTo && resource.assignedTo.id !== resource.user.id
            "
          >
            {{ resource.assignedTo.firstName }}
          </span>
        </div>

        <div class="c-notes-compact__item__body">
          <article v-html="resource.html"></article>
          <div>
            <span
              ><DateComponent
                dateFormat="EEE do, MMM y"
                :date="resource.createdAt"
              ></DateComponent
            ></span>
            <span v-if="resource.followupDate"
              ><strong>Follow up on: </strong
              ><DateComponent
                dateFormat="EEE do, MMM y"
                :date="resource.followupDate"
              ></DateComponent
            ></span>
          </div>
          <NotesCompactLabel
            v-if="resource.status"
            v-model="resource.status"
            :editable="false"
          ></NotesCompactLabel>
        </div>
      </div>
    </div>
    <div v-if="notes.length === 0" class="c-notes-compact__empty">
      <h3>
        No notes here.
      </h3>
    </div>
    <div class="c-notes-compact__add">
      <NotesCompactAdd :resource="resource"></NotesCompactAdd>
    </div>
  </div>
</template>

<script>
import Avatar from 'vue-avatar';

import DateComponent from '@/components/global/date';

import NotesCompactAdd from './compact-add';

import NotesCompactLabel from './compact-label';

export default {
  components: {
    Avatar,
    DateComponent,
    NotesCompactAdd,
    NotesCompactLabel
  },

  computed: {
    viewId: function() {
      return this.$store.getters['notes/viewId'];
    },
    resource: function() {
      return this.$store.getters['leads/one'](this.viewId);
    },
    notes: function() {
      if (!this.resource) {
        return [];
      }
      return this.$store.getters['notes/byLead'](this.resource.id);
    },
    computedNotes: function() {
      let notes = this.notes;

      // Always show newly created notes first
      notes.sort(function(a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      return notes;
    }
  }
};
</script>

<style lang="scss">
.c-notes-compact {
  border-radius: var(--border-radius-lg);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  &__header {
    padding: var(--space-xs);
    font-size: var(--font-size-xs);
    text-align: center;
  }

  &__scrollable {
    max-height: 280px;
    overflow-y: auto;
  }

  &__item {
    position: relative;
    padding: var(--space-sm);
    // display: grid;
    // grid-template-columns: 24px 1fr;

    &__header {
      display: flex;
      align-items: center;

      > span {
        font-weight: 500;
      }

      .vue-avatar--wrapper {
        margin-right: var(--space-xs);
        min-width: 24px;
      }

      .divider {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 0 var(--space-xs);
      }
    }

    &__body {
      margin-top: var(--space-xs);
      line-height: 1.3;

      > div {
        display: block;
        margin-bottom: var(--space-xs);

        span {
          display: block;

          div {
            display: inline;
          }
        }
      }

      > div {
        > span {
          font-size: var(--font-size-sm);
          opacity: 0.6;
        }
        strong {
          font-weight: 500;
        }
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: var(--space-sm);
      width: calc(100% - var(--space-sm) * 2);
      height: 1px;
      background-color: var(--color-black);
      opacity: 0.15;
    }

    // &:first-of-type {
    //   &:after {
    //     display: none;
    //   }
    // }
  }

  &__empty {
    padding: var(--space-xs);
    text-align: center;
    opacity: 0.85;
  }

  &__add {
    border-top: #ededed solid 1px;
  }
}
</style>
