<template>
  <div class="c-list-filters">
    <div class="c-list-filters__header">
      <slot></slot>
    </div>
    <div class="c-list-filters__inner">
      <InputText v-model="query" placeholder="Search"></InputText>
      <div class="c-list-filters__right">
        <slot name="buttons"></slot>
      </div>
      <!-- <div class="col-9 c-list-filters__dropdowns" v-if="filter">
          <InputDropdown
            label="Filter by"
            inline
            :options="filterOptions"
            v-model="currentFilter"
          ></InputDropdown>
        </div> -->
    </div>
  </div>
</template>

<script>
import InputText from '@/components/global/forms/input-text';
import InputDropdown from '@/components/global/forms/input-dropdown';

export default {
  components: {
    InputText,
    InputDropdown
  },

  data: function() {
    return {
      query: '',
      currentFilter: ''
    };
  },

  watch: {
    query: function() {
      this.$emit('onQuery', this.query);
    },
    currentFilter: function() {
      if (this.currentFilter) {
        this.$emit('input', this.currentFilter);
      } else {
        this.$emit('input', '');
      }
    }
  },

  props: {
    options: {
      type: Object
    },
    value: {},
    filter: {
      default: null
    }
  },

  computed: {
    filterOptions: function() {
      if (this.options && this.options.filter && this.options.filter.options) {
        let arr = this.options.filter.options;
        return arr;
      }
      return [];
    }
  }
};
</script>

<style lang="scss">
.c-list-filters {
  .form-group {
    margin-top: 0;
  }

  .form__label::after {
    content: ':';
  }

  &__dropdowns {
    display: flex;
    justify-content: flex-end;
  }

  .v-select {
    min-width: 10rem;
  }

  &__inner {
    display: flex;
    min-height: 56px;

    .c-input-text {
      height: 100%;
      max-width: 33%;
      flex-grow: 1;
    }
    .form-control {
      border-radius: 0;
    }
  }

  &__right {
    height: 100%;
    max-width: 66%;
    flex-grow: 1;
    padding: 0 var(--space-sm);
  }
}
</style>
