<template>
  <div class="form-group c-input-datepicker">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <div class="c-input-datepicker__inner">
      <vc-date-picker
        v-model="range"
        mode="date"
        :modelConfig="modelConfig"
        is-range
      >
        <template v-slot="{ inputValue, inputEvents, isDragging }">
          <div class="c-input-datepicker__datepickers">
            <div class="form-group-wrapper">
              <input
                class="form-control"
                :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                :value="inputValue.start"
                v-on="inputEvents.start"
              />
            </div>
            <div class="form-group-wrapper">
              <input
                class="form-control"
                :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                :value="inputValue.end"
                v-on="inputEvents.end"
              />
            </div>
          </div>
          <div class="c-input-datepicker__timeline">
            <span>
              {{ differenceInDays }} day<template v-if="differenceInDays !== 1"
                >s</template
              >
            </span>
          </div>
        </template>
      </vc-date-picker>
    </div>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
  </div>
</template>

<script>
import InputError from './input-error';
import InputDescription from './input-description';
import slugify from '@/lib/slugify';

import { parseISO, differenceInDays, parse } from 'date-fns';

export default {
  components: {
    InputDescription,
    InputError
  },

  data: function() {
    return {
      range: {
        start: `2022-01-07T13:00:00.000Z`,
        end: `2022-01-15T13:00:00.000Z`
      }
    };
  },

  watch: {
    range: function() {
      this.onChange();
    }
  },

  props: {
    label: {
      type: String,
      default: ''
    },
    value: {},
    placeholder: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: null
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },

  computed: {
    modelConfig: function() {
      return {
        type: 'string',
        mask: 'iso'
      };
    },
    differenceInDays: function() {
      let start = parseISO(this.range.start);
      let end = parseISO(this.range.end);

      let diff = differenceInDays(end, start);

      return diff;
    },
    slug: function() {
      return slugify(this.label);
    },
    attributes: function() {}
  },

  methods: {
    onChange: function() {
      let value = this.range;

      this.$emit('input', value);
    }
  },

  created: function() {
    if (this.value.start) {
      this.range.start = this.value.start;
    }
    if (this.value.end) {
      this.range.end = this.value.end;
    }
  }
};
</script>

<style lang="scss">
.c-input-datepicker {
  &__inner {
    position: relative;
    padding-bottom: var(--space-lg);
  }

  &__datepickers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--space-md);
  }

  &__timeline {
    position: absolute;
    bottom: 12px;
    left: 0;
    width: 100%;
    height: 10px;
    //background-color: green;
    border-left: var(--gray) solid 2px;
    border-right: var(--gray) solid 2px;
    border-bottom: var(--gray) solid 2px;

    border-bottom-left-radius: var(--border-radius-md);
    border-bottom-right-radius: var(--border-radius-md);

    text-align: center;

    span {
      display: inline-block;
      padding: var(--margin) var(--margin-lg);
      background-color: var(--color-white);
      font-size: var(--font-size-xs);
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.5px;

      transform: translateY(-5px);
    }
  }
}
</style>
