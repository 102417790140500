<template>
  <form @submit.prevent="onSubmit">
    <Form
      ref="form"
      v-model="form"
      :schema="schema"
      :fields="fields"
      @status="saveStatus"
    ></Form>
    <InputButton class="btn btn-primary" type="submit">
      Update Person
    </InputButton>
  </form>
</template>

<script>
import Form from '@/components/global/form';

import schema from './schema.js';

import InputButton from '@/components/global/button';

import parseFormFields from '@/lib/parse-form-fields';

export default {
  components: {
    InputButton,

    Form
  },

  data: function() {
    return {
      status: null,

      schema: schema,

      form: {
        firstName: '',
        lastName: '',
        email: ''
      }
    };
  },

  watch: {
    resource: function() {
      let form = this.destructureCustomFields(this.resource);
      this.form = form;
    }
  },

  props: {
    resource: {
      type: Object,
      default: function() {
        return null;
      }
    }
  },

  computed: {
    fields: function() {
      return this.$store.getters['fields/all'];
    }
  },

  methods: {
    saveStatus(status) {
      this.status = status;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      let form = parseFormFields(this.form);

      console.log(form);
      console.log('---');

      let lead = await this.$store.dispatch('leads/update', form);

      if (lead) {
        this.$router.push('/people');
      }
    },

    destructureCustomFields: function(form) {
      let fields = {};
      let newForm = {};

      if (!form) {
        return {};
      }

      if (form.fields && form.fields.length > 0) {
        for (let i = 0; i < form.fields.length; i++) {
          let slug = `form_${form.fields[i].slug}`;
          fields[slug] = form.fields[i].value;
        }

        for (let key in fields) {
          newForm[key] = fields[key];
        }
      }

      for (let key in form) {
        if (key !== 'notes' && key !== 'organisation' && key !== 'fields') {
          newForm[key] = form[key];
        }
      }

      return newForm;
    }
  },

  created: function() {
    let form = this.destructureCustomFields(this.resource);

    this.form = form;
  }
};
</script>

<style></style>
