<template>
  <div class="form-group c-input-locations">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <v-select
      :disabled="readonly === true"
      :value="selectValue"
      :options="computedOptions"
      label="label"
      @input="onChange"
      :multiple="false"
    ></v-select>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
  </div>
</template>

<script>
import InputError from './input-error';
import slugify from '@/lib/slugify';

import InputText from './input-text';
import InputEnum from './input-enum';
import InputButton from '@/components/global/button';

export default {
  components: {
    InputError,
    InputText,
    InputEnum,
    InputButton
  },

  data: function() {
    return {
      selectValue: null
    };
  },

  watch: {
    value: {
      handler: function() {
        // !this.selectValue so it stops re-renders
        if (this.value && !this.selectValue) {
          let organisation = this.$store.getters['organisations/one'](
            this.value.organisationId
          );
          let name = "";
          if(this.value.name) {
            name = this.value.name + " ";
          }
          let obj = {
            label: `${organisation.name} - ${name}${this.value.streetAddress}`,
            resource: this.value,
            value: this.value.id
          };
          this.selectValue = obj;
        }
      },
      immediate: true
    }
  },

  props: {
    label: {
      type: String,
      default: ''
    },
    value: {},
    placeholder: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: null
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    locations: function() {
      return this.$store.getters['locations/filtered'];
    },
    computedOptions: function() {
      let locations = this.locations;

      let options = [];

      for (let i = 0; i < locations.length; i++) {
        let organisation = this.$store.getters['organisations/one'](
          locations[i].organisationId
        );
        let name = "";
        if(locations[i].name) {
          name = locations[i].name + ' ';
        }
        let obj = {
          label: `${organisation.name} - ${name}${locations[i].streetAddress}`,
          resource: locations[i],
          value: locations[i].id
        };
        options.push(obj);
      }

      return options;
    },
    columns: function() {
      return [
        {
          label: 'Street Address',
          key: 'streetAddress'
        },
        {
          label: 'Post code',
          key: 'postalCode'
        },
        {
          label: 'Action',
          key: '_action'
        }
      ];
    },
    slug: function() {
      return slugify(this.label);
    }
  },

  methods: {
    onChange: function(obj) {
      let value = obj.resource.id;
      this.selectValue = obj;

      this.$emit('input', obj.resource);
    }
  }
};
</script>

<style lang="scss">
// .c-input-locations {
//   margin: var(--space-md) 0;
//   padding: var(--space-md);

//   &__header {
//     margin-bottom: var(--space-md);
//   }
// }
.c-input-locations {
}
</style>
