<template>
  <div class="c-organisation-stats">
    <Stats :sections="sections"></Stats>
  </div>
</template>

<script>
import generateSection from '@/lib/generate-section';

import Stats from '@/components/global/stats';

export default {
  components: {
    Stats
  },

  props: {
    resource: {}
  },

  computed: {
    sections: function() {
      let resource = this.resource;

      let section1 = generateSection(resource, 'Details', [
        'locations',
        'fields'
      ]);

      let fields = [];

      for (let i = 0; i < resource.fields.length; i++) {
        let value = resource.fields[i]['value'];
        if (value === 'null') {
          value = '';
        }
        fields.push({
          key: resource.fields[i]['label'],
          value: value,
          type: resource.fields[i]['type'].toLowerCase()
        });
      }

      let section2 = {
        title: 'Custom fields',
        fields
      };

      return [section1, section2];
    }
  }
};
</script>

<style lang="scss">
.c-stats {
  --gap: 5px;

  &__section {
    padding: var(--gap) 0;
    border-top: var(--color-bg-3) solid 1px;
    margin-bottom: calc(var(--gap) * 4);

    &__field {
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-bottom: var(--color-bg-3) solid 1px;

      &:first-of-type {
        border-top: var(--color-bg-3) solid 1px;
      }

      &:hover {
        .c-stats__value,
        .c-stats__key {
          background-color: var(--color-bg-3);
        }
      }
    }
  }

  &__key {
    padding: var(--gap) 0;
    padding-left: var(--gap);
    background-color: var(--color-bg-2);
    border-right: var(--color-bg-3) solid 1px;

    span {
      opacity: 0.65;
    }
  }

  &__value {
    padding: var(--gap);

    &.object {
      span {
        font-size: var(--font-size-xs);
        font-family: var(--font-family-monospace);
        line-height: 1;
      }
    }
  }
}
</style>
