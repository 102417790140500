function parseFormFields(form) {
  let newForm = {};
  let customFields = [];
  for (let key in form) {
    if (key.includes('form_')) {
      let slug = key.replace('form_', '');
      customFields.push({
        slug: slug,
        value: form[key]
      });
    } else {
      newForm[key] = form[key];
    }
  }

  if (customFields.length > 0) {
    newForm.fields = customFields;
  }

  return JSON.parse(JSON.stringify(newForm));
}

export default parseFormFields;
