<template>
  <div class="c-organisations">
    <Dashboard>
      <DashboardHeader title="Manage Organisations">
        <router-link
          v-if="!isReadOnly"
          to="/organisations/create"
          class="btn btn-primary"
          >Add New</router-link
        >
      </DashboardHeader>
      <DashboardBody>
        <List
          :options="listOptions"
          :resources="organisations"
          @click="onClick"
          :columns="columns"
        ></List>
      </DashboardBody>
    </Dashboard>
  </div>
</template>

<script>
import Dashboard from '@/components/dashboard';
import DashboardHeader from '@/components/dashboard/header';
import DashboardBody from '@/components/dashboard/body';

import Button from '@/components/global/button';

import List from '@/components/list';

export default {
  components: {
    Dashboard,
    DashboardHeader,
    DashboardBody,

    Button,

    List
  },

  computed: {
    isReadOnly: function() {
      return this.$store.getters['users/isReadOnly'];
    },
    listOptions: function() {
      return {
        fullContainerWidth: true,
        pagination: 100,
        search: ['name', 'category'],
        filter: null
      };
    },
    organisations: function() {
      return this.$store.getters['organisations/filtered'];
    },
    columns: function() {
      return [
        {
          label: 'Assigned to',
          key: 'userId',
          renderer: 'ItemUser'
        },
        {
          label: 'Organisation name',
          key: 'name',
          sortable: true,
          sortType: 'string',
          clickable: true
        },
        {
          label: 'Category',
          key: 'category',
          sortable: true,
          sortType: 'string',
          renderer: 'ItemEnum',
          enum: 'status'
        },
        {
          label: 'Type',
          key: 'type',
          sortable: true,
          sortType: 'string',
          renderer: 'ItemEnum',
          enum: 'category'
        },
        {
          label: 'Landline',
          key: 'landline'
        },
        {
          label: 'Locations',
          key: 'locations',
          renderer: 'ItemBadge',
          formatter: (resource, column) => {
            if (!resource.locations) {
              return 0 + '';
            }
            return resource.locations.length + '';
          }
        },
        {
          label: 'Leads',
          key: 'leads',
          renderer: 'ItemBadge',
          formatter: (resource, column) => {
            let leads = this.$store.getters['leads/filtered'];

            let count = 0;

            for (let i = 0; i < leads.length; i++) {
              if (leads[i].organisationId === resource.id) {
                count++;
              }
            }
            return count + '';
          }
        },
        {
          label: 'Created at',
          key: 'createdAt',
          renderer: 'ItemDate',
          sortable: true,
          sortType: 'date'
        },
        {
          label: 'Action',
          key: '_action',
          clickable: false,
          actions: ['V', 'E']
        }
      ];
    }
  },

  methods: {
    onClick: function(payload) {
      if (payload.action && payload.action === 'view') {
        this.$router.push({
          name: 'OrganisationsDrilldown',
          params: { id: payload.id }
        });

        // this.$store.dispatch('app/setInfoWindow', {
        //   type: 'organisation',
        //   id: payload.id
        // });
      }

      if (payload.action && payload.action === 'edit') {
        this.$router.push({
          name: 'OrganisationsUpdate',
          params: { id: payload.id }
        });
      }

      if (payload.action && payload.action === 'delete') {
        this.$store.dispatch('organisations/delete', payload.id);
      }
    }
  }
};
</script>

<style></style>
