<template>
  <Popup :active="!!el" :target="el" @onClose="closePopup">
    <NotesCompact> </NotesCompact>
  </Popup>
</template>

<script>
import Popup from '@/components/global/popup';
import NotesCompact from './compact';

export default {
  components: {
    Popup,
    NotesCompact
  },

  computed: {
    el: function() {
      let viewId = this.viewId;
      if (!viewId) {
        return null;
      }
      return `#note-org-${viewId}`;
    },
    viewId: function() {
      return this.$store.getters['notes/viewId'];
    }
  },

  methods: {
    closePopup: function() {
      this.$store.dispatch('notes/setView', null);
    }
  }
};
</script>

<style lang="scss">
.c-notes-popup {
}
</style>
