<template>
  <div class="c-notes-compact-add">
    <div>
      <Editor placeholder="Enter note text" v-model="html"></Editor>
      <!-- <NoteCompactLabel v-model="status"></NoteCompactLabel> -->
      <InputNoteStatus v-model="status"></InputNoteStatus>
      <InputUsers v-model="assignedToUserId"></InputUsers>
      <div class="c-notes-bottom">
        <div class="c-notes-compact-add__datepicker">
          <vc-date-picker v-model="followupDate">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="form-control"
                :value="inputValue"
                v-on="inputEvents"
                placeholder="DD/MM/YYYY"
              />
            </template>
          </vc-date-picker>
        </div>

        <button class="btn btn-primary btn-sm" @click="addNote">
          Add Note
        </button>
      </div>
      <article class="c-input-error" v-if="hasError">
        Please set a follow up date and a assigned to user.
      </article>
    </div>
  </div>
</template>

<script>
import Editor from '@/components/global/editor';

import NoteCompactLabel from './compact-label';

import InputNoteStatus from '@/components/global/forms/input-note-status';

import InputUsers from '@/components/global/forms/input-users';

export default {
  components: {
    Editor,
    NoteCompactLabel,
    InputUsers,
    InputNoteStatus
  },

  data: function() {
    return {
      html: '',
      status: 'OPENING_CALL_NO_ANSWER',
      followupDate: '',
      assignedToUserId: null
    };
  },

  props: {
    // The org this note will be attached to
    resource: {
      type: Object,
      default: null
    }
  },

  computed: {
    hasError: function() {
      if (!this.followupDate || !this.status || !this.assignedToUserId) {
        return true;
      }
      return false;
    }
  },

  methods: {
    async addNote() {
      let note = {
        html: this.html,
        status: this.status,
        leadId: this.resource.id,
        assignedToUserId: this.assignedToUserId
      };

      if (this.followupDate) {
        note.followupDate = this.followupDate.toISOString();
      }

      note = await this.$store.dispatch('notes/add', note);

      this.reset();

      return note;
    },

    reset: function() {
      this.html = '';
      this.status = '';
      this.followupDate = '';
      this.assignedToUserId = this.$store.getters['users/user'].id || null;
    }
  },

  created: function() {
    this.followupDate = new Date().toISOString();
    this.assignedToUserId = this.$store.getters['users/user'].id || null;
  }
};
</script>

<style lang="scss">
.c-notes-compact-add {
  padding: var(--space-sm);
  background-color: rgba(0, 0, 0, 0.02);

  .c-editor {
    appearance: none;
    display: inline-block;
    font-size: var(--font-size-md);
    line-height: 1.5;
    margin-top: var(--space-xs);
    // padding: var(--space-xs) var(--space-sm);
    transition: all 50ms ease-out;
    width: 100%;

    p {
      font-size: inherit;
      line-height: inherit;
    }

    &:active,
    &:focus {
      border-color: transparent;
    }

    &:focus {
      outline: none;
    }
  }

  &__datepicker {
    flex-grow: 1;
    margin-right: var(--space-xs);

    .form-control {
      margin-top: 0;
    }
  }

  .c-input-users {
    margin-top: 0;
  }

  .c-input-note-status {
    margin-top: 0;
  }

  .v-select {
    .vs__selected {
      font-size: var(--font-size-sm);
      em {
        display: none;
      }
    }
  }
}

.c-notes-bottom {
  display: flex;
  margin-top: var(--space-xs);
}
</style>
