const schema = [
  {
    type: 'meta',
    title: 'Diginostic EOI form',
    content: `
    <p>
    iCOPE
    </p>
    <p>
    Expression of Interest
    </p>
    `
  },
  {
    type: 'submit',
    content: `
      <h1>
      Thank you for your time</h1>
      <p>
      You'll receive a copy of this form in your email.
      </p>
      `
  },
  {
    type: 'accordion',
    label: 'Contact details',
    fields: {
      firstName: {
        component: 'InputText',
        label: 'Your first name',
        validations: {
          required: {
            params: null,
            message: 'This field is required'
          }
        }
      },
      lastName: {
        component: 'InputText',
        label: 'Your last name',
        validations: {
          required: {
            params: null,
            message: 'This field is required'
          }
        }
      },
      emailAddress: {
        component: 'InputEmail',
        label: 'Your email address',
        validations: {
          required: {
            params: null,
            message: 'This field is required'
          }
        }
      },
      natureOfService: {
        component: 'InputSelectOther',
        label: 'Nature of service',
        options: [
          {
            label: 'Private Obstetric Clinic'
          },
          {
            label: 'General Practitioner'
          },
          {
            label: 'Public Maternity Hospital'
          },
          {
            label: 'Maternal Child and Family Health Centre'
          },
          {
            label: 'IVF - Private Provider'
          },
          {
            label: 'IVF - Public Provider'
          },
          {
            label: 'Parenting Centre'
          },
          {
            label: 'Allied Health Provider (e.g. Psychologist'
          },
          {
            label: 'Other'
          }
        ],
        validations: {
          required: {
            params: null,
            message: 'This field is required'
          }
        }
      },
      organisationName: {
        component: 'InputText',
        label: 'Name of your organisation',
        validations: {
          required: {
            params: null,
            message: 'This field is required'
          }
        }
      },
      practiceManagerName: {
        component: 'InputText',
        label: 'Practice Manager name',
        validations: {
          required: {
            params: null,
            message: 'This field is required'
          }
        }
      }
    }
  }
];

export default schema;
