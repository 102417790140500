<template>
  <div class="c-resources">
    <Dashboard>
      <h1>
        {{ pageTitle }}
      </h1>
      <article class="article-text">
        <p>
          Here's your activity for today.
        </p>
      </article>
      <DashboardBody>
        <List
          :options="listOptions"
          :resources="resources"
          @click="onClick"
          :columns="columns"
        >
          <template v-slot:buttons>
            <InputUsers v-model="form.userId"></InputUsers>
            <InputDatepicker v-model="form.range"></InputDatepicker>
          </template>
        </List>
      </DashboardBody>
    </Dashboard>
  </div>
</template>

<script>
import Dashboard from '@/components/dashboard';
import DashboardHeader from '@/components/dashboard/header';
import DashboardBody from '@/components/dashboard/body';

import Button from '@/components/global/button';

import List from '@/components/list';

import InputDatepicker from '@/components/global/forms/input-datepicker';

import InputUsers from '@/components/global/forms/input-users';

export default {
  components: {
    Dashboard,
    DashboardHeader,
    DashboardBody,

    Button,

    List,

    InputDatepicker,

    InputUsers
  },

  data: function() {
    return {
      form: {
        range: {
          start: new Date().toISOString(),
          end: new Date().toISOString()
        },
        userId: 1
      }
    };
  },

  computed: {
    user: function() {
      return this.$store.getters['users/user'];
    },
    pageTitle: function() {
      let user = this.$store.getters['users/user'];

      let str = `Welcome ${user.firstName} ${user.lastName}`;

      return str;
    },
    isReadOnly: function() {
      return this.$store.getters['users/isReadOnly'];
    },
    listOptions: function() {
      return {
        fullContainerWidth: true,
        pagination: 100,
        search: ['status'],
        filter: null
      };
    },
    resources: function() {
      let resources = this.$store.getters['notes/filtered'];

      let userId = this.form.userId;

      let range = this.form.range;

      resources = this.filterByUserId(resources, userId);

      resources = this.filterByDate(resources, range.start, range.end);

      return resources;
    },
    columns: function() {
      return [
        {
          label: 'Assigned to',
          key: 'userId',
          renderer: 'ItemUser'
        },
        {
          label: 'Folloup date',
          key: 'followupDate',
          renderer: 'ItemDate',
          sortable: true,
          sortType: 'date'
        },
        {
          label: 'Note',
          key: 'html',
          sortable: true,
          sortType: 'string',
          formatter: function(resource, column) {
            let cleanText = resource[column.key].replace(/<\/?[^>]+(>|$)/g, '');
            let limit = 25;
            if (cleanText.length > limit) {
              return cleanText.substring(0, limit) + '...';
            }
            return cleanText;
          },
          enum: 'status'
        },
        {
          label: 'Organisation name',
          clickable: 'true',
          key: 'organisationId',
          formatter: (resource, column) => {
            let lead = this.$store.getters['leads/one'](resource.leadId);
            if (!lead) {
              return '';
            }
            let org = this.$store.getters['organisations/one'](
              lead.organisationId
            );
            if (!org) {
              return '';
            }
            return org.name;
          }
        },
        {
          label: 'Contact name',
          formatter: (resource, column) => {
            let lead = this.$store.getters['leads/one'](resource.leadId);
            if (!lead) {
              return '';
            }
            return `${lead.firstName} ${lead.lastName}`;
          }
        },
        {
          label: 'Contact email',
          formatter: (resource, column) => {
            let lead = this.$store.getters['leads/one'](resource.leadId);
            if (!lead) {
              return '';
            }
            return `${lead.email}`;
          }
        },
        {
          label: 'Contact mobile',
          formatter: (resource, column) => {
            let lead = this.$store.getters['leads/one'](resource.leadId);
            if (!lead) {
              return '';
            }
            return `${lead.mobile}`;
          }
        },
        {
          label: 'Contact landline',
          formatter: (resource, column) => {
            let lead = this.$store.getters['leads/one'](resource.leadId);
            if (!lead) {
              return '';
            }
            return `${lead.landline}`;
          }
        },
        {
          label: 'Status',
          key: 'status',
          sortable: true,
          sortType: 'string',
          renderer: 'ItemEnum',
          enum: 'metrics'
        },
        {
          label: 'Created at',
          key: 'createdAt',
          renderer: 'ItemDate',
          sortable: true,
          sortType: 'date'
        }
      ];
    }
  },

  methods: {
    filterByUserId: function(resources, userId) {
      resources = resources.filter(resource => {
        if (resource.userId === userId) {
          return true;
        }
        return false;
      });

      return resources;
    },
    filterByDate: function(resources, start, end) {
      start = new Date(start);
      end = new Date(end);
      resources = resources.filter(resource => {
        let followupDate = new Date(resource.followupDate);

        if (followupDate > start && followupDate < end) {
          return true;
        }
        return false;
      });

      return resources;
    },
    onClick: function(payload) {
      if (
        payload.action &&
        payload.action === 'view' &&
        payload.key === 'organisationId'
      ) {
        let note = this.$store.getters['notes/one'](payload.id);
        let lead = this.$store.getters['leads/one'](note.leadId);
        let org = this.$store.getters['organisations/one'](lead.organisationId);
        this.$router.push({
          name: 'OrganisationsDrilldown',
          params: { id: org.id }
        });
        return;
      }

      if (payload.action && payload.action === 'view') {
        this.$router.push({
          name: 'resourcesDrilldown',
          params: { id: payload.id }
        });

        return;
      }

      if (payload.action && payload.action === 'edit') {
        this.$router.push({
          name: 'resourcesUpdate',
          params: { id: payload.id }
        });
        return;
      }

      if (payload.action && payload.action === 'delete') {
        this.$store.dispatch('resources/delete', payload.id);
        return;
      }
    }
  },

  created: function() {
    this.form.userId = this.user.id;

    var d = new Date();
    d.setDate(d.getDate() - 1);

    d = d.toISOString();

    this.form.range.start = d;
  }
};
</script>

<style></style>
