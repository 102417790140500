<template>
  <div
    :class="['c-item-note', { active: notes.length > 0 }]"
    @click="onClick"
    :id="`note-org-${resource.id}`"
  >
    <span class="c-item-note__label">
      {{ notes.length }} {{ noteText }}
      <plus-icon size="1x"></plus-icon>
    </span>
  </div>
</template>

<script>
import { PlusIcon } from 'vue-feather-icons';
export default {
  components: {
    PlusIcon
  },

  props: {
    resource: {
      type: Object,
      default: function() {
        return {};
      }
    },
    column: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  computed: {
    notes: function() {
      return this.$store.getters['notes/byLead'](this.resource.id);
    },
    noteText: function() {
      if (this.notes.length === 1) {
        return 'note';
      }
      return 'notes';
    },
    viewId: function() {
      return this.$store.getters['notes/viewId'];
    }
  },

  methods: {
    onClick: function() {
      if (!this.viewId) {
        this.$store.dispatch('notes/setView', this.resource.id);
      } else {
        this.$store.dispatch('notes/setView', null);
      }
    }
  }
};
</script>

<style lang="scss">
.c-item-note {
  display: inline-block;

  &__label {
    display: inline-flex;
    align-items: center;
    padding: var(--margin) var(--space-xs);

    font-weight: 500;
    font-size: var(--font-size-sm);
    line-height: 1;
    color: rgba(0, 0, 0, 0.5);

    border: var(--color-grey-light) solid 1px;
    border-radius: var(--border-radius-sm);

    user-select: none;

    cursor: pointer;

    svg {
      margin-left: var(--margin);
    }

    &:hover {
      opacity: 0.9;
    }
  }

  &.active {
    .c-item-note__label {
      color: var(--primary);
      border-color: var(--primary);
    }
  }
}
</style>
