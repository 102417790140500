<template>
  <vue-final-modal
    v-slot="{ params, close }"
    :clickToClose="closable"
    :escToClose="closable"
    v-model="isActive"
    classes="modal-container"
    content-class="modal-content"
    @closed="onClose"
  >
    <span class="modal__title">
      <slot name="title"></slot>
    </span>
    <div class="modal__content">
      <slot v-bind:params="params"></slot>
    </div>
    <XIcon v-if="closable" @click="close"></XIcon>
  </vue-final-modal>
</template>

<script>
/**
 * Modal HOC
 *
 * Use this as a base for your custom modals
 */
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal';
import { XIcon } from 'vue-feather-icons';

export default {
  name: 'Modal',
  components: {
    VueFinalModal,
    ModalsContainer,
    XIcon
  },

  data: function() {
    return {
      isActive: true
    };
  },

  watch: {
    value: {
      handler: function() {
        this.isActive = this.value;
      },
      immediate: true
    }
  },

  props: {
    closable: {
      type: Boolean,
      default: true
    },
    value: {}
  },

  computed: {},

  methods: {
    onClose: function() {
      this.$emit('input', this.isActive);
    }
  }
};
</script>

<style lang="scss">
.vfm {
  .vfm__overlay {
    background-color: rgb(175 175 175);
  }
  .vfm__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .vfm__content {
    padding: var(--space-md) var(--space-lg) !important;
    background-color: var(--g1);
    border-radius: var(--border-radius);
    text-align: center;
    box-shadow: 0px 1.2px 1.3px rgba(0, 0, 0, 0.04),
      0px 4px 4.5px rgba(0, 0, 0, 0.06), 0px 18px 20px rgba(0, 0, 0, 0.1);
  }
  .vfm__content {
    position: relative;
    min-width: 400px;
    border-radius: 20px;
    padding: var(--space-lg) var(--space-sm);

    > svg {
      position: absolute;
      top: var(--space-sm);
      right: var(--space-sm);
    }
  }
}
</style>
