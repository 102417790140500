<template>
  <div class="form-group c-input-checkbox">
    <label class="form__label" :for="slug">{{ label }}</label>
    <input
      :id="slug"
      type="checkbox"
      :checked="value"
      :method="method"
      @change="onChange"
    />
  </div>
</template>

<script>
import slugify from '@/lib/slugify';

export default {
  props: {
    label: {
      type: String,
      default: 'Label'
    },
    value: {},
    method: {
      type: Function
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },

  computed: {
    slug: function() {
      return slugify(this.label);
    }
  },

  methods: {
    onChange: function(e) {
      let event = e;
      let value = e.target.value;
      this.$emit('input', value);

      if (this.method) {
        this.method(event);
      }
    }
  }
};
</script>

<style lang="scss">
.form-group.c-input-checkbox {
  margin: 0;
}
.c-input-checkbox .form__label {
  // visually hide
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.c-input-checkbox input {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  font: inherit;
  color: currentColor;
  width: 1.25em;
  height: 1.25em;
  border: 1px solid rgba(42, 45, 82, 0.4);
  border-radius: 0.15em;
  margin: 0 10px 0 0;
  display: grid;
  place-content: center;
  /* transform: translateY(-.125em); */
}

.c-input-checkbox input::before {
  content: '';
  width: 0.75em;
  height: 0.75em;
  transform: scale(0);
  box-shadow: inset 1em 1em #fff;
}

.c-input-checkbox input:checked {
  background-color: var(--color-blue);
}

.c-input-checkbox input:checked::before {
  transform: scale(1);
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
</style>
