<template>
  <div class="c-icon" @click="onClick">
    <component v-if="name" :is="name"></component>
  </div>
</template>

<script>
import { EyeIcon, Edit2Icon, DeleteIcon } from 'vue-feather-icons';
export default {
  components: {
    EyeIcon,
    Edit2Icon,
    DeleteIcon
  },

  props: {
    name: {
      type: String,
      default: ''
    }
  },

  methods: {
    onClick: function() {
      this.$emit('onClick');
    }
  }
};
</script>

<style lang="scss">
.c-icon {
}
</style>
