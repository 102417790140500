<template>
  <div class="c-input-description">
    <span>{{ message }}</span>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss">
.c-input-description {
  margin: var(--margin-lg) 0 0;
  font-size: var(--font-size-sm);
  opacity: 0.75;
  //color: var(--danger-color);
}
</style>
