function objectId() {
	let str = new Date().getTime() / 1000;
	str = str | 0;
	var timestamp = str.toString(16);
	const format = 'xxxxxxxxxxxxxxxx';
	let temp = format.replace(/[x]/g, function() {
		let str = (Math.random() * 16) | 0;
		str = str.toString(16);
		return str;
	});
	return timestamp + temp;
}

export default objectId;
