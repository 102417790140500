<template>
  <div class="c-form">
    <Accordion
      v-for="(schema, index) in computedSchema"
      :key="index"
      :title="schema.label"
      :description="schema.description"
      :active="true"
    >
      <div class="container-fluid">
        <div class="row" v-if="schema.type === 'accordion'">
          <div
            :class="[getColumnClass(field)]"
            v-for="(field, key) in schema.fields"
            :key="key"
          >
            <transition name="pop">
              <component
                v-if="checkLogic(field)"
                :key="field.slug"
                :is="field.component"
                @input="update(key, $event)"
                :value="setValue(key)"
                v-bind="{ ...field }"
                :hasError="$v.form[key] ? $v.form[key].$error : false"
                :errorMessage="errorMessages[key]"
              ></component>
            </transition>
          </div>
        </div>
        <div class="row" v-if="schema.type === 'content'">
          <article v-html="schema.content"></article>
        </div>
      </div>
    </Accordion>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';

import validationRules from './parser';

import InputText from '@/components/global/forms/input-text';
import InputDropdown from '@/components/global/forms/input-dropdown';
import InputEmail from '@/components/global/forms/input-email';
import InputNotes from '@/components/global/forms/input-notes';
import InputFile from '@/components/global/forms/input-file';
import InputRelation from '@/components/global/forms/input-relation';
import InputNumber from '@/components/global/forms/input-number';
import InputLocations from '@/components/global/forms/input-locations';
import InputEnum from '@/components/global/forms/input-enum';
import InputBoolean from '@/components/global/forms/input-boolean';
import InputRelationMultiple from '@/components/global/forms/input-relation-multiple';
import InputProducts from '@/components/global/forms/input-products';
import InputOrders from '@/components/global/forms/input-orders';
import InputUsers from '@/components/global/forms/input-users';
import InputSelectOther from '@/components/global/forms/input-select-other';
import InputTextMultiple from '@/components/global/forms/input-text-multiple';
import InputDateTimePicker from '@/components/global/forms/input-datetimepicker';
import InputDatePicker from '@/components/global/forms/input-datepicker';
import InputTextarea from '@/components/global/forms/input-textarea';
import InputEditor from '@/components/global/forms/input-editor';
import InputNoteStatus from '@/components/global/forms/input-note-status';

import Accordion from '@/components/global/accordion';

import conditionChecker from './condition-checker';

export default {
  mixins: [validationMixin],

  components: {
    InputText,
    InputDropdown,
    InputEmail,
    InputNotes,
    InputFile,
    InputRelation,
    InputNumber,
    InputLocations,
    InputEnum,
    InputBoolean,
    InputRelationMultiple,
    InputProducts,
    InputOrders,
    InputUsers,
    InputSelectOther,
    InputTextMultiple,
    InputDateTimePicker,
    InputDatePicker,
    InputTextarea,
    InputEditor,
    InputNoteStatus,

    Accordion
  },

  validations() {
    return { form: validationRules(this.computedFlatSchema) };
  },

  data: function() {
    return {
      form: {}
    };
  },

  props: {
    schema: {
      type: Array,
      default: function() {
        return [];
      }
    },
    fields: {
      type: Array,
      default: function() {
        return [];
      }
    },
    value: { type: Object, required: true }
  },

  computed: {
    orders: function() {
      return this.$store.getters['orders/filtered'];
    },
    isValid: function() {
      return false;
    },
    computedFields: function() {
      let fields = this.fields;

      let obj = {};

      for (let i = 0; i < fields.length; i++) {
        let component = 'InputText';
        if (fields[i].type === 'NUMBER') {
          component = 'InputNumber';
        }
        if (fields[i].type === 'BOOLEAN') {
          component = 'InputBoolean';
        }
        let fieldName = `form_${fields[i].slug}`;
        obj[fieldName] = {
          component: component,
          label: fields[i].label,
          validations: {}
        };
      }

      return obj;
    },
    computedSchema: function() {
      let schema = [];

      for (let i = 0; i < this.schema.length; i++) {
        if (this.schema[i].type === 'insert') {
          schema.push({
            type: 'accordion',
            label: 'Custom fields',
            fields: this.computedFields
          });
        } else if (this.schema[i].type === 'content') {
          schema.push({
            type: 'content',
            label: this.schema[i].label || '',
            content: this.schema[i].content,
            fields: this.schema[i].fields || []
          });
        } else if (this.schema[i].type === 'meta') {
          // Ignore if meta
        } else if (this.schema[i].type === 'submit') {
          // Also ignore if submit
        } else {
          schema.push({
            type: 'accordion',
            label: this.schema[i].label,
            description: this.schema[i].content || '',
            fields: this.schema[i].fields
          });
        }
      }

      return schema;
    },
    computedFlatSchema: function() {
      let newSchema = {};
      let schema = this.computedSchema;
      for (let i = 0; i < schema.length; i++) {
        for (let key in schema[i].fields) {
          newSchema[key] = schema[i].fields[key];
        }
      }

      return newSchema;
    },
    errorMessages() {
      const validations = this.$v.form;
      let schema = this.computedFlatSchema;

      return Object.keys(schema).reduce((messages, key) => {
        const rules = schema[key].validations || {};
        const rulesKeys = Object.keys(rules);
        const validator = validations[key];

        if (!validator) return messages;

        for (let rule of rulesKeys) {
          if (validator[rule] !== false) continue;

          messages[key] = rules[rule].message;
          return messages;
        }

        return messages;
      }, {});
    }
  },

  methods: {
    setValue: function(key) {
      let form = this.form;
      if (key.includes('form_')) {
        return form[key];
      } else {
        return form[key];
      }
    },
    checkLogic: function(field) {
      if (conditionChecker(field, this.form)) {
        return true;
      }
      return;
    },
    hasError: function(key) {
      if (this.$v.form[key]) {
        return this.$v.form[key].$error;
      } else {
        return false;
      }
    },
    getColumnClass: function(component) {
      let klass = 'col-12';

      if (component.properties && component.properties.columnSize) {
        klass = `col-${component.properties.columnSize}`;
      }

      return klass;
    },

    validate() {
      this.$v.$touch();
      this.announceStatus();
    },

    update: function(key, value) {
      this.form[key] = value;

      if (this.$v.form[key]) {
        this.$v.form[key].$touch();
      }

      let newForm = {
        ...this.value,
        [key]: value
      };

      this.$emit('input', newForm);

      this.announceStatus();
    },
    announceStatus: function() {
      this.$emit('status', {
        invalid: this.$v.$invalid
      });
    }
  },

  created() {
    for (const name in this.computedFlatSchema) {
      this.$set(this.form, name, null);
    }
    for (const name in this.value) {
      if (name in this.form) {
        this.$set(this.form, name, this.value[name]);
      }
    }
    if (this.value.fields) {
      let fields = this.value.fields;
      for (let i = 0; i < fields.length; i++) {
        this.$set(this.form, `form_${fields[i].slug}`, fields[i].value);
      }
    }
  }
};
</script>

<style lang="scss">
.c-form {
  .pop-leave-active {
    transition: all 400ms ease-in;
  }

  // this transition is bugged so this is the best i could do to highlight a conditional field
  .pop-leave-to {
    opacity: 0;
  }
}
</style>
