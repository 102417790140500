<template>
  <div class="c-organisations-drilldown">
    <Breadcrumb></Breadcrumb>
    <Dashboard>
      <DashboardHeader :title="`${resource.name} | Details`">
        <router-link
          v-if="!isReadOnly"
          :to="'/organisations/update/' + resource.id"
          class="btn btn-primary"
          >Update</router-link
        >
      </DashboardHeader>
      <DashboardBody>
        <article class="article-text">
          <p>
            {{ resource.name }} has
            <span v-if="resource.category">
              category of {{ resource.category }},
            </span>
            <span v-if="resource.type"> type of {{ resource.type }}, </span>
            <span v-if="resource.status"> status of {{ resource.status }}</span>
          </p>
        </article>
        <Accordion title="Organisation Details">
          <Stats :resource="resource"></Stats>
        </Accordion>
        <List
          :options="leadListOptions"
          :resources="leadsByOrganisation"
          @click="onClick"
          :columns="leadColumns"
        >
          <template v-slot:title>
            <h5>{{ resource.name }}'s People</h5>
          </template>
          <template v-slot:buttons>
            <router-link
              v-if="!isReadOnly"
              :to="`/people/create?organisationId=${resource.id}`"
              class="btn btn-primary"
              >Add new person</router-link
            >
          </template>
        </List>
        <List
          :options="locationListOptions"
          :resources="locationsByOrganisation"
          @click="onLocationClick"
          :columns="locationColumns"
        >
          <template v-slot:title>
            <h5>{{ resource.name }}'s Locations</h5>
          </template>
          <template v-slot:buttons>
            <router-link
              v-if="!isReadOnly"
              :to="`/locations/create?organisationId=${resource.id}`"
              class="btn btn-primary"
              >Add new location</router-link
            >
          </template>
        </List>
        <List
          :options="noteListOptions"
          :resources="notesByOrganisation"
          @click="onClick"
          :columns="noteColumns"
        >
          <template v-slot:title>
            <h5>{{ resource.name }}'s Notes</h5>
          </template>
        </List>
      </DashboardBody>
    </Dashboard>
    <NotesPopup></NotesPopup>
  </div>
</template>

<script>
import Dashboard from '@/components/dashboard';
import DashboardHeader from '@/components/dashboard/header';
import DashboardBody from '@/components/dashboard/body';

import Button from '@/components/global/button';

import List from '@/components/list';

import Stats from './stats';

import NotesPopup from '@/components/notes/popup';

import Accordion from '@/components/global/accordion';

import Breadcrumb from '@/components/global/breadcrumb';

export default {
  components: {
    Dashboard,
    DashboardHeader,
    DashboardBody,

    Button,

    List,

    Stats,

    NotesPopup,

    Accordion,

    Breadcrumb
  },

  computed: {
    resourceId: function() {
      return this.$route.params.id;
    },
    resource: function() {
      let resourceId = this.resourceId;
      let resource = this.$store.getters['organisations/one'](resourceId);
      return resource;
    },
    isReadOnly: function() {
      return this.$store.getters['users/isReadOnly'];
    },
    locationListOptions: function() {
      return {
        fullContainerWidth: true,
        pagination: 10,
        search: ['name', 'streetAddress', 'postalCode', 'state', 'landline'],
        filter: null
      };
    },
    leadListOptions: function() {
      return {
        fullContainerWidth: true,
        pagination: 10,
        search: ['name', 'email', 'mobile', 'landline', 'role'],
        filter: null
      };
    },
    noteListOptions: function() {
      return {
        fullContainerWidth: true,
        pagination: 10,
        search: ['status'],
        filter: null
      };
    },
    organisations: function() {
      return this.$store.getters['organisations/filtered'];
    },
    locationsByOrganisation: function() {
      return this.$store.getters['locations/byOrganisation'](this.resourceId);
    },
    leadsByOrganisation: function() {
      return this.$store.getters['leads/byOrganisation'](this.resourceId);
    },
    notesByOrganisation: function() {
      return this.$store.getters['notes/byOrganisation'](this.resourceId);
    },
    locationColumns: function() {
      return [
        {
          label: 'Name',
          key: 'name',
          sortable: true
        },
        {
          label: 'Street Address',
          key: 'streetAddress',
          clickable: true,
          sortable: true
        },
        {
          label: 'Postal Code',
          key: 'postalCode',
          sortable: true
        },
        {
          label: 'State',
          key: 'state',
          sortable: true,
          renderer: 'ItemEnum'
        },
        {
          label: 'Landline',
          key: 'landline'
        },
        {
          label: 'Created at',
          key: 'createdAt',
          renderer: 'ItemDate',
          sortable: true,
          sortType: 'date'
        },
        {
          label: 'Action',
          key: '_action',
          sortable: false,
          clickable: false,
          actions: ['V', 'E']
        }
      ];
    },
    leadColumns: function() {
      return [
        {
          label: 'Name',
          key: 'firstName,lastName',
          sortable: true,
          clickable: true,
          formatter: (resource, column) => {
            return `${resource.firstName}  ${resource.lastName}`;
          }
        },
        {
          label: 'Email',
          key: 'email',
          sortable: true
        },
        {
          label: 'Mobile',
          key: 'mobile'
        },
        {
          label: 'Landline',
          key: 'landline'
        },
        {
          label: 'Role/Title',
          key: 'role',
          sortable: false,
          clickable: false
        },
        {
          label: 'Last contacted by',
          key: '12345',
          formatter: (resource, column) => {
            let notes = this.$store.getters['notes/byLead'](resource.id);

            let note = null;

            if (notes.length > 0) {
              note = notes[0];
            }

            if (!note) {
              return '';
            }

            return `${note.user.firstName} ${note.user.lastName}`;
          }
        },
        {
          label: 'Latest follow up date',
          key: 'followupDate',
          renderer: 'ItemDate',
          sortable: true,
          sortType: 'string'
        },
        {
          label: 'Latest follow up person',
          key: 'followupUser',
          renderer: 'ItemUser'
        },
        {
          label: 'Notes',
          renderer: 'ItemNote'
        },
        {
          label: 'Created at',
          key: 'createdAt',
          renderer: 'ItemDate',
          sortable: true,
          sortType: 'date'
        },
        {
          label: 'Action',
          key: '_action',
          sortable: false,
          clickable: false,
          actions: ['V', 'E']
        }
      ];
    },
    noteColumns: function() {
      return [
        {
          label: 'Assigned to',
          key: 'userId',
          renderer: 'ItemUser'
        },
        {
          label: 'Folloup date',
          key: 'followupDate',
          renderer: 'ItemDate',
          sortable: true,
          sortType: 'date'
        },
        {
          label: 'Note',
          key: 'html',
          sortable: true,
          sortType: 'string',
          formatter: function(resource, column) {
            let cleanText = resource[column.key].replace(/<\/?[^>]+(>|$)/g, '');
            let limit = 25;
            if (cleanText.length > limit) {
              return cleanText.substring(0, limit) + '...';
            }
            return cleanText;
          },
          enum: 'status'
        },
        {
          label: 'Contact name',
          formatter: (resource, column) => {
            let lead = this.$store.getters['leads/one'](resource.leadId);
            if (!lead) {
              return '';
            }
            return `${lead.firstName} ${lead.lastName}`;
          }
        },
        {
          label: 'Contact email',
          formatter: (resource, column) => {
            let lead = this.$store.getters['leads/one'](resource.leadId);
            if (!lead) {
              return '';
            }
            return `${lead.email}`;
          }
        },
        {
          label: 'Contact mobile',
          formatter: (resource, column) => {
            let lead = this.$store.getters['leads/one'](resource.leadId);
            if (!lead) {
              return '';
            }
            return `${lead.mobile}`;
          }
        },
        {
          label: 'Contact landline',
          formatter: (resource, column) => {
            let lead = this.$store.getters['leads/one'](resource.leadId);
            if (!lead) {
              return '';
            }
            return `${lead.landline}`;
          }
        },
        {
          label: 'Status',
          key: 'status',
          sortable: true,
          sortType: 'string',
          renderer: 'ItemEnum',
          enum: 'metrics'
        },
        {
          label: 'Created at',
          key: 'createdAt',
          renderer: 'ItemDate',
          sortable: true,
          sortType: 'date'
        }
      ];
    }
  },

  methods: {
    onLocationClick: function(payload) {
      if (payload.action && payload.action === 'view') {
        this.$store.dispatch('app/setInfoWindow', {
          type: 'location',
          id: payload.id
        });
      }

      if (payload.action && payload.action === 'edit') {
        this.$router.push({
          name: 'LocationsUpdate',
          params: { id: payload.id }
        });
      }

      if (payload.action && payload.action === 'delete') {
        this.$store.dispatch('locations/delete', payload.id);
      }
    },
    onClick: function(payload) {
      if (payload.action && payload.action === 'view') {
        this.$store.dispatch('app/setInfoWindow', {
          type: 'organisation',
          id: payload.id
        });
      }

      if (payload.action && payload.action === 'edit') {
        this.$router.push({
          name: 'OrganisationsUpdate',
          params: { id: payload.id }
        });
      }

      if (payload.action && payload.action === 'delete') {
        this.$store.dispatch('organisations/delete', payload.id);
      }
    }
  }
};
</script>

<style lang="scss">
.c-organisations-drilldown {
}
</style>
