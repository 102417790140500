<template>
  <div class="form-group c-input-file">
    <h3 class="c-input-file__heading" v-if="label">
      <label class="form__label" :for="slug">{{ label }}</label>
    </h3>
    <div
      :class="['c-input-file__drop-area', { hover: isMouseOver === true }]"
      @drop="onDrop"
      @dragenter.prevent="onDragEnter"
      @dragover.prevent="onDragOver"
      @dragleave.prevent="onDragLeave"
    >
      <div :class="['c-input-file__file', { active: hasFile === true }]">
        <div class="c-input-file__file__icon">
          <file-text-icon size="1.5x" v-if="hasFile"></file-text-icon>
          <download-icon size="1.5x" v-else></download-icon>
        </div>
        <p>
          <strong v-if="file">{{ file.name }}</strong>
          <strong v-else>Upload File</strong>
        </p>
      </div>
      <div class="c-input-file__form-elements">
        <input type="file" :accept="accept" @change="onChange" :id="slug" />
      </div>
      <p v-if="file">
        <button class="c-input-file__action-link">Remove file</button> or drag
        and drop another file here
      </p>
      <p v-else>
        <button class="c-input-file__action-link">Select file</button> or drag
        and drop file here
      </p>
    </div>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
  </div>
</template>

<script>
import InputError from './input-error';
import slugify from '@/lib/slugify';
import FileUpload from 'vue-upload-component';
import { FileTextIcon, DownloadIcon } from 'vue-feather-icons';

export default {
  components: {
    FileUpload,
    InputError,
    FileTextIcon,
    DownloadIcon
  },

  data: function() {
    return {
      files: [],
      file: null,
      isMouseOver: false
    };
  },

  watch: {
    file: function() {
      this.$emit('input', file);
    }
  },

  props: {
    label: {
      type: String,
      default: ''
    },
    value: {},
    placeholder: {
      type: String,
      default: ''
    },
    extensions: {
      type: String,
      default: 'gif,jpg,jpeg,png,webp,pdf'
    },
    accept: {
      type: String,
      default: 'image/png,image/gif,image/jpeg,image/webp,application/pdf'
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },

  computed: {
    hasFile: function() {
      if (this.file) {
        return true;
      }
      return false;
    },
    slug: function() {
      return slugify(this.label);
    }
  },

  methods: {
    onChange: function(e) {
      this.file = e.target.files[0];
    },
    onDrop: function(e) {
      e.preventDefault();
      this.isMouseOver = false;
      this.file = e.dataTransfer.files[0];
    },
    onDragEnter: function(e) {
      this.isMouseOver = true;
    },
    onDragOver: function(e) {},
    onDragLeave: function(e) {
      this.isMouseOver = false;
    }
  }
};
</script>

<style lang="scss">
.c-input-file {
  padding: 0 var(--space-lg);

  &__heading {
    margin-bottom: var(--space-sm);
  }

  &__drop-area {
    align-items: center;
    border: 1px dashed var(--color-grey);
    border-radius: var(--border-radius-md);
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--space-lg);
    position: relative;
    text-align: center;
    z-index: 1;

    &.hover {
      background-color: var(--silver);
      border-color: var(--color-black);
    }
  }

  &__form-elements {
    // visibly hidden
    // https://www.a11yproject.com/posts/how-to-hide-content/
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  &__file {
    &__icon {
      align-items: center;
      background-color: var(--color-grey-lighter);
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      margin-bottom: var(--space-xs);
      min-height: calc(var(--space-lg) * 1.25);
      min-width: calc(var(--space-lg) * 1.25);
    }

    &.active {
      .c-input-file__file__icon {
        background-color: var(--color-blue);
        color: var(--color-white);
      }
    }
  }

  &__action-link {
    appearance: none;
    background: none;
    border: 0;
    color: var(--color-blue);
    cursor: pointer;
    font-weight: 700;
    padding: 0;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
}
</style>
