<template>
  <div class="form-group c-input-password">
    <label class="form__label" :for="slug">{{ label }}</label>
    <input
      class="form-control"
      :id="slug"
      type="password"
      :value="value"
      @change="onChange"
    />
    <InputError v-if="hasError" :message="errorMessage"></InputError>
  </div>
</template>

<script>
import InputError from './input-error';
import slugify from '@/lib/slugify';

export default {
  components: {
    InputError
  },

  props: {
    label: {
      type: String,
      default: 'Label'
    },
    value: {},
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },

  computed: {
    slug: function() {
      return slugify(this.label);
    }
  },

  methods: {
    onChange: function(e) {
      let value = e.target.value;
      this.$emit('input', value);
    }
  }
};
</script>

<style lang="scss">
.c-input-password {
}
</style>
