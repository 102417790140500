import * as localForage from 'localforage';
import { http } from './http.js';
import CrudApi from '@/lib/crud-api';

let store = null;
store = localForage.createInstance({
  name: 'ordering',
  driver: localForage.LOCALSTORAGE
});

function Storage() {
  this.get = async function(key) {
    let res = null;
    res = await store.getItem(key);
    return res;
  };

  this.set = async function(key, data) {
    let res = null;
    res = await store.setItem(key, data);
    return res;
  };

  this.remove = async function(key) {
    let res = null;
    res = await store.removeItem(key);
    return res;
  };
}

let storage = new Storage();
let self = null;

let api = {
  init: function() {
    self = this;
    return true;
  },

  fields: {
    async get() {
      let response = await http.get('/field');
      let resources = response.data || [];
      return resources;
    }
  },

  products: {
    async get() {
      let response = await http.get('/product');
      let resources = response.data || [];
      return resources;
    }
  },

  organisations: {
    async get() {
      let response = await http.get('/organisation');
      let resources = response.data || [];
      return resources;
    },

    async add(resource) {
      let response = null;
      try {
        response = await http.post('/organisation', resource);
      } catch (err) {
        throw err.response.data || [];
      }
      if (!response) {
        throw [
          {
            type: 'GENERIC',
            messge: 'Something went wrong, please try again'
          }
        ];
      }
      resource = response.data;
      return resource;
    },

    async update(resource) {
      let response = null;
      try {
        response = await http.put(`/organisation/${resource.id}`, resource);
      } catch (err) {
        throw err.response.data;
      }
      if (!response) {
        throw [
          {
            type: 'GENERIC',
            messge: 'Something went wrong, please try again'
          }
        ];
      }
      resource = response.data;
      return resource;
    },

    async delete(id) {
      let response = null;
      try {
        response = await http.delete(`/organisation/${id}`);
      } catch (err) {
        throw err.response.data || [];
      }
      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    },

    async doAction(id, action) {
      let response = null;
      try {
        response = await http.post(`/organisation/${id}/action/${action}`);
      } catch (err) {
        throw err.response.data || [];
      }
      if (response.status === 201) {
        return response.data;
      } else {
        return false;
      }
    }
  },

  locations: {
    async get() {
      let response = await http.get('/location');
      let resources = response.data || [];
      return resources;
    },

    async add(resource) {
      let response = null;
      try {
        response = await http.post('/location', resource);
      } catch (err) {
        throw err.response.data || [];
      }
      if (!response) {
        throw [
          {
            type: 'generic',
            messge: 'Something went wrong, please try again'
          }
        ];
      }
      resource = response.data;
      return resource;
    },

    async update(resource) {
      let response = null;

      try {
        response = await http.put(`/organisation/${resource.id}`, resource);
      } catch (err) {
        throw err.response.data;
      }
      if (!response) {
        throw [
          {
            type: 'generic',
            messge: 'Something went wrong, please try again'
          }
        ];
      }
      resource = response.data;
      return resource;
    },

    async delete(id) {
      let response = null;
      try {
        response = await http.delete(`/organisation/${id}`);
      } catch (err) {
        throw err.response.data || [];
      }
      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    }
  },

  leads: {
    async get() {
      let response = await http.get('/lead');
      let resources = response.data || [];
      return resources;
    },

    async add(resource) {
      let response = null;
      try {
        response = await http.post('/lead', resource);
      } catch (err) {
        throw err.response.data || [];
      }
      if (!response) {
        throw [
          {
            type: 'generic',
            messge: 'Something went wrong, please try again'
          }
        ];
      }
      resource = response.data;
      return resource;
    },

    async update(resource) {
      let response = null;
      try {
        response = await http.put(`/lead/${resource.id}`, resource);
      } catch (err) {
        throw err.response.data;
      }
      console.log(response.data);
      if (!response) {
        throw [
          {
            type: 'generic',
            messge: 'Something went wrong, please try again'
          }
        ];
      }
      resource = response.data;
      return resource;
    },

    async delete(id) {
      let response = null;
      try {
        response = await http.delete(`/lead/${id}`);
      } catch (err) {
        throw err.response.data || [];
      }
      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    },

    async doAction(id, action) {
      let response = null;
      try {
        response = await http.post(`/lead/${id}/action/${action}`);
      } catch (err) {
        throw err.response.data || [];
      }
      if (response.status === 201) {
        return response.data;
      } else {
        return false;
      }
    }
  },

  /**
   * Notes
   */
  notes: {
    async get() {
      let response = await http.get('/note');
      let resources = response.data || [];
      return resources;
    },

    async add(resource) {
      let response = null;
      try {
        response = await http.post('/note', resource);
      } catch (err) {
        throw err.response.data || [];
      }
      if (!response) {
        throw [
          {
            type: 'generic',
            messge: 'Something went wrong, please try again'
          }
        ];
      }
      resource = response.data;
      return resource;
    },

    async update(resource) {
      let response = null;
      try {
        response = await http.put(`/note/${resource.id}`, resource);
      } catch (err) {
        console.log(err);
      }
      if (!response) {
        return null;
      }
      resource = response.data;
      return resource;
    },

    async delete(id) {
      let response = null;
      try {
        response = await http.delete(`/note/${id}`);
      } catch (err) {
        throw err.response.data || [];
      }
      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    }
  },

  locations: {
    async get() {
      let response = await http.get('/location');
      let resources = response.data || [];
      return resources;
    },

    async add(resource) {
      let response = null;
      try {
        response = await http.post('/location', resource);
      } catch (err) {
        throw err.response.data || [];
      }
      if (!response) {
        throw [
          {
            type: 'generic',
            messge: 'Something went wrong, please try again'
          }
        ];
      }
      resource = response.data;
      return resource;
    },

    async update(resource) {
      let response = null;
      try {
        response = await http.put(`/location/${resource.id}`, resource);
      } catch (err) {
        throw err.response.data;
      }
      if (!response) {
        throw [
          {
            type: 'generic',
            messge: 'Something went wrong, please try again'
          }
        ];
      }
      resource = response.data;
      return resource;
    },

    async delete(id) {
      let response = null;
      try {
        response = await http.delete(`/location/${id}`);
      } catch (err) {
        throw err.response.data || [];
      }
      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    }
  },

  orders: {
    async get() {
      let response = await http.get('/order');
      let resources = response.data || [];
      return resources;
    },

    async add(resource) {
      let response = null;
      try {
        response = await http.post('/order', resource);
      } catch (err) {
        throw err.response.data || [];
      }
      if (!response) {
        throw [
          {
            type: 'generic',
            messge: 'Something went wrong, please try again'
          }
        ];
      }
      resource = response.data;
      return resource;
    },

    async update(resource) {
      let response = null;
      try {
        response = await http.put(`/order/${resource.id}`, resource);
      } catch (err) {
        console.log(err);
      }
      if (!response) {
        return null;
      }
      resource = response.data;
      return resource;
    },

    async delete(id) {
      let response = null;
      try {
        response = await http.delete(`/order/${id}`);
      } catch (err) {
        throw err.response.data || [];
      }
      if (response.status === 201 || response.status === 200) {
        return true;
      } else {
        return false;
      }
    },

    async doAction(id, action) {
      let response = null;
      try {
        response = await http.post(`/order/${id}/action/${action}`);
      } catch (err) {
        throw err.response.data || [];
      }
      if (response.status === 201) {
        return response.data;
      } else {
        return false;
      }
    }
  },

  /**
   * Winthrop Receipts
   */
  winthropReceipts: {
    async get() {
      let response = await http.get('/winthrop-receipt');
      let resources = response.data || [];
      return resources;
    },

    async add(resource) {
      let response = null;
      try {
        response = await http.post('/winthrop-receipt', resource);
      } catch (err) {
        throw err.response.data || [];
      }
      if (!response) {
        throw [
          {
            type: 'generic',
            messge: 'Something went wrong, please try again'
          }
        ];
      }
      resource = response.data;
      return resource;
    },

    async update(resource) {
      let response = null;
      try {
        response = await http.put(`/winthrop-receipt/${resource.id}`, resource);
      } catch (err) {
        console.log(err);
      }
      if (!response) {
        return null;
      }
      resource = response.data;
      return resource;
    },

    async delete(id) {
      let response = null;
      try {
        response = await http.delete(`/winthrop-receipt/${id}`);
      } catch (err) {
        throw err.response.data || [];
      }
      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    },

    async doAction(id, action) {
      let response = null;
      try {
        response = await http.post(`/winthrop-receipt/${id}/action/${action}`);
      } catch (err) {
        throw err.response.data || [];
      }
      if (response.status === 201) {
        return response.data;
      } else {
        return false;
      }
    }
  },

  customForms: {
    async doAction(id, action, form) {
      let response = null;
      try {
        response = await http.post(`/custom-form/${id}/action/${action}`, {
          form
        });
      } catch (err) {
        throw err.response.data || [];
      }
      if (response.status === 201) {
        return response.data;
      } else {
        return false;
      }
    }
  },

  /**
   * RCTIs
   */
  rctis: {
    async get() {
      let response = await http.get('/rcti');
      let resources = response.data || [];
      return resources;
    },

    async add(resource) {
      let response = null;
      try {
        response = await http.post('/rcti', resource);
      } catch (err) {
        throw err.response.data || [];
      }
      if (!response) {
        throw [
          {
            type: 'generic',
            messge: 'Something went wrong, please try again'
          }
        ];
      }
      resource = response.data;
      return resource;
    },

    async update(resource) {
      let response = null;
      try {
        response = await http.put(`/rcti/${resource.id}`, resource);
      } catch (err) {
        console.log(err);
      }
      if (!response) {
        return null;
      }
      resource = response.data;
      return resource;
    },

    async delete(id) {
      let response = null;
      try {
        response = await http.delete(`/rcti/${id}`);
      } catch (err) {
        throw err.response.data || [];
      }
      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    },

    async doAction(id, action) {
      let response = null;
      try {
        response = await http.post(`/rcti/${id}/action/${action}`);
      } catch (err) {
        throw err.response.data || [];
      }
      if (response.status === 201) {
        return response.data;
      } else {
        return false;
      }
    }
  },

  reports: {
    async doAction(id, action, resource) {
      let response = null;
      let form = {};
      if (resource) {
        form.resource = resource;
      }
      try {
        response = await http.post(`/report/1/action/${action}`, form);
      } catch (err) {
        throw err.response.data || [];
      }
      if (response.status === 201) {
        return response.data;
      } else {
        return false;
      }
    }
  },

  users: {
    async get() {
      let response = await http.get('/user');
      let resources = response.data || [];
      return resources;
    },

    async resetPassword(form) {
      let response = await http.post('/user/password-reset', form);
      let resources = response.data || [];
      return resources;
    },

    async resetPasswordRequest(form) {
      let response = await http.post('/user/password-reset-request', form);
      let resources = response.data || [];
      return resources;
    },

    async login(form) {
      let response = await http.post('/user/login', form);
      let data = (response && response.data) || null;
      return data;
    },

    async headlessLogin() {
      let response = await http.post('/user/headless-login', {});
      let data = (response && response.data) || null;
      return data;
    },

    async logout() {
      let response = await http.post('/user/logout', {});
      if (response && response.status === 200) {
        storage.remove('token');
        return true;
      } else {
        return false;
      }
    },

    async saveToken(token) {
      storage.set('token', token);
    },

    async getToken() {
      return await storage.get('token');
    }
  },

  app: {
    async getSessionId() {
      return await storage.get('sessionId');
    },
    async setSessionId(sessionId) {
      return await storage.set('sessionId', sessionId);
    }
  }
};

export default api;
