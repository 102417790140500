<template>
  <div class="c-list">
    <ListFilters
      v-if="options.search || options.sort || options.filter"
      :options="options"
      :filter="options.filter"
      @onQuery="onQuery"
      v-model="filter"
    >
      <slot name="title"></slot>
      <slot slot="buttons" name="buttons"></slot>
    </ListFilters>
    <div
      :class="[
        'c-list__outer-wrapper',
        { 'full-container-width': options.fullContainerWidth === true }
      ]"
    >
      <div class="c-list__wrapper">
        <div class="c-list__content">
          <ListHeader
            :columns="columns"
            @onToggle="onToggle"
            @onToggleFilter="onToggleFilter"
            :sort="sort"
            :sortDirection="sortDirection"
            :fullContainerWidth="options.fullContainerWidth"
          ></ListHeader>
          <ListRow
            v-for="resource in paginatedResources"
            :key="resource.id"
            :resource="resource"
            :columns="columns"
            :selected="selected"
            @click="onClick"
          ></ListRow>
        </div>
      </div>
    </div>
    <ListFooter
      ref="listFooter"
      v-if="options.pagination"
      :total="resources.length"
      :count="computedResources.length"
      :pagination="options.pagination"
      :currentCount="paginatedResources.length"
      @onOffset="onOffset"
    />
  </div>
</template>

<script>
import ListFilters from '@/components/list/list-filters';
import ListHeader from '@/components/list/list-header';
import ListRow from '@/components/list/list-row';
import ListFooter from '@/components/list/list-footer';

import sortDate from './sort-date';
import search from './search';

/**
 * Column api
 * Array of objects
 *
 * This is what should be in the object
 * label - Decides what shows up on the list header
 * key - what field of the resource item shows up - note that key shouldn't start with underscore as they are reserved
 * sortable - boolean - if true, sorts by the key, may be depreciated
 * clickable - boolean - if true, listen to the click action on the <list></list> component
 * computed - will be depreciated
 * formatter - function(resource, columns) { return '' } - decides how the text should be formatted instead
 * ..other custom properties may be passed. For instance, the actions item can be modified by passing an actions array['V', 'D', 'E']
 *
 */

export default {
  components: {
    ListFilters,
    ListHeader,
    ListRow,
    ListFooter
  },

  data: function() {
    return {
      selected: [],
      query: '',
      offset: 0,
      filter: '',
      sort: '',
      sortDirection: 'ASC',
      sortType: ''
    };
  },

  props: {
    resources: {
      type: Array,
      default: function() {
        return [];
      }
    },
    options: {
      type: Object,
      default: function() {
        return {
          fullContainerWidth: true,
          pagination: 100,
          search: ['firstName', 'lastName', 'email', 'landline', 'category'],
          filter: {
            label: 'Stage',
            key: 'status',
            options: ['Proposal', 'Test', 'Lead', 'Customer', 'Potential']
          }
        };
      }
    },
    columns: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },

  computed: {
    computedResources: function() {
      let resources = this.resources;
      let options = this.options;
      let query = this.query;
      let filter = this.filter;
      let sort = this.sort;
      let sortType = this.sortType;
      let sortDirection = this.sortDirection;

      resources = this.searchResources(resources, options, query);

      resources = this.filterResources(resources, options, filter);

      resources = this.sortResources(
        resources,
        options,
        sort,
        sortType,
        sortDirection
      );

      return resources;
    },
    paginatedResources: function() {
      let resources = this.computedResources;
      let options = this.options;
      let offset = this.offset;
      let query = this.query;

      resources = this.paginateResources(resources, options, offset, query);

      return resources;
    }
  },

  methods: {
    filterResources: function(resources, options, filterValue) {
      let filter = options.filter;
      if (!filter) {
        return resources;
      }
      if (!filterValue) {
        return resources;
      }

      resources = resources.filter(resource => {
        let field = resource[filter.key];
        if (typeof field === 'object') {
          let temp = filterValue.toUpperCase();
          if (field.value === temp) {
            return true;
          }
        }
        if (typeof field === 'string') {
          if (field === filterValue.toUpperCase()) {
            return true;
          }
        }
        return false;
      });

      return resources;
    },
    sortResources: function(resources, options, sort, sortType, sortDirection) {
      if (!sort) {
        return resources;
      }

      resources = [...resources]; // like arr.slice()

      let res = false;

      let isStr = false;

      if (sortType === 'string') {
        isStr = true;
      }
      if (!sortType) {
        isStr = true;
      }

      if (isStr) {
        resources = resources.sort(function(a, b) {
          let ra = a[sort];
          let rb = b[sort];
          if (sortDirection === 'ASC') {
            return ('' + ra).localeCompare(rb);
          } else {
            return ('' + rb).localeCompare(ra);
          }
        });
      } else {
        resources = resources.sort(function(a, b) {
          res = false;

          let ra = a[sort];
          let rb = b[sort];

          // HACK! Need a more elegant way to solve this
          if (sort === 'notes') {
            ra = a.notes.length;
            rb = b.notes.length;
          }

          if (sortType === 'date') {
            if (sortDirection === 'ASC') {
              res = new Date(rb) - new Date(ra);
            } else {
              res = new Date(ra) - new Date(rb);
            }
          } else if (sortType === 'number') {
            if (sortDirection === 'ASC') {
              res = rb < ra;
            } else {
              res = ra < rb;
            }
          }

          if (isNaN(res)) {
            res = 1;
          }

          if (res === 1) {
            res = true;
          }

          if (res === 0) {
            res = false;
          }

          return res;
        });
      }

      return resources;
    },
    searchResources: function(resources, options, query) {
      return search(resources, options, query);
    },
    paginateResources: function(resources, options, offset, query) {
      let limit = this.options.pagination || null;

      if (!limit) {
        return resources;
      }

      // If someone is searching, disable pagination
      if (query) {
        return resources;
      }

      let startIndex = offset * limit;

      let endIndex = offset * limit + limit;

      resources = resources.slice(startIndex, endIndex);
      return resources;
    },
    onToggle: function(checked) {
      if (checked) {
        this.selectAll();
      } else {
        this.selectNone();
      }
    },
    onToggleFilter: function(key, type) {
      if (!this.sort) {
        this.sort = key;
        this.sortDirection = 'ASC';
      } else {
        if (this.sort === key) {
        } else {
          this.sort = key;
        }

        if (this.sortDirection === 'ASC') {
          this.sortDirection = 'DSC';
        } else {
          this.sortDirection = 'ASC';
        }
      }
      this.sortType = type || 'string';
    },
    onOffset: function(offset) {
      this.offset = offset;
    },

    onQuery: function(query) {
      this.query = query;
    },

    onClick: function(data) {
      if (data.action === 'selected') {
        this.toggleSelection(data.id);
        return;
      }
      this.$emit('click', data);
    },

    selectAll: function() {
      let resources = this.resources;
      let arr = [];

      for (let i = 0; i < resources.length; i++) {
        arr.push(resources[i].id);
      }

      this.selected = arr;
    },

    selectNone: function() {
      this.selected = [];
    },

    toggleSelection: function(id) {
      if (this.selected.length === 0) {
        this.addSelection(id);
        return;
      }
      if (this.selected.includes(id)) {
        this.removeSelection(id);
      } else {
        this.addSelection(id);
      }
    },

    removeSelection: function(id) {
      let index = this.selected.indexOf(id);
      this.selected.splice(index, 1);
    },

    addSelection: function(id) {
      this.selected.push(id);
    }
  }
};
</script>

<style lang="scss">
.c-list {
  margin-top: var(--space-lg);
  position: relative;

  &__outer-wrapper {
    position: relative;
    width: 100%;

    &.full-container-width {
      width: calc(100% + var(--space-xl) * 2);
      margin-left: calc(var(--space-xl) * -1);
    }

    &::before,
    &::after {
      background-repeat: repeat-y;
      background-size: 100%;
      content: '';
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      transform: opacity 0.125s ease-in;
      width: 1rem;
    }

    &::before {
      background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.125) 100%
      );
      left: 0;
    }

    &::after {
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.125) 100%
      );
      right: 0;
    }

    &.overflow-left::before {
      opacity: 1;
    }

    &.overflow-right::after {
      opacity: 1;
    }
  }

  &__wrapper {
    overflow: scroll;
    position: relative;
    width: 100%;

    .c-list-row {
      &:nth-child(2n) {
        background-color: var(--color-bg-3); // rgba(0, 0, 0, 0.05);
      }

      &:hover {
        background-color: var(--color-bg-4);
      }
    }
  }

  &__content {
    display: table;
    //margin: 0 var(--space-lg);
    //width: calc(100% - calc(var(--space-lg) * 2));
    width: calc(100% + var(--space-lg) / 2);
  }
}
</style>
