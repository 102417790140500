<template>
  <div class="c-item-user">
    {{ name }}
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    resource: {
      type: Object,
      default: function() {
        return {};
      }
    },
    column: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  computed: {
    name: function() {
      const resource = this.resource;
      if (resource.location && resource.location.organisation) {
        return resource.location.organisation.name;
      }
      if (resource.organisation) {
        return resource.organisation.name;
      }

      return '';
    }
  },

  methods: {
    onClick: function() {
      if (this.column.clickable) {
        this.$emit('click', 'view', this.resource.id, this.column.key);
      }
    }
  }
};
</script>

<style lang="scss">
.c-item-user {
  font-size: var(--font-size-sm);

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
