<template>
  <div class="c-lead-info-window">
    <InfoWindow ref="infoWindow" :sections="sections" @onClose="onClose">
      <template v-slot:header v-if="currentLead">
        <Gravatar
          v-if="currentLead.email"
          :email="currentLead.email"
        ></Gravatar>
        <h2>{{ currentLead.firstName }} {{ currentLead.lastName }}</h2>
      </template>
      <!-- <template v-slot:section v-if="currentLead && currentLead.notes">
        <div class="c-info-window-section">
          <div class="c-info-window-section__header">
            Notes
          </div>
          <div class="c-info-window-section__body">
            <NotesCompact :resources="currentLead.notes"></NotesCompact>
          </div>
        </div>
      </template> -->
    </InfoWindow>
  </div>
</template>

<script>
import InfoWindow from '@/components/global/info-window';
import NotesCompact from '@/components/notes/compact';

import Gravatar from 'vue-gravatar';

export default {
  components: {
    InfoWindow,
    NotesCompact,
    Gravatar
  },

  props: {
    id: {
      type: Number,
      default: null
    }
  },

  watch: {
    currentLead: function() {
      if (this.currentLead) {
        this.$refs.infoWindow.activate();
      } else {
        this.$refs.infoWindow.deactivate();
      }
    }
  },

  computed: {
    active: function() {
      if (this.id) {
        return true;
      } else {
        return false;
      }
    },
    currentLead: function() {
      if (!this.id) {
        return;
      }
      let lead = this.$store.getters['leads/one'](this.id);
      return lead;
    },
    organisation: function() {
      if (!this.currentLead) {
        return null;
      }
      console.log(this.currentLead.organisationId);
      let organisation = this.$store.getters['organisations/one'](
        this.currentLead.organisationId
      );

      return organisation;
    },
    sections: function() {
      let lead = this.currentLead;
      let organisation = this.organisation;

      if (!lead) {
        return [];
      }

      let sections = [];

      let items = [];
      let notes = [];

      items.push({
        label: 'Email Address',
        value: lead.email
      });
      items.push({
        label: 'Phone number',
        value: lead.phone
      });
      if (organisation) {
        items.push({
          label: 'Organisation name',
          value: organisation.name
        });
      }
      items.push({
        label: 'Role/title',
        value: lead.role
      });
      items.push({
        label: 'Mobile number',
        value: lead.mobile
      });
      items.push({
        label: 'Email address',
        value: lead.email
      });

      sections.push({
        name: 'Contact Details',
        items: items
      });

      // for (let i = 0; i < lead.notes.length; i++) {
      //   notes.push({
      //     label: '',
      //     type: 'note',
      //     value: lead.notes[i]
      //   });
      // }

      // sections.push({
      //   name: 'Notes',
      //   items: notes
      // });

      // sections.push({
      //   name: 'Fields',
      //   items: lead.fields
      // });

      return sections;
    }
  },

  methods: {
    onClose: function() {
      this.$emit('onClose');
    }
  }
};
</script>

<style lang="scss">
.c-lead-info-window {
  h2 {
    font-size: var(--font-size-xl);
    font-weight: 700;
  }
}
</style>
