function generateSection(resource, title = '', ignoreKeys = []) {
  let fields = [];
  for (const p in resource) {
    if (ignoreKeys.includes(p)) {
      continue;
    }
    fields.push({
      key: p,
      value: resource[p],
      type: typeof resource[p]
    });
  }

  let section = {
    title: title,
    fields: fields
  };

  return section;
}

export default generateSection;
