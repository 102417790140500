<template>
  <div id="app" data-theme="default">
    <Layout v-if="init"></Layout>

    <ModalLogin ref="modalLogin"></ModalLogin>

    <Toast></Toast>

    <InfoWindowHandler></InfoWindowHandler>

    <Message></Message>
  </div>
</template>

<script>
import router from './router';
import store from './store';

import Layout from '@/components/global/layout';

import ModalLogin from '@/components/global/modal-login';

import Toast from '@/components/global/toast';

import InfoWindowHandler from '@/components/global/info-window/handler';

import Message from '@/components/global/message';

import Vue from 'vue';
import vSelect from 'vue-select';
import Toasted from 'vue-toasted';
import VHighlightJS from '@/lib/highlighter.js';
import VCalendar from 'v-calendar';

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc'
});
Vue.use(VHighlightJS);
Vue.use(Toasted);
Vue.component('v-select', vSelect);
Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = event => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});

export default {
  store,
  router,

  data: function() {
    return {};
  },

  components: {
    Layout,
    ModalLogin,
    Toast,
    InfoWindowHandler,
    Message
  },

  watch: {
    init: {
      handler: function() {
        if (this.init) {
          this.checkAuth();
        }
      },
      immediate: true
    },
    isAuth: function() {
      if (!this.isAuth) {
        this.$refs.modalLogin.activate();
      }
    }
  },

  computed: {
    init: function() {
      return this.$store.getters['config/init'];
    },
    isAuth: function() {
      return this.$store.getters['users/isAuth'];
    }
  },

  methods: {
    checkAuth: function() {
      let isAuth = this.$store.getters['users/isAuth'];
      if (!isAuth && this.$refs.modalLogin) {
        this.$refs.modalLogin.activate();
      }
    }
  },

  created: function() {
    this.$store.dispatch('init');
  },
  mounted: function() {},
  beforeDestroy: function() {}
};
</script>

<style lang="scss">
@import 'assets/styles/main.scss';

#app {
  @include cssVars();

  background-color: var(--color-bg-2);
  color: var(--font-color);
  font-family: var(--font-family);
  font-size: var(--font-size-md);
  font-weight: 400;

  line-height: 1.5;

  min-height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  // A really light tint
  &:after {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-bg-2);
    background: linear-gradient(
      -78deg,
      hsla(var(--s), 85%, 62%, 1) 0%,
      hsla(var(--s), 70%, 50%, 0) 40%
    );
    opacity: 0.05;
    pointer-events: none;
  }
}
</style>
