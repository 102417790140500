<template>
  <div class="c-input-error">
    <span>{{ message }}</span>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss">
.c-input-error {
  margin: var(--margin-lg) 0 0;
  font-size: var(--font-size-sm);
  font-weight: 500;
  color: var(--danger-color);
}
</style>
