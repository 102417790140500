<template>
  <div
    :class="['form-group c-input-relation', { 'form-group--inline': inline }]"
  >
    <label class="form__label" :for="slug">{{ label }}</label>
    <v-select
      :disabled="readonly === true"
      :value="selectValue"
      :options="computedOptions"
      label="label"
      @input="onChange"
    ></v-select>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
  </div>
</template>

<script>
import InputError from './input-error';
import InputDescription from './input-description';
import slugify from '@/lib/slugify';

export default {
  components: {
    InputError,
    InputDescription
  },

  data: function() {
    return {
      selectValue: null
    };
  },

  watch: {
    value: {
      handler: function() {
        this.setValue(this.value);
      },
      immediate: true
    }
  },

  props: {
    label: {
      type: String,
      default: 'Label'
    },
    value: {},
    inline: {
      type: Boolean,
      default: false
    },
    relation: {
      type: String,
      default: ''
    },
    optionKey: {
      type: String,
      default: 'id'
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    returnId: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: ''
    },
    filter: {
      type: Function,
      default: function(x) {
        return x;
      }
    }
  },

  computed: {
    options: function() {
      console.log(this.$store.getters);
      let options = this.$store.getters[this.relation];

      options = this.filter(options);

      return options;
    },
    computedOptions: function() {
      let options = this.options;

      if (!options) {
        throw new Error(`Relation doesn't exist`);
      }

      if (options.length === 0) {
        return [];
      }

      let labelKey = this.optionKey;

      let newOptions = [];

      let value = null;

      for (let i = 0; i < options.length; i++) {
        let obj = {
          label: options[i][labelKey],
          resource: options[i],
          value: options[i].id
        };

        newOptions.push(obj);
      }

      return newOptions;
    },
    slug: function() {
      return slugify(this.label);
    }
  },

  methods: {
    setValue: function(val) {
      if (!val) {
        return;
      }
      // don't allow objects as input if returnId is false
      if (typeof val !== 'object' && !this.returnId) {
        return;
      }

      let id = null;
      if (this.returnId) {
        id = val;
      } else {
        id = val.id;
      }
      let options = this.options;
      let option = null;

      for (let i = 0; i < options.length; i++) {
        if (options[i].id === id) {
          option = options[i];
          break;
        }
      }

      if (!option) {
        return;
      }

      let obj = {
        label: option[this.optionKey],
        resource: option,
        value: option.id
      };

      this.selectValue = obj;

      return;
    },
    formatOutput: function(option) {
      let returnId = this.returnId;
      if (!option) {
        return null;
      }

      if (returnId) {
        return option.resource.id;
      }

      return option.resource;
    },
    onChange: function(data) {
      this.selectValue = data;
      data = this.formatOutput(data);
      this.$emit('input', data);
    }
  }
};
</script>

<style lang="scss">
.c-input-relation {
}
</style>
