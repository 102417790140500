<template>
  <div class="c-info-window-item c-info-window-item--note">
    <dl class="c-info-window-list">
      <dt class="c-info-window-list__title">Date added</dt>
      <dd class="c-info-window-list__content">
        <DateComponent :date="item.value.createdAt"></DateComponent>
      </dd>
      <dt class="c-info-window-list__title">By</dt>
      <dd class="c-info-window-list__content">Steph Ghersetti</dd>
    </dl>
    <article v-html="item.value.html"></article>
  </div>
</template>

<script>
import DateComponent from '@/components/global/date';
import { Fragment } from 'vue-fragment';

export default {
  components: {
    DateComponent,
    Fragment
  },

  props: {
    item: {
      type: Object,
      default: function() {
        return {};
      }
    }
  }
};
</script>

<style lang="scss">
.c-info-window-item--note {
  & + & {
    border-top: 1px solid var(--color-grey-lighter);
    margin-top: var(--space-md);
    padding-top: var(--space-sm);
  }
}
</style>
