<template>
  <div class="form-group c-input-users">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <v-select
      :value="selectValue"
      :options="computedOptions"
      label="label"
      @input="onChange"
      :multiple="false"
    >
      <template #selected-option="{resource}">
        <Gravatar :email="resource.email" default-img="monsterid"></Gravatar>
        <span>{{ resource.firstName }} {{ resource.lastName }}</span>
        <em>{{ resource.email }}</em>
      </template>
      <template #option="{ resource }">
        <Gravatar :email="resource.email" default-img="monsterid"></Gravatar>
        <span>{{ resource.firstName }} {{ resource.lastName }}</span>
        <em>{{ resource.email }}</em>
      </template>
    </v-select>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
  </div>
</template>

<script>
import InputError from './input-error';
import InputDescription from './input-description';
import slugify from '@/lib/slugify';

import Gravatar from 'vue-gravatar';

export default {
  components: {
    InputDescription,
    InputError,
    Gravatar
  },

  data: function() {
    return {
      selectValue: null
    };
  },

  watch: {
    value: {
      handler: function() {
        // !this.selectValue so it stops re-renders
        if (this.value && !this.selectValue) {
          let user = this.value;
          if (typeof this.value === 'number') {
            user = this.$store.getters['users/one'](this.value);
          }
          let obj = {
            label: `${user.firstName} ${user.lastName}`,
            resource: user,
            value: user.id
          };
          this.selectValue = obj;
        }
      },
      immediate: true
    }
  },

  props: {
    label: {
      type: String,
      default: ''
    },
    value: {},
    placeholder: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: null
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },

  computed: {
    users: function() {
      return this.$store.getters['users/filtered'];
    },
    computedOptions: function() {
      let users = this.users;

      let options = [];

      for (let i = 0; i < users.length; i++) {
        let obj = {
          label: `${users[i].firstName} ${users[i].lastName}`,
          resource: users[i],
          value: users[i].id
        };
        options.push(obj);
      }

      return options;
    },
    slug: function() {
      return slugify(this.label);
    }
  },

  methods: {
    onChange: function(obj) {
      let value = null;
      if (obj && obj.resource) {
        value = obj;
      }
      this.selectValue = value;

      let output = null;

      if (typeof value === 'number') {
        output = value;
      } else {
        output = value.resource.id;
      }

      this.$emit('input', output);
    }
  }
};
</script>

<style lang="scss">
.c-input-users {
  .v-select {
    cursor: pointer;
    height: 42px;
  }
}
</style>
