<template>
  <div class="form-group c-input-editor">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <div :class="['c-editor']" ref="editor"></div>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
  </div>
</template>

<script>
import InputError from './input-error';
import slugify from '@/lib/slugify';

import { Editor } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';

import { Node, mergeAttributes } from '@tiptap/core';

let Document = Node.create({
  name: 'doc',
  topNode: true,
  content: 'block+'
});
let Text = Node.create({
  name: 'text',
  group: 'inline'
});
let Paragraph = Node.create({
  name: 'paragraph',
  priority: 1000,
  addOptions() {
    return {
      HTMLAttributes: {}
    };
  },
  group: 'block',
  content: 'inline*',
  parseHTML() {
    return [{ tag: 'p' }];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'p',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0
    ];
  },
  addCommands() {
    return {
      setParagraph: () => ({ commands }) => {
        return commands.setNode('paragraph');
      }
    };
  },
  addKeyboardShortcuts() {
    return {
      'Mod-Alt-0': () => this.editor.commands.setParagraph()
    };
  }
});

export default {
  components: {
    InputError
  },

  props: {
    label: {
      type: String,
      default: ''
    },
    value: {},
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },

  watch: {
    value: function() {
      //this.updateContent();
    }
  },

  computed: {
    slug: function() {
      return slugify(this.label);
    }
  },

  methods: {
    setup: function() {
      this.editor = new Editor({
        element: this.$refs.editor,
        extensions: [Document, Text, Paragraph],
        content: '',
        onUpdate: () => {
          this.onUpdate();
        },
        onFocus: () => {
          this.onFocus();
        },
        onBlur: () => {
          this.onBlur();
        }
      });
      this.updateContent();
    },

    onUpdate: function() {
      let html = this.editor.getHTML();
      this.$emit('input', html);
    },
    onFocus: function() {},
    onBlur: function() {},

    updateContent: function() {
      if (this.value) {
        this.editor.commands.setContent(this.value);
      }
    },

    onChange: function(e) {
      let value = e.target.value;

      this.$emit('input', value);
    }
  },

  mounted: function() {
    this.setup();
  }
};
</script>

<style lang="scss">
.c-input-editor {
  width: 40rem;

  .ProseMirror {
    appearance: none;
    background-color: var(--g1);
    border: 1px solid transparent;
    border-radius: var(--border-radius-md);
    box-shadow: 0px 0px 8px #00000033;
    display: inline-block;
    font-size: var(--font-size-md);
    line-height: 1.4;
    margin-top: var(--space-xs);
    padding: calc(var(--space-xs) * 1.5) var(--space-sm);
    text-align: left;
    transition: all 50ms ease-out;
    width: 100%;

    p {
      margin: 0;

      + p {
        margin-top: var(--space-sm);
      }
    }
  }
}
</style>
