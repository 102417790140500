<template>
  <div class="c-organisation-report">
    <h1>
      Organisation reports
    </h1>

    <article class="article-text">
      <p>
        All organisation based metrics can be exported from here.
      </p>
    </article>

    <Header></Header>
  </div>
</template>

<script>
import Header from './header';
import Item from './item';
import Button from '@/components/global/button';

export default {
  components: {
    Header,
    Item,
    Button
  },

  data: function() {
    return {
      isProcessing: false
    };
  },

  computed: {},

  methods: {},

  created: function() {
    this.$store.dispatch('organisationLogs/populateLogs');
  }
};
</script>

<style lang="scss">
.c-organisation-report {
  &__grid {
    display: grid;
    margin: var(--space-sm) 0;
    grid-template-columns: 1fr;
  }
}
</style>
