<template>
  <div class="c-order-info-window">
    <InfoWindow ref="infoWindow" :sections="sections" @close="onClose">
      <template v-slot:header v-if="currentLead">
        <h2>{{ currentLead.firstName }} {{ currentLead.lastName }}</h2>
      </template>
    </InfoWindow>
  </div>
</template>

<script>
import InfoWindow from '@/components/global/info-window';
export default {
  components: {
    InfoWindow
  },

  props: {
    id: {}
  },

  watch: {
    currentLead: function() {
      if (this.currentLead) {
        this.$refs.infoWindow.activate();
      }
    }
  },

  computed: {
    active: function() {
      if (this.id) {
        return true;
      } else {
        return false;
      }
    },
    currentLead: function() {
      if (!this.id) {
        return;
      }
      let lead = this.$store.getters['orders/one'](this.id);
      return lead;
    },
    organization: function() {
      if (!this.currentLead) {
        return null;
      }
      let organization = this.$store.getters['orders/all'](
        this.currentLead.organizationId
      );
    },
    sections: function() {
      let lead = this.currentLead;
      let organization = this.organization;

      if (!lead) {
        return [];
      }

      let sections = [];

      let items = [];
      let notes = [];

      items.push({
        label: 'Email Address',
        value: lead.email
      });
      items.push({
        label: 'Phone number',
        value: lead.phone
      });
      items.push({
        label: 'Organisation name',
        value: 'NA'
      });
      items.push({
        label: 'Role/title',
        value: lead.role
      });

      sections.push({
        name: 'Contact Details',
        items: items
      });

      for (let i = 0; i < lead.notes.length; i++) {
        notes.push({
          label: '',
          type: 'note',
          value: lead.notes[i]
        });
      }

      sections.push({
        name: 'Notes',
        items: notes
      });

      return sections;
    }
  },

  methods: {
    onClose: function() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss">
.c-order-info-window {
  h2 {
    font-size: var(--font-size-xl);
    font-weight: 700;
  }
}
</style>
