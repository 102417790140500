<template>
  <div class="c-info-window-handler">
    <InfoWindowLead :id="setId('lead')" @onClose="closeWindow"></InfoWindowLead>
    <InfoWindowOrganisation
      :id="setId('organisation')"
      @onClose="closeWindow"
    ></InfoWindowOrganisation>
    <InfoWindowLocation
      :id="setId('location')"
      @onClose="closeWindow"
    ></InfoWindowLocation>
    <InfoWindowOrder
      :id="setId('order')"
      @onClose="closeWindow"
    ></InfoWindowOrder>
  </div>
</template>

<script>
import InfoWindowLead from '@/components/people/info-window-lead';
import InfoWindowOrganisation from '@/components/organisations/info-window-organisation';
import InfoWindowOrder from '@/components/orders/info-window-order';
import InfoWindowLocation from '@/components/locations/info-window-location';

export default {
  components: {
    InfoWindowLead,
    InfoWindowOrganisation,
    InfoWindowOrder,
    InfoWindowLocation
  },

  watch: {
    infoWindowTrigger: function() {
      this.onUpdate();
    }
  },

  computed: {
    infoWindow: function() {
      let infoWindow = this.$store.getters['app/infoWindow'];
      return infoWindow;
    },
    infoWindowTrigger: function() {
      return this.$store.getters['app/infoWindowTrigger'];
    }
  },

  methods: {
    onUpdate: function() {},
    closeWindow: function() {
      this.$store.dispatch('app/setInfoWindow', { type: null, id: null });
    },
    setId: function(type) {
      let infoWindow = this.infoWindow;
      if (!infoWindow.id) {
        return null;
      }
      if (infoWindow.type !== type) {
        return null;
      }
      return infoWindow.id;
    }
  }
};
</script>

<style></style>
