<template>
  <div class="c-locations-create">
    <BreadCrumb></BreadCrumb>
    <h1>
      Update Location
    </h1>
    <FormUpdate :resource="resource"></FormUpdate>
  </div>
</template>

<script>
import BreadCrumb from '@/components/global/breadcrumb';
import FormUpdate from './form-update';

export default {
  components: {
    BreadCrumb,
    FormUpdate
  },

  computed: {
    resource: function() {
      let id = this.$route.params.id;
      let resource = this.$store.getters['locations/one'](id);
      return resource;
    }
  }
};
</script>

<style lang="scss">
.c-locations-create {
}
</style>
