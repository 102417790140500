<template>
  <div
    :class="['form-group c-input-dropdown', { 'form-group--inline': inline }]"
  >
    <label class="form__label" :for="slug">{{ label }}</label>
    <v-select :options="options" @input="onChange"></v-select>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
  </div>
</template>

<script>
import InputError from './input-error';
import slugify from '@/lib/slugify';

export default {
  components: {
    InputError
  },

  props: {
    label: {
      type: String,
      default: 'Label'
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    },
    value: {},
    inline: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },

  computed: {
    slug: function() {
      return slugify(this.label);
    }
  },

  methods: {
    onChange: function(data) {
      if (!data) {
        this.$emit('input', null);
        return;
      }

      if (typeof data === 'string') {
        this.$emit('input', data);
        return;
      }

      this.$emit('input', data.value);
    }
  }
};
</script>

<style lang="scss">
.c-input-dropdown {
  .v-select {
    appearance: none;
    background-color: var(--g1);
    border: 1px solid transparent;
    border-radius: var(--border-radius-md);
    box-shadow: 0px 0px 8px #00000033;
    display: inline-block;
    font-size: var(--font-size-md);
    margin-top: var(--space-xs);
    transition: all 50ms ease-out;
    padding: var(--space-xs) var(--space-sm);
    width: 100%;

    &.vs--open {
      box-shadow: 0 0 0 3px rgba(70, 132, 248, 0.6) !important;
    }

    .vs__selected-options {
      padding: 0;
    }

    .vs__selected {
      border-radius: 0;
      line-height: 1.4;
      margin: 0 var(--space-xs) 0 0;
      padding: 0;
    }

    .vs__dropdown-toggle {
      border: none;
      border-radius: 0;
      padding: 0;
    }

    .vs__search {
      appearance: none;
      border: 0;
      font-size: var(--font-size-md);
      line-height: 1.5;
      margin: 0;
      padding: 0;
    }

    .vs__actions {
      padding: 0;
    }

    &:active,
    &:focus {
      border-color: transparent;
    }
    &:focus {
      outline: none;
    }

    ul {
    }
  }
}
</style>
