<template>
  <div class="c-info-window-header">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style>
.c-info-window-header {
  margin: var(--space-xl) var(--space-md) var(--space-lg);
}
</style>
