<template>
  <section
    class="c-user"
    v-bind:class="{ 'is-active': isActive }"
    v-click-outside="onClickOutside"
  >
    <button class="c-user__button" @click="onClick()">
      <Gravatar :email="user.email" default-img="monsterid"></Gravatar>
      <span class="c-user__name">{{ name }}</span>
    </button>
    <div class="c-user__panel">
      <a class="c-user__signout" href="#" @click.prevent="onSignout"
        >Sign out</a
      >
      <span class="c-user__name">{{ name }}</span>
      <span class="c-user__team" v-if="team">{{ team.title }}</span>
      <span class="c-user__email">{{ email }}</span>
      <router-link class="c-user__edit" to="edit-account"
        >Edit account</router-link
      >
    </div>
  </section>
</template>

<script>
import Gravatar from 'vue-gravatar';

export default {
  components: {
    Gravatar
  },

  data() {
    return {
      isActive: false
    };
  },

  computed: {
    user: function() {
      return this.$store.getters['users/user'];
    },
    initials: function() {
      let user = this.user;

      if (!user || !user.id) {
        return '';
      }

      return `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
    },
    name: function() {
      let user = this.user;

      if (!user || !user.id) {
        return '';
      }

      return `${user.firstName} ${user.lastName}`;
    },
    email: function() {
      let user = this.user;

      if (!user || !user.id) {
        return '';
      }

      return user.email;
    },
    team: function() {
      let user = this.user;

      if (!user || !user.id) {
        return null;
      }

      // need to get the team object here
      return user.teamId;
    }
  },

  methods: {
    onClick: function(e) {
      this.isActive = !this.isActive;
    },
    onClickOutside: function(e) {
      this.isActive = false;
    },
    onSignout: function() {
      this.$store.dispatch('users/logout');
    }
  }
};
</script>

<style lang="scss">
.c-user {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--z-2);

  a {
    text-decoration: underline;
    text-underline-offset: 2px;

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
}

.c-user__button {
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  height: var(--space-xl);
  padding: 0 var(--space-md);

  img {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: var(--margin);
    object-fit: cover;
    object-position: center center;
    border-radius: 16px;
  }

  &:hover,
  &:active {
    span {
      text-decoration: underline;
      text-underline-offset: 2px;
    }
  }

  .c-user.is-active & {
    background-color: var(--primary);
    box-shadow: 0px 0px 14px #00000029;
    color: var(--color-white);
  }

  &::after {
    background-image: url('/images/icon-chevron-down.svg');
    background-position: center 60%;
    background-repeat: no-repeat;
    background-size: 80%;
    content: '';
    display: inline-block;
    height: var(--space-sm);
    margin-left: var(--space-xs);
    width: var(--space-sm);

    .c-user.is-active & {
      background-image: url('/images/icon-chevron-down-white.svg');
    }
  }
}

.c-user__image {
  align-items: center;
  background-color: var(--color-grey-light);
  border-radius: 50%;
  color: var(--color-white);
  display: flex;
  font-size: var(--font-size-sm);
  font-weight: 500;
  height: calc(var(--space-lg) * 1.125);
  justify-content: center;
  letter-spacing: 1px;
  text-decoration: none;
  margin-right: var(--space-sm);
  width: calc(var(--space-lg) * 1.125);

  .c-user.is-active & {
    background-color: var(--color-white);
    color: var(--primary);
  }
}

.vue-avatar--wrapper {
  margin-right: var(--space-sm);
}

.c-user__panel {
  background-color: var(--color-white);
  border-radius: var(--border-radius-lg);
  border-top-right-radius: 0;
  box-shadow: 0px 0px 14px #00000029;
  display: none;
  flex-direction: column;
  padding: calc(var(--space-lg) * 1.25) calc(var(--space-xl) * 2)
    calc(var(--space-lg) * 1.25) var(--space-xl);
  position: absolute;
  top: var(--space-xl);

  .c-user.is-active & {
    display: flex;
  }

  .c-user__signout {
    margin: var(--space-sm) var(--space-md) 0 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .c-user__name {
    font-weight: 700;
    margin-bottom: var(--space-xxs);
  }

  .c-user__edit {
    align-items: center;
    color: var(--color-black);
    display: flex;
    margin-top: var(--space-xs);

    &::before {
      background-image: url('/images/icon-pencil.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 75%;
      content: '';
      display: inline-block;
      height: calc(var(--space-sm) * 1.5);
      margin-right: var(--space-xs);
      width: calc(var(--space-sm) * 1.5);
    }

    &:hover,
    &:active {
      color: var(--color-grey);
    }
  }
}
</style>
