<template>
  <div :class="['c-item-badge', { active: active }]">
    <span>
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    resource: {
      type: Object,
      default: function() {
        return {};
      }
    },
    column: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  computed: {
    text: function() {
      if (this.column.formatter) {
        return this.column.formatter(this.resource, this.column);
      } else {
        return this.resource[column.key];
      }
    },
    active: function() {
      if (this.text) {
        let temp = parseInt(this.text);

        if (!isNaN(temp) && temp > 0) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    }
  }
};
</script>

<style lang="scss">
.c-item-badge {
  display: inline-flex;
  align-items: center;
  padding: var(--margin) var(--space-xs);

  font-weight: 500;
  font-family: var(--font-family-monospace);
  font-size: var(--font-size-sm);
  line-height: 1;
  color: rgba(0, 0, 0, 0.75);

  border: var(--color-grey-light) solid 1px;
  border-radius: 30px;

  &.active {
    color: var(--primary);
    border-color: var(--primary);
  }
}
</style>
