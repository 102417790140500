<template>
  <div class="c-orders">
    <Dashboard>
      <DashboardHeader title="Manage Orders">
        <router-link
          v-if="!isReadOnly"
          to="/orders/orders/create"
          class="btn btn-primary"
          >Add New</router-link
        >
      </DashboardHeader>
      <DashboardBody>
        <List
          :options="listOptions"
          :resources="resources"
          @click="onClick"
          :columns="columns"
        ></List>
      </DashboardBody>
    </Dashboard>
    <LeadInfoOrder
      :leadId="currentLeadId"
      @close="onInfoWindowClose"
    ></LeadInfoOrder>
  </div>
</template>

<script>
import Dashboard from '@/components/dashboard';
import DashboardHeader from '@/components/dashboard/header';
import DashboardBody from '@/components/dashboard/body';

import Button from '@/components/global/button';

import List from '@/components/list';

import LeadInfoOrder from './info-window-order';

export default {
  components: {
    Dashboard,
    DashboardHeader,
    DashboardBody,

    Button,

    List,

    LeadInfoOrder
  },

  data: function() {
    return {
      currentLeadId: null,
      listOptions: {
        fullContainerWidth: true,
        pagination: 20,
        search: ['bundleId', 'id', 'billingMethod', 'status']
      }
    };
  },

  computed: {
    isReadOnly: function() {
      return this.$store.getters['users/isReadOnly'];
    },
    resources: function() {
      return this.$store.getters['orders/filtered'];
    },
    columns: function() {
      return [
        {
          label: 'ID',
          key: 'id',
          renderer: 'ItemId'
        },
        {
          label: 'Bundle Id',
          key: 'bundleId'
        },
        {
          label: 'Assigned by',
          key: 'userId',
          renderer: 'ItemUser',
          sortable: true
        },
        {
          label: 'Company',
          renderer: 'ItemOrganisation',
          sortable: false
        },
        {
          label: 'Billing flow',
          key: 'billingMethod',
          renderer: 'ItemEnum',
          enum: 'billingMethod'
        },
        {
          label: 'Status',
          key: 'status',
          renderer: 'ItemStage',
          sortable: true
        },
        {
          label: 'Date created',
          key: 'createdAt',
          sortable: true,
          sortType: 'date',
          renderer: 'ItemDate'
        },
        {
          label: 'Action',
          key: '_action',
          actions: ['V', 'E', 'D']
        }
      ];
    }
  },

  methods: {
    onClick: function(payload) {
      if (payload.action && payload.action === 'view') {
        this.currentLeadId = payload.id;
      }
      if (payload.action && payload.action === 'edit') {
        this.$router.push({
          name: 'OrdersUpdate',
          params: { id: payload.id }
        });
      }
      if (payload.action && payload.action === 'delete') {
        this.$store.dispatch('orders/delete', payload.id);

        // this.$router.push({
        //   name: 'OrdersUpdate',
        //   params: { id: payload.id }
        // });
      }
    },
    onInfoWindowClose: function() {
      this.currentLeadId = null;
    }
  }
};
</script>

<style></style>
