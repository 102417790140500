<template>
  <div :class="['c-editor', { nostyle: disableStyle === true }]" ref="editor">
    <!-- <span class="c-editor__enter"> </span> -->
  </div>
</template>

<script>
import { Editor } from '@tiptap/core';
import Placeholder from '@tiptap/extension-placeholder';
import ExtensionLink from '@tiptap/extension-link';

import { Node, mergeAttributes } from '@tiptap/core';

import Color from './extension-color';
import Markdown from './extension-markdown';
//import ExtensionDate from './extension-date';

let Document = Node.create({
  name: 'doc',
  topNode: true,
  content: 'block+'
});

let Text = Node.create({
  name: 'text',
  group: 'inline'
});

let Paragraph = Node.create({
  name: 'paragraph',
  priority: 1000,
  addOptions() {
    return {
      HTMLAttributes: {}
    };
  },
  group: 'block',
  content: 'inline*',
  parseHTML() {
    return [{ tag: 'p' }];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'p',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0
    ];
  },
  addCommands() {
    return {
      setParagraph: () => ({ commands }) => {
        return commands.setNode('paragraph');
      }
    };
  },
  addKeyboardShortcuts() {
    return {
      'Mod-Alt-0': () => this.editor.commands.setParagraph()
    };
  }
});

export default {
  data: function() {
    return {
      editor: null,
      disableStyle: false
    };
  },

  props: {
    value: {},
    placeholder: {
      type: String,
      default: ''
    },
    max: {
      type: Number,
      default: -1
    }
  },

  watch: {
    value: {
      handler: function() {
        if (this.value === this.editor.getHTML()) {
          return;
        }
        this.updateContent();
      },
      immediate: false
    }
  },

  computed: {},

  methods: {
    setup: function() {
      this.editor = new Editor({
        element: this.$refs.editor,
        extensions: [
          Document,
          Text,
          Paragraph,
          Color,
          Markdown,
          Placeholder.configure({
            placeholder: this.placeholder
          })
        ],
        content: '',
        onUpdate: () => {
          this.onUpdate();
        },
        onFocus: () => {
          this.onFocus();
        },
        onBlur: () => {
          this.onBlur();
        }
      });
      this.updateContent();
    },

    onUpdate: function() {
      this.$emit('input', this.editor.getHTML());
    },

    onFocus: function() {
      this.$emit('focus');
    },

    onBlur: function() {
      this.$emit('blur');
    },

    updateContent: function() {
      this.editor.commands.setContent(this.value || '');
    },

    focus: function() {
      this.editor.commands.focus();
    }
  },

  mounted: function() {
    this.setup();
    this.updateContent();
  }
};
</script>

<style lang="scss">
.c-editor {
  position: relative;
  &:not(.nostyle) {
    .ProseMirror {
      height: 100%;
      padding: var(--margin-lg) var(--margin-lg);

      font-size: var(--font-size-sm);

      border: 1px solid var(--color-input-border);
      border-radius: var(--border-input);
      box-shadow: var(--box-shadow-input);
      border-radius: var(--border-input);

      &:focus-visible {
        outline: none;
        @include focus();
      }
    }

    .ProseMirror p.is-editor-empty:first-child::before {
      content: 'Say something..'; //attr(data-placeholder);
      float: left;
      color: #ced4da;
      pointer-events: none;
      height: 0;
    }
  }

  .decoration-color {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: var(--margin);
    background-color: var(--color);
    border-radius: 50%;
    border: rgba(0, 0, 0, 0.5) solid 2px;
  }

  .decoration-date {
    display: inline-block;
    color: var(--s2);
    padding: 0 var(--margin-sm);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: var(--border-radius-sm);
    margin-right: var(--margin);
  }

  .text-line {
    color: var(--s2);
    text-shadow: 0 0 16px var(--s2);
  }

  .text-header {
    color: var(--s2);
    text-shadow: 0 0 32px var(--s2);
  }

  .text-list-item {
    color: var(--s2);
    text-shadow: 0 0 8px var(--s2);
  }

  &__enter {
    position: absolute;
    z-index: 1;
    top: 6px;
    right: 6px;
    width: 24px;
    height: 24px;
    background-color: var(--primary);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      stroke-width: 3px;
    }

    ~ .c-editor {
      .ProseMirror {
        padding-right: 32px;
      }
    }

    &:hover,
    &:active,
    &:focus {
      background-color: var(--primary-light);
    }
  }
}
</style>
