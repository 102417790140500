<template>
  <div class="c-leads">
    <Dashboard>
      <DashboardHeader title="Manage Products">
        <!-- <router-link to="/products/create" class="btn btn-primary"
          >Add New</router-link
        > -->
      </DashboardHeader>
      <DashboardBody>
        <List
          :options="listOptions"
          :resources="leads"
          @click="onClick"
          :columns="columns"
        ></List>
      </DashboardBody>
    </Dashboard>
    <LeadInfoWindow
      :leadId="currentLeadId"
      @close="onInfoWindowClose"
    ></LeadInfoWindow>
  </div>
</template>

<script>
import Dashboard from '@/components/dashboard';
import DashboardHeader from '@/components/dashboard/header';
import DashboardBody from '@/components/dashboard/body';

import Button from '@/components/global/button';

import List from '@/components/list';

import LeadInfoWindow from './info-window-product';

export default {
  components: {
    Dashboard,
    DashboardHeader,
    DashboardBody,

    Button,

    List,

    LeadInfoWindow
  },

  data: function() {
    return {
      currentLeadId: null,
      listOptions: {
        fullContainerWidth: true,
        pagination: 100,
        search: ['name', 'sku']
      }
    };
  },

  computed: {
    leads: function() {
      return this.$store.getters['products/all'];
    },
    columns: function() {
      return [
        {
          label: 'ID',
          key: 'id',
          renderer: 'ItemId'
        },
        {
          label: 'Name',
          key: 'name'
        },
        {
          label: 'Price',
          key: 'price',
          formatter: (resource, column) => {
            return `$${resource.price}`;
          }
        },
        {
          label: `Winthrop's cut`,
          key: 'winthrop_price',
          formatter: (resource, column) => {
            return `$${resource.winthrop_cut || 0}`;
          }
        },
        {
          label: `Cope's cut`,
          key: 'cope_cut',
          formatter: (resource, column) => {
            return `$${resource.cope_cut || 0}`;
          }
        },
        {
          label: 'Billing cycle',
          key: 'billingPeriod',
          renderer: 'ItemEnum'
        },
        {
          label: 'Billing duration(in months)',
          key: 'billingDuration'
        },
        {
          label: 'SKU',
          key: 'sku',
          sortable: true,
          renderer: 'ItemEnum'
        },
        {
          label: 'Account Code',
          key: 'accountCode',
          renderer: 'ItemEnum'
        }
      ];
    }
  },

  methods: {
    onClick: function(payload) {
      if (payload.action && payload.action === 'view') {
        this.currentLeadId = payload.id;
      }
      if (payload.action && payload.action === 'edit') {
        this.$router.push({
          name: 'LeadsUpdate',
          params: { id: payload.id }
        });
      }
    },
    onInfoWindowClose: function() {
      this.currentLeadId = null;
    }
  }
};
</script>

<style></style>
