<template>
  <div class="c-reports-header">
    <InputEnum
      v-model="status"
      enum="status"
      label="Current Organisation status"
    ></InputEnum>
    <InputDatepicker label="Select range" v-model="range"></InputDatepicker>
    <button v-if="json" class="btn btn-primary" @click="downloadCsv">
      Download Report ({{ json.length }} datapoints)
    </button>
  </div>
</template>

<script>
import InputDatepicker from '@/components/global/forms/input-datepicker';

import InputEnum from '@/components/global/forms/input-enum';

export default {
  components: {
    InputDatepicker,
    InputEnum
  },

  watch: {
    range: function() {
      this.onChange();
    }
  },

  data: function() {
    return {
      range: {
        start: new Date().toISOString(),
        end: new Date().toISOString()
      },
      status: '',
      json: ''
    };
  },

  computed: {},

  methods: {
    onChange: function() {
      if (this.range.start && this.range.end) {
        this.submit();
      }
    },
    async submit() {
      let form = {
        start: this.range.start,
        end: this.range.end,
        status: this.status
      };

      let res = await this.$store.dispatch('reports/doAction', {
        action: 'organisation-report',
        resource: form
      });

      if (res) {
        this.json = res;
      }
      return res;
    },

    downloadCsv() {
      let filename = 'stats.csv';
      let text = this.generateCsv(this.json);
      var element = document.createElement('a');
      element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
      );
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },

    generateCsv(notes) {
      let delimiter = `,`;

      let d = delimiter;

      let lineBreak = `\n`;

      let csv = `status${d}createdAt${d}followupDate${d}html${d}id${d}lead.firstName${d}lead.lastName${d}user.firstName${d}user.lastName`;

      for (let i = 0; i < notes.length; i++) {
        let note = notes[i];
        let row = `${note.status}${d}${note.createdAt}${d}${note.followupDate}${d}${note.html}${d}${note.id}${d}${note.lead.firstName}${d}${note.lead.lastName}${d}${note.user.firstName}${d}${note.user.lastName}`;

        csv = `${csv}${lineBreak}${row}`;
      }

      return csv;
    }
  },

  created: function() {
    let start = new Date();
    let end = new Date();

    let subtractDays = 30;

    start.setDate(start.getDate() - subtractDays);
    start = start.toISOString();
    end = end.toISOString();

    this.range = {
      start: start,
      end: end
    };
  }
};
</script>

<style lang="scss">
.c-reports-header {
  .btn {
    margin-top: var(--space-lg);
  }
}
</style>
