<template>
  <div class="c-item-status">
    <span v-if="resource['status']" :class="['label', klass]">
      {{ resource['status'] }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    resource: {
      type: Object,
      default: function() {
        return {};
      }
    },
    column: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  computed: {
    klass: function() {
      if (this.resource['status']) {
        return this.resource['status'].toLowerCase();
      }
    },
    key: function() {
      return status;
    }
  }
};
</script>

<style lang="scss">
.c-item-status {
  .label {
    display: inline-flex;
    align-items: center;
    padding: var(--margin) var(--margin);
    font-size: var(--font-size-xs);
    font-weight: 600;
    line-height: 1;
    color: rgba(0, 0, 0, 0.75);
    border: var(--color-grey-light) solid 1px;
    border-radius: var(--border-radius-sm);
    border-color: currentColor;

    &.proposal {
      color: var(--color-blue-dark);
    }

    &.customer {
      color: var(--color-green);
    }

    &.lead {
      color: var(--color-orange);
    }

    &.potential {
      color: var(--color-blue);
    }
  }
}
</style>
