<template>
  <div class="c-action">
    <article>
      <h3>
        Action steps
      </h3>
      <p>
        A successful order will take a couple of steps to complete. Use this
        flow to track how your order is doing.
      </p>
    </article>

    <div class="c-action__inner">
      <div
        :class="[
          'c-action__item',
          { active: step.active === true },
          { current: step.current === true }
        ]"
        v-for="(step, index) in computedSteps"
        :key="index"
      >
        <span class="c-action__item__ball"></span>
        <h3>{{ step.label }}</h3>
        <div class="c-action__trigger" v-if="step.current === true">
          <slot></slot>
        </div>

        <span><DateComponent :date="step.completedAt"></DateComponent></span>
      </div>
      <div
        class="c-action__timeline"
        v-if="duration"
        :style="computedTimelineStyle"
      >
        <span>{{ duration }} days</span>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/global/button';
import DateComponent from '@/components/global/date';

import { parseISO, format, formatISO, differenceInDays } from 'date-fns';

export default {
  components: {
    Button,
    DateComponent
  },

  props: {
    steps: {
      type: Array,
      default: function() {
        return [];
      }
    },
    status: {
      type: String,
      default: null
    }
  },

  computed: {
    duration: function() {
      let steps = this.steps;

      let firstStep = 'DRAFT';
      let lastStep = this.status;

      steps.map(step => {
        if (step.value === firstStep) {
          firstStep = step;
        }
        if (step.value === lastStep) {
          lastStep = step;
        }
      });

      // Don't show duration if first and last steps are the same
      if (firstStep.value === lastStep.value) {
        return null;
      }

      let result = differenceInDays(
        parseISO(lastStep.completedAt),
        parseISO(firstStep.completedAt)
      );

      return result;
    },
    sortedSteps: function() {
      // if no slice, infinite loop will happen
      let newSteps = this.steps.slice();

      const sortList = [
        'DRAFT',
        'AWAITING_PFR',
        'PFR_RECEIVED',
        'AWAITING_DOCUMENTS',
        'DOCUMENTS_RECEIVED',
        'WINTHROP_INVOICE_GENERATED',
        'SETUP_SANDBOX',
        'CREATE_WINTHROPE_ORDER',
        'COMPLETED'
      ];

      newSteps = newSteps.sort((a, b) => {
        return sortList.indexOf(a.value) - sortList.indexOf(b.value);
      });

      return newSteps;
    },
    computedSteps: function() {
      let newSteps = this.sortedSteps;
      if (!this.status) {
        return [];
      }
      let status = this.status;

      newSteps = newSteps.map(step => {
        step.active = false;
        step.current = false;
        return step;
      });

      for (let i = 0; i < newSteps.length; i++) {
        newSteps[i].active = true;
        if (newSteps[i].value === status) {
          newSteps[i].current = true;

          break;
        }
      }

      return newSteps;
    },
    computedTimelineStyle: function() {
      let steps = this.sortedSteps;
      let status = this.status;

      let firstStep = 'DRAFT';
      let lastStep = this.status;

      steps.map((step, i) => {
        if (step.value === firstStep) {
          firstStep = i;
        }
        if (step.value === lastStep) {
          lastStep = i;
        }
      });

      let totalSteps = steps.length;
      let completedSteps = lastStep - firstStep + 1;

      let diff = completedSteps / totalSteps;

      diff = diff * 100;

      let width = `${diff}%`;

      return {
        width: width
      };
    }
  }
};
</script>

<style lang="scss">
.c-action {
  position: relative;
  width: 100%;
  margin: var(--space-md) 0;
  margin-bottom: var(--space-xl);

  > article {
    margin-bottom: var(--space-sm);
  }

  &__inner {
    display: flex;
    border-radius: var(--border-radius-lg);
    border: var(--color-bg-4) solid 1px;
  }

  &__item {
    position: relative;
    flex-grow: 1;
    flex-basis: 0;
    padding-top: var(--space-xl);
    padding-bottom: var(--space-md);
    text-align: center;

    &__ball {
      position: absolute;
      z-index: 2;
      top: 24px;
      left: calc(50% - 12px);
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: var(--color-bg-4);
      box-shadow: 0 0 0 4px rgba(255, 255, 255, 1);
    }

    &:not(:first-child) {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 100%;
        background-color: var(--color-bg-4);
      }
    }

    &:not(:first-child) {
      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 34px;
        left: -50%;
        width: calc(100% + 12px);
        height: 4px;
        background-color: var(--p3);
        opacity: 0;
        border-radius: 2px;
      }
    }

    &.active {
      .c-action__item__ball {
        background-color: var(--p3);
      }
      &:after {
        opacity: 1;
      }
    }

    &.current {
      .c-action__item__ball {
        box-shadow: 0 0 0 4px rgba(255, 255, 255, 1), 0 0 0 8px var(--p3);
      }
    }
  }

  &__timeline {
    position: absolute;
    bottom: -30px;
    left: 0;
    width: 37.5%;
    height: 20px;
    //background-color: green;
    border-left: var(--p3) solid 2px;
    border-right: var(--p3) solid 2px;
    border-bottom: var(--p3) solid 2px;

    border-bottom-left-radius: var(--border-radius-lg);
    border-bottom-right-radius: var(--border-radius-lg);

    text-align: center;

    span {
      display: inline-block;
      margin-top: 2px;
      padding: var(--margin-lg);
      background-color: var(--color-white);
      font-size: var(--font-size-xs);
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }
}
</style>
