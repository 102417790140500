<template>
  <div class="c-breadcrumb">
    <button class="c-breadcrumb__item" @click="$router.go(-1)">
      <span>Go back</span>
    </button>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.c-breadcrumb {
  left: var(--space-xl);
  position: absolute;
  top: calc(var(--space-lg) * -1.25);
  transform: translateX(calc(var(--space-sm) * -3));

  &__item {
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    display: flex;
    padding: 0;

    &::before {
      background-color: var(--color-white);
      background-image: url('/images/icon-arrow-back.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 50%;
      border: 1px solid var(--color-input-border);
      border-radius: 50%;
      box-shadow: var(--box-shadow-input);
      content: '';
      display: inline-block;
      height: calc(var(--space-sm) * 2);
      margin-right: var(--space-sm);
      width: calc(var(--space-sm) * 2);
    }

    &:hover,
    &:focus {
      text-decoration: underline;
      text-underline-offset: 2px;

      &:before {
        @include focus();
      }
    }
  }
}
</style>
