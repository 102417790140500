import { Decoration, DecorationSet } from "prosemirror-view";

import { Extension } from "@tiptap/core";
import { Plugin } from "prosemirror-state";

function renderColor(issue) {
  const icon = document.createElement("div");

  icon.className = "decoration-color";
  icon.title = issue[0];
  icon.style.setProperty(`--color`, `${issue[0]}`);
  icon.issue = issue;

  return icon;
}

function renderUrl(issue) {
  const icon = document.createElement("a");
  let url = new URL(issue[0]);
  let domain = url.origin;
  icon.className = "decoration-email";
  icon.href = issue[0];
  icon.target = "_blank";
  icon.style.setProperty("background-image", `url(${domain}/favicon.ico)`);
  icon.issue = issue;

  return icon;
}

function replacers(doc) {
  const hexRegex = /(#[0-9a-f]{3,8})\b/gi;

  const decorations = [];

  doc.descendants((node, position) => {
    if (!node.text) {
      return;
    }

    Array.from(node.text.matchAll(hexRegex)).forEach((match) => {
      const color = match[0];
      const index = match.index || 0;
      const from = position + index;
      const to = from + color.length;
      const decoration = Decoration.widget(from, renderColor(match));

      decorations.push(decoration);
    });
  });

  return DecorationSet.create(doc, decorations);
}

export default Extension.create({
  name: "textColor",

  addProseMirrorPlugins() {
    return [
      new Plugin({
        state: {
          init(_, { doc }) {
            return replacers(doc);
          },
          apply(transaction, oldState) {
            return transaction.docChanged
              ? replacers(transaction.doc)
              : oldState;
          },
        },
        props: {
          decorations(state) {
            return this.getState(state);
          },
        },
      }),
    ];
  },
});
