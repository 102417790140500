<template>
  <div class="c-item-id">
    {{ resource.id }}
  </div>
</template>

<script>
import Date from '@/components/global/date';

export default {
  components: {
    Date
  },

  props: {
    resource: {
      type: Object,
      default: function() {
        return {};
      }
    },
    column: {
      type: Object,
      default: function() {
        return {};
      }
    }
  }
};
</script>

<style lang="scss">
.c-item-id {
  font-family: var(--font-family-monospace);
  font-size: var(--font-size-sm);
}
</style>
