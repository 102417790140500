<template>
  <div class="c-item-followup-date">
    <div v-if="date">
      <DateComponent :date="date"></DateComponent>
      <span v-if="isToday" class="c-item-followup-date__label">Today</span>
    </div>
  </div>
</template>

<script>
import DateComponent from '@/components/global/date';

const { isSameDay, format, parseISO } = require('date-fns');

export default {
  components: {
    DateComponent
  },

  props: {
    resource: {
      type: Object,
      default: function() {
        return {};
      }
    },
    column: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  computed: {
    date: function() {
      let notes = this.$store.getters['notes/byLead'](this.resource.id);

      let note = null;

      if (notes.length > 0) {
        note = notes[0];
      }

      if (!note) {
        return '';
      }

      if (!note.followupDate) {
        return '';
      }

      let dateFormat = 'dd/MM/yyyy';

      return note.followupDate;
    },
    isToday: function() {
      let date = this.date;

      date = parseISO(date);

      if (!date) {
        return false;
      }

      let today = new Date();

      return isSameDay(date, today);
    }
  },

  methods: {}
};
</script>

<style lang="scss">
.c-item-followup-date {
  overflow: hidden;
  padding-right: var(--space-sm);
  text-overflow: ellipsis;
  text-align: center;
  width: 100%;
  white-space: nowrap;
  font-family: var(--font-family-monospace);
  font-size: var(--font-size-sm);

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__label {
    font-family: var(--font-family);
    background-color: var(--danger-color);
    padding: var(--margin) var(--margin-lg);
    margin-left: var(--margin-lg);
    display: inline-block;
    color: var(--color-white);
    border-radius: var(--border-radius-sm);
    font-size: var(--font-size-xs);
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
}
</style>
