<template>
  <div :class="['c-item-checkbox', column.size]">
    <InputCheckbox :value="checked" @input="onChange"></InputCheckbox>
  </div>
</template>

<script>
import InputCheckbox from '@/components/global/forms/input-checkbox';

export default {
  components: {
    InputCheckbox
  },

  data: function() {
    return {
      checked: false
    };
  },

  watch: {
    selected: {
      handler: function() {
        this.checked = this.selected;
      },
      immediate: true
    }
    // checked: function() {
    //   this.onChange();
    // }
  },

  props: {
    resource: {
      type: Object,
      default: function() {
        return {};
      }
    },
    column: {
      type: Object,
      default: function() {
        return {};
      }
    },
    selected: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onChange: function() {
      this.$emit('click', 'selected', this.resource.id);
    }
  }
};
</script>

<style lang="scss">
.c-item-checkbox {
}
</style>
