<template>
  <div
    :class="[
      'c-list-header',
      { 'no-rounded-border': fullContainerWidth === true }
    ]"
  >
    <template v-for="column in columns">
      <div
        v-if="column.key === '_checkbox'"
        class="c-list-item c-list-item--checkbox"
        :key="column.key"
      >
        <InputCheckbox :label="column.key" :method="onToggle" />
      </div>
      <div
        v-else
        :class="[
          'c-list-item',
          { sortable: column.sortable || false },
          { active: sort === column.key },
          sortDirection
        ]"
        :key="column.key"
        tabindex="0"
        @click="onClick(column)"
      >
        <span>
          {{ column.label }}
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="20"
          height="20"
        >
          <path
            fill="currentColor"
            d="M13 16.172l5.364-5.364 1.414 1.414L12 20l-7.778-7.778 1.414-1.414L11 16.172V4h2v12.172z"
          />
        </svg>
      </div>
    </template>
  </div>
</template>

<script>
import InputCheckbox from '@/components/global/forms/input-checkbox';

export default {
  components: {
    InputCheckbox
  },

  data: function() {
    return {};
  },

  props: {
    columns: {
      type: Array,
      default: function() {
        return [];
      }
    },
    sort: {
      type: String,
      default: ''
    },
    sortDirection: {
      type: String,
      default: ''
    },
    fullContainerWidth: {
      type: Boolean,
      default: false
    }
  },

  // computed: {
  //   sortable: function() {
  //     return false;
  //   }
  // },

  methods: {
    onToggle: function(e) {
      this.$emit('onToggle', e.target.checked);
    },
    onClick: function(column) {
      if (column.sortable) {
        let key = column.key;
        let type = column.sortType || 'string';

        // Cant sort through computed keys for now
        if (key.includes(',')) {
          key = key.split(',')[0];
        }
        this.$emit('onToggleFilter', key, type);
      }
    }
  }
};
</script>

<style lang="scss">
.c-list-header {
  // c-list-row to be optimised
  display: table-row;
  padding: var(--space-md) calc(var(--space-md) * 2);
  width: 100%;

  background-color: hsl(var(--p), 80%, 65%);
  color: var(--color-white);
  font-weight: 700;
  position: relative;

  &::before,
  &::after {
    background-color: hsl(var(--p), 80%, 65%);
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    width: var(--space-lg);
  }

  &::before {
    border-bottom-left-radius: var(--border-radius-lg);
    border-top-left-radius: var(--border-radius-lg);
    right: 100%;
  }

  &::after {
    border-bottom-right-radius: var(--border-radius-lg);
    border-top-right-radius: var(--border-radius-lg);
    left: 100%;
  }

  &.no-rounded-border {
    &::before,
    &::after {
      border-radius: 0;
    }
  }

  .c-list-item {
    border-bottom: 0;
    padding-right: var(--space-md);
    position: relative;

    > span,
    > svg {
      user-select: none;
    }

    svg {
      position: absolute;
      top: calc(50% - 10px);
      right: 0;
      opacity: 0;
      transition: transform 120ms ease-out;
    }

    &.sortable {
      cursor: pointer;
      user-select: none;

      &:focus {
        outline: none;
        background-color: var(--primary);
      }

      &:hover,
      &:active {
        background-color: var(--primary);
      }

      svg {
        opacity: 0.5;
      }
    }

    &.active {
      background-color: var(--primary);
      svg {
        opacity: 1;
      }
    }

    &.DSC.active {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
