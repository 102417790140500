<template>
  <div class="c-message">
    <transition-group name="list" tag="div">
      <Alert
        v-for="message in stack"
        :key="message.id"
        :message="message"
        @onClose="onClose(message)"
      ></Alert>
    </transition-group>
  </div>
</template>

<script>
import Alert from './alert';

export default {
  components: {
    Alert
  },

  data: function() {
    return {
      limit: 6,
      stack: []
    };
  },

  watch: {
    messageTrigger: function() {
      this.showMessage(this.message);
    }
  },

  computed: {
    message: function() {
      return this.$store.getters['message/message'];
    },
    messageTrigger: function() {
      return this.$store.getters['message/messageTrigger'];
    }
  },

  methods: {
    showMessage(message) {
      message.timer = message.timer || 4000;
      message.status = message.status || 'info';
      message.status = message.status.toLowerCase();
      this.addToStack(message);
    },
    onClose: function(message) {
      this.removeFromStack(message.id);
    },
    removeFromStack: function(id) {
      let index = -1;
      let messages = this.stack;

      for (let i = 0; i < messages.length; i++) {
        if (messages[i].id === id) {
          index = i;
          break;
        }
      }

      if (index === -1) {
        return;
      }

      this.stack.splice(index, 1);
    },
    addToStack: function(message) {
      message = {
        ...message,
        id: Math.floor(Math.random() * 100000)
      };
      this.stack.unshift(message);

      if (this.stack.length === this.limit) {
        this.stack.pop();
      }
    }
  }
};
</script>

<style lang="scss">
.c-message {
  position: fixed;
  z-index: 1050;
  bottom: 0;
  left: 0;
  width: 100%;

  pointer-events: none;

  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s ease;
  }

  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }
}
</style>
