import Vue from 'vue';
import Vuex from 'vuex';

import config from './config.js';
import app from './app.js';
import users from './users.js';
import organisations from './organisations.js';
import fields from './fields.js';
import leads from './leads.js';
import notes from './notes.js';
import products from './products.js';
import locations from './locations.js';
import events from './events.js';
import orders from './orders.js';
import winthropReceipts from './winthrop-receipts.js';
import rctis from './rctis.js';
import reports from './reports';
import message from './message.js';
import customForms from './custom-forms';
import organisationLogs from './organisation-logs.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    async init(store) {
      await store.dispatch('config/init');

      await store.dispatch('app/init');

      await store.dispatch('events/init');

      await store.dispatch('users/init');

      await store.dispatch('notes/init');

      await store.dispatch('leads/init');

      await store.dispatch('locations/init');

      await store.dispatch('organisations/init');

      await store.dispatch('organisationLogs/init');

      await store.dispatch('orders/init');

      await store.dispatch('winthropReceipts/init');

      await store.dispatch('rctis/init');

      await store.dispatch('message/init');

      // ---

      if (store.rootGetters['users/isAuth']) {
        await store.dispatch('afterLogin');
      }

      await store.dispatch('config/setInit');

      // ---

      if (store.rootGetters['users/isAuth']) {
        await store.dispatch('message/onLogin');
      }
    },

    async afterLogin(store) {
      await store.dispatch('app/afterLogin');

      await store.dispatch('users/afterLogin');

      await store.dispatch('fields/afterLogin');

      await store.dispatch('notes/afterLogin');

      await store.dispatch('leads/afterLogin');

      await store.dispatch('organisations/afterLogin');

      await store.dispatch('organisationLogs/afterLogin');

      await store.dispatch('locations/afterLogin');

      await store.dispatch('products/afterLogin');

      await store.dispatch('orders/afterLogin');

      await store.dispatch('winthropReceipts/afterLogin');

      await store.dispatch('rctis/afterLogin');
    },

    async afterLogout(store) {
      await store.dispatch('app/afterLogout');

      await store.dispatch('fields/afterLogout');

      await store.dispatch('users/afterLogout');

      await store.dispatch('notes/afterLogout');

      await store.dispatch('leads/afterLogout');

      await store.dispatch('organisations/afterLogout');

      await store.dispatch('organisationLogs/afterLogout');

      await store.dispatch('products/afterLogout');

      await store.dispatch('orders/afterLogout');

      await store.dispatch('winthropReceipts/afterLogout');

      await store.dispatch('rctis/afterLogout');
    }
  },
  modules: {
    config,
    app,
    users,
    organisations,
    organisationLogs,
    fields,
    leads,
    notes,
    products,
    locations,
    events,
    orders,
    winthropReceipts,
    rctis,
    reports,
    message,
    customForms
  }
});
