'use strict';

import hljs from 'highlight.js/lib/highlight';

let languages = {};
const supportedLanguages = ['javascript', 'xml', 'css', 'json'];

async function highlightOnce(el, binding) {
  for (let i = 0; i < supportedLanguages.length; i++) {
    let language = await import(
      `highlight.js/lib/languages/${supportedLanguages[i]}`
    );
    languages[supportedLanguages[i]] = language;
    hljs.registerLanguage(
      supportedLanguages[i],
      languages[supportedLanguages[i]].default
    );
  }

  let data = hljs.highlightAuto(binding.value.code);
  el.innerHTML = data.value;
}

async function highlight(el, binding) {
  let data = hljs.highlightAuto(binding.value.code);
  el.innerHTML = data.value;
}

export default {
  install(vue) {
    vue.directive('highlightjs', {
      deep: true,
      async bind(el, binding) {
        try {
          await highlightOnce(el, binding);
        } catch (err) {
          console.log(err);
        }
      },
      async componentUpdated(el, binding) {
        // if (binding.oldValue.code === binding.value.code &&
        //   binding.oldValue.language === binding.value.language) return

        try {
          await highlight(el, binding);
        } catch (err) {
          console.log(err);
        }
      }
    });
  }
};
