<template>
  <div
    :class="[
      'form-group c-input-relation-multiple',
      { 'form-group--inline': inline }
    ]"
  >
    <label class="form__label" :for="slug">{{ label }}</label>
    <v-select
      :value="selectValue"
      :options="computedOptions"
      label="label"
      @input="onChange"
      :multiple="true"
    ></v-select>

    <InputError v-if="hasError" :message="errorMessage"></InputError>
  </div>
</template>

<script>
import InputError from './input-error';
import slugify from '@/lib/slugify';

export default {
  components: {
    InputError
  },

  data: function() {
    return {
      selectValue: []
    };
  },

  watch: {
    value: {
      handler: function() {
        //this.setValue(this.value);
      },
      immediate: true
    }
  },

  props: {
    label: {
      type: String,
      default: 'Label'
    },
    value: {},
    inline: {
      type: Boolean,
      default: false
    },
    relation: {
      type: String,
      default: ''
    },
    optionKey: {
      type: String,
      default: 'id'
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },

  computed: {
    options: function() {
      return this.$store.getters[this.relation];
    },
    computedOptions: function() {
      let options = this.options;

      if (!options) {
        throw new Error('Relation doenst exist');
      }

      if (options.length === 0) {
        return [];
      }

      let labelKey = this.optionKey;

      let newOptions = [];

      for (let i = 0; i < options.length; i++) {
        let obj = {
          label: options[i][labelKey],
          value: options[i].id
        };

        newOptions.push(obj);
      }

      return newOptions;
    },
    slug: function() {
      return slugify(this.label);
    }
  },

  methods: {
    setValue: function(val) {
      if (!val) {
        return;
      }
      let id = val;
      let options = this.options;
      let option = null;

      for (let i = 0; i < options.length; i++) {
        if (options[i].id === id) {
          option = options[i];
          break;
        }
      }

      if (!option) {
        return;
      }

      let obj = {
        label: option[this.optionKey],
        value: option.id
      };

      this.selectValue = obj;

      return;
    },
    transformValues: function(arr) {
      arr = arr.map(item => {
        return {
          id: item.value
        };
      });

      return arr;
    },
    onChange: function(data) {
      this.selectValue = data;
      let values = this.transformValues(data);
      this.$emit('input', values);
    }
  }
};
</script>

<style lang="scss">
.c-input-relation-multiple {
  .v-select {
    appearance: none;
    background-color: var(--g1);
    border: 1px solid transparent;
    border-radius: var(--border-radius-md);
    box-shadow: 0px 0px 8px #00000033;
    display: inline-block;
    font-size: var(--font-size-md);
    margin-top: var(--space-xs);
    transition: all 50ms ease-out;
    padding: var(--space-xs);
    width: 100%;

    &.vs--open {
      box-shadow: 0 0 0 3px rgba(70, 132, 248, 0.6) !important;
    }

    .vs__selected-options {
      padding: 0;
    }

    .vs__selected {
      border-color: transparent;
      border-radius: var(--border-radius-sm);
      line-height: 1.4;
      margin: 0 var(--space-xs) 0 0;
      padding: var(--margin);
    }

    .vs__dropdown-toggle {
      border: none;
      border-radius: 0;
      padding: 0;
    }

    .vs__deselect {
      padding: var(--margin);
      border-radius: 50%;

      &:hover,
      &:active {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .vs__search {
      appearance: none;
      border: 0;
      font-size: var(--font-size-md);
      line-height: 1.5;
      margin: 0;
      padding: 0;
    }

    .vs__actions {
      padding: 0;
    }

    &:active,
    &:focus {
      border-color: transparent;
    }
    &:focus {
      outline: none;
    }

    ul {
    }
  }
}
</style>
