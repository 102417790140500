<template>
  <div class="c-reports-item">
    <div class="c-reports-item__body">
      <p>
        {{ title }}
      </p>
      <h1>
        $1,888
      </h1>
    </div>
    <div class="c-reports-item__chart"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: `New x in the last y days`
    }
  }
};
</script>

<style lang="scss">
.c-reports-item {
  display: grid;
  grid-template-columns: 30% 70%;
  border-bottom: rgba(0, 0, 0, 0.1) solid 1px;

  &__body {
    padding: var(--space-sm) 0;
  }

  &__chart {
    background-color: #ddd;
  }

  p {
    font-weight: 500;
    font-size: var(--font-size-sm);
    color: rgba(0, 0, 0, 0.75);
  }
  h1 {
    margin: 0;
    font-weight: 400;
    letter-spacing: 1px;
    color: var(--primary);
  }
}
</style>
