<template>
  <div class="c-organisations-create">
    <BreadCrumb></BreadCrumb>
    <h1>
      Update Organisation
    </h1>
    <FormUpdate :resource="resource"></FormUpdate>
  </div>
</template>

<script>
import BreadCrumb from '@/components/global/breadcrumb';
import FormUpdate from '@/components/organisations/form-update';

export default {
  components: {
    BreadCrumb,
    FormUpdate
  },

  computed: {
    resource: function() {
      let id = this.$route.params.id;
      return this.$store.getters['organisations/one'](id);
    }
  }
};
</script>

<style lang="scss">
.c-organisations-create {
}
</style>
