<template>
  <div class="c-form">
    <Accordion
      v-for="(schema, index) in computedSchema"
      :key="index"
      :title="schema.label"
      :active="true"
    >
      <div class="container-fluid">
        <div class="row">
          <div
            :class="[getColumnClass(field)]"
            v-for="(field, key) in schema.fields"
            :key="key"
          >
            <component
              :key="field.slug"
              :is="field.component"
              @input="update(key, $event)"
              :value="form[key]"
              v-bind="{ ...field }"
              :hasError="$v.form[key] ? $v.form[key].$error : false"
              :errorMessage="errorMessages[key]"
            ></component>
          </div>
        </div>
      </div>
    </Accordion>
  </div>
</template>

<script>
/**
 * [IMP] This is an exact copy of index.vue but for some reason, import index.vue doesn't work for a specific component(locations/form-create)
 * Hence, I made this component
 */
import { validationMixin } from 'vuelidate';

import validationRules from './parser';

import InputText from '@/components/global/forms/input-text';
import InputDropdown from '@/components/global/forms/input-dropdown';
import InputEmail from '@/components/global/forms/input-email';
import InputNotes from '@/components/global/forms/input-notes';
import InputFile from '@/components/global/forms/input-file';
import InputRelation from '@/components/global/forms/input-relation';
import InputNumber from '@/components/global/forms/input-number';
import InputLocations from '@/components/global/forms/input-locations';
import InputEnum from '@/components/global/forms/input-enum';
import InputBoolean from '@/components/global/forms/input-boolean';
import InputRelationMultiple from '@/components/global/forms/input-relation-multiple';
import InputProducts from '@/components/global/forms/input-products';

import Accordion from '@/components/global/accordion';

export default {
  mixins: [validationMixin],

  components: {
    InputText,
    InputDropdown,
    InputEmail,
    InputNotes,
    InputFile,
    InputRelation,
    InputNumber,
    InputLocations,
    InputEnum,
    InputBoolean,
    InputRelationMultiple,
    InputProducts,

    Accordion
  },

  validations() {
    return { form: validationRules(this.computedFlatSchema) };
  },

  data: function() {
    return {
      form: {}
    };
  },

  props: {
    schema: {
      type: Array,
      default: function() {
        return [];
      }
    },
    fields: {
      type: Array,
      default: function() {
        return [];
      }
    },
    value: { type: Object, required: true }
  },

  computed: {
    isValid: function() {
      return false;
    },
    computedFields: function() {
      let fields = this.fields;

      let obj = {};

      for (let i = 0; i < fields.length; i++) {
        let component = 'InputText';
        if (fields[i].type === 'NUMBER') {
          component = 'InputNumber';
        }
        let fieldName = `form_${fields[i].slug}`;
        obj[fieldName] = {
          component: component,
          label: fields[i].label,
          validations: {}
        };
      }

      return obj;
    },
    computedSchema: function() {
      let schema = [];

      for (let i = 0; i < this.schema.length; i++) {
        if (this.schema[i].type === 'insert') {
          schema.push({
            type: 'accordion',
            label: 'Custom fields',
            fields: this.computedFields
          });
        } else {
          schema.push({
            type: 'accordion',
            label: this.schema[i].label,
            fields: this.schema[i].fields
          });
        }
      }

      return schema;
    },
    computedFlatSchema: function() {
      let newSchema = {};
      let schema = this.computedSchema;
      for (let i = 0; i < schema.length; i++) {
        for (let key in schema[i].fields) {
          newSchema[key] = schema[i].fields[key];
        }
      }

      return newSchema;
    },
    errorMessages() {
      const validations = this.$v.form;
      let schema = this.computedFlatSchema;

      return Object.keys(schema).reduce((messages, key) => {
        const rules = schema[key].validations || {};
        const rulesKeys = Object.keys(rules);
        const validator = validations[key];

        if (!validator) return messages;

        for (let rule of rulesKeys) {
          if (validator[rule] !== false) continue;

          messages[key] = rules[rule].message;
          return messages;
        }

        return messages;
      }, {});
    }
  },

  methods: {
    hasError: function(key) {
      if (this.$v.form[key]) {
        return this.$v.form[key].$error;
      } else {
        return false;
      }
    },
    getColumnClass: function(component) {
      let klass = 'col-12';

      if (component.properties && component.properties.columnSize) {
        klass = `col-${component.properties.columnSize}`;
      }

      return klass;
    },

    validate() {
      this.$v.$touch();
      this.announceStatus();
    },

    update: function(key, value) {
      this.form[key] = value;

      if (this.$v.form[key]) {
        this.$v.form[key].$touch();
      }

      this.$emit('input', {
        ...this.value,
        [key]: value
      });

      this.announceStatus();
    },
    announceStatus: function() {
      this.$emit('status', {
        invalid: this.$v.$invalid
      });
    }
  },

  created() {
    for (const name in this.computedFlatSchema) {
      this.$set(this.form, name, null);
    }
    for (const name in this.value) {
      if (name in this.form) {
        this.$set(this.form, name, this.value[name]);
      }
    }
  }
};
</script>

<style>
.c-form {
}
</style>
