<template>
  <div class="c-form c-form-reset-password-request">
    <form @submit.prevent="onSubmit" v-if="active">
      <InputEmail
        label="Email"
        v-model="form.email"
        :validation="$v.form.email"
      ></InputEmail>
      <br />
      <Button :processing="processing" type="submit">Reset</Button>
    </form>
    <p v-else>
      Check your inbox. You should get an email with the password reset link
      inside it.
    </p>
  </div>
</template>

<script>
import Button from '@/components/global/button';
import InputText from '@/components/global/forms/input-text';
import InputEmail from '@/components/global/forms/input-email';

import { validationMixin } from 'vuelidate';
import { required, email, minLength, between } from 'vuelidate/lib/validators';

export default {
  components: {
    Button,
    InputText,
    InputEmail
  },

  mixins: [validationMixin],

  data: function() {
    return {
      active: true,
      processing: false,
      form: {
        password: ''
      }
    };
  },

  props: {
    token: {
      type: String,
      default: ''
    }
  },

  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },

  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.invalid) {
        return;
      }
      this.processing = true;
      let token = this.token;
      let form = {
        ...this.form,
        token
      };
      let condition = await this.$store.dispatch(
        'users/resetPasswordRequest',
        form
      );

      this.active = false;

      // if (condition) {
      //   await this.$store.dispatch('afterLogin');
      //   this.$emit('submit');
      // }
      this.processing = false;
    }
  }
};
</script>

<style lang="scss">
.c-form-reset-password {
  .form-control {
    text-align: center;
  }

  .btn {
    margin-top: var(--space-lg);
  }

  .c-input-text {
    display: none;
  }
}
</style>
