<template>
  <div class="c-item-text">
    <div v-if="!column.html">
      <a v-if="column.clickable" @click="onClick" :title="text" href="#">
        {{ text }}
      </a>
      <span v-else :title="text">
        {{ text }}
      </span>
    </div>
    <article v-else v-html="text"></article>
  </div>
</template>

<script>
export default {
  props: {
    resource: {
      type: Object,
      default: function() {
        return {};
      }
    },
    column: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  computed: {
    text: function() {
      if (this.column.formatter) {
        return this.column.formatter(this.resource, this.column);
      }
      return this.resource[this.column.key];
    }
  },

  methods: {
    onClick: function() {
      if (this.column.clickable) {
        this.$emit('click', 'view', this.resource.id, this.column.key);
      }
    }
  }
};
</script>

<style lang="scss">
.c-item-text {
  overflow: hidden;

  div {
    align-items: center;
    display: flex;
  }

  article {
    display: inline-flex;
    flex-wrap: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    p {
      margin-right: var(--space-xxs);
    }
  }

  span,
  a {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }

  a {
    &,
    &:hover,
    &:active {
      color: var(--color-text);
      text-decoration: underline;
    }
  }
}
</style>
