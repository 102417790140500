<template>
  <div class="c-item-action">
    <Icon v-if="canView" name="EyeIcon" @onClick="onClick('view', resource.id)">
    </Icon>
    <svg
      v-if="canDelete"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-trash-2"
      @click="onClick('delete', resource.id)"
    >
      <polyline points="3 6 5 6 21 6"></polyline>
      <path
        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
      ></path>
      <line x1="10" y1="11" x2="10" y2="17"></line>
      <line x1="14" y1="11" x2="14" y2="17"></line>
    </svg>
    <Icon
      v-if="!isReadOnly && canEdit"
      name="Edit2Icon"
      @onClick="onClick('edit', resource.id)"
    >
    </Icon>
  </div>
</template>

<script>
import Icon from '@/components/global/icon';
export default {
  components: {
    Icon
  },
  props: {
    resource: {
      type: Object,
      default: function() {
        return {};
      }
    },
    column: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  computed: {
    isReadOnly: function() {
      return this.$store.getters['users/isReadOnly'];
    },
    canView: function() {
      let column = this.column;
      if (!column.actions) {
        return true;
      }
      if (column.actions && column.actions.includes('V')) {
        return true;
      }

      return false;
    },
    canDelete: function() {
      let column = this.column;
      if (!column.actions) {
        return true;
      }
      if (column.actions && column.actions.includes('D')) {
        return true;
      }

      return false;
    },
    canEdit: function() {
      let column = this.column;
      if (!column.actions) {
        return true;
      }
      if (column.actions && column.actions.includes('E')) {
        return true;
      }

      return false;
    }
  },

  methods: {
    onClick: function(action, id) {
      this.$emit('click', action, id);
    }
  }
};
</script>

<style lang="scss">
.c-item-action {
  display: flex;

  > .c-icon,
  > svg {
    width: var(--space-lg);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: var(--color-grey);
    font-weight: 500;
    line-height: 1;
    cursor: pointer;

    &:hover,
    &:active {
      color: var(--color-blue-dark);
    }

    &.feather-trash-2 {
      &:hover,
      &:active {
        color: var(--danger-color);
      }
    }
  }
}
</style>
