<template>
  <form @submit.prevent="onSubmit">
    <Form
      ref="form"
      v-model="form"
      :schema="schema"
      :fields="fields"
      @status="saveStatus"
    ></Form>
    <InputButton :processing="processing" class="btn btn-primary" type="submit">
      Add Order
    </InputButton>
  </form>
</template>

<script>
import Form from '@/components/global/form';

import schema from './schema.js';

import InputButton from '@/components/global/button';

import parseFormFields from '@/lib/parse-form-fields';

export default {
  components: {
    InputButton,

    Form
  },

  data: function() {
    return {
      status: null,

      processing: false,

      form: {}
    };
  },

  computed: {
    schema: function() {
      let currentSchema = JSON.parse(JSON.stringify(schema));
      let form = this.form;

      let fields = currentSchema[0].fields;

      if (!form.organisation) {
        fields.lead.readonly = true;
        fields.lead.description = 'Set a location to select a lead';

        form.lead = null;
      }

      // if (form.location) {
      //   let organisation = this.$store.getters['organisations/one'](
      //     form.location.organisationId
      //   );

      //   fields.lead.filter = function(options) {
      //     options = options.filter(option => {
      //       if (option.organisationId === organisation.id) {
      //         return true;
      //       }
      //       return false;
      //     });
      //     return options;
      //   };
      // }

      if (form.organisation) {
        fields.lead.filter = function(options) {
          options = options.filter(option => {
            if (option.organisationId === form.organisation.id) {
              return true;
            }
            return false;
          });
          return options;
        };
      }

      if (form.mhr_active) {
        if (!form.location) {
          fields.mhr_active.description =
            'Please set a location before setting MHR to active.';
        } else {
          let organisation = this.$store.getters['organisations/one'](
            form.location.organisationId
          );
          if (!organisation.hpio_name || !organisation.hpio_number) {
            fields.mhr_active.description = `Please set a HPIO name and HPIO number for the ${organisation.name} organisation before proceeding ahead.`;
          }
        }
      }

      currentSchema[0].fields = fields;

      return currentSchema;
    },
    fields: function() {
      return [];
    }
  },

  methods: {
    saveStatus(status) {
      this.status = status;
    },
    async onSubmit() {
      this.processing = true;
      this.$refs.form.validate();

      if (this.status.invalid) {
        this.processing = false;
        return;
      }

      let form = JSON.parse(JSON.stringify(this.form));

      const locations = this.$store.getters['locations/filtered'];

      for (let i = 0; i < locations.length; i++) {
        const location = locations[i];

        if (location.organisationId === form.organisation.id) {
          form.location = location;
          delete form.organisation;
          break;
        }
      }

      form = parseFormFields(form);

      console.log(form);

      let resource = await this.$store.dispatch('orders/add', form);

      this.processing = false;

      if (resource) {
        this.$router.push('/orders');
      }
    }
  }
};
</script>

<style></style>
