<template>
  <div class="c-date">
    {{ formattedDate }}
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns';
/**
 * Globally usable date component
 * Pass a valid iso8601 date and it will render it
 */
export default {
  props: {
    date: {
      type: String,
      default: ''
    },
    dateFormat: {
      type: String,
      default: 'dd/MM/yyyy'
    }
  },

  computed: {
    formattedDate: function() {
      if (!this.date) {
        return;
      }

      let date = parseISO(this.date);

      let dateFormat = this.dateFormat;

      let formattedDate = format(date, dateFormat);

      return formattedDate;
    }
  }
};
</script>

<style></style>
