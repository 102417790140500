<template>
  <div class="c-list-stats">
    <span v-if="total">{{ currentCount }}</span> <span v-if="total">/</span>
    {{ total }} {{ text }} shown
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 1
    },
    currentCount: {
      type: Number,
      default: 1
    }
  },

  computed: {
    text: function() {
      let text = 'records';

      if (this.total === 1) {
        text = 'record';
      }

      return text;
    }
  }
};
</script>

<style lang="scss">
.c-list-stats {
}
</style>
