<template>
  <div class="c-form">
    <form @submit.prevent="onSubmit">
      <InputEditor v-model="form.html" :validation="$v.form.html">
      </InputEditor>
      <button class="btn btn-primary" type="submit">
        Add
      </button>
    </form>
  </div>
</template>

<script>
import InputEditor from '@/components/global/forms/input-editor';

import { validationMixin } from 'vuelidate';
import { required, email, minLength, between } from 'vuelidate/lib/validators';

export default {
  components: {
    InputEditor
  },

  mixins: [validationMixin],

  data: function() {
    return {
      form: {
        html: ''
      }
    };
  },

  validations: {
    form: {
      html: {
        required
      }
    }
  },

  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        // let user = await this.$store.dispatch('users/login', this.form);
        // if (user) {
        //   this.$emit('submit');
        // }
        let form = JSON.parse(JSON.stringify(this.form));
        this.$emit('submit', form);
        this.reset();
      }
    },

    async reset() {
      this.form.html = '';
    }
  }
};
</script>

<style></style>
