import CrudStore from '@/lib/crud-store.js';
var crud = new CrudStore('organisationLogs');

const state = {
  ...crud.state
};
const getters = {
  ...crud.getters
};
const actions = {
  ...crud.actions,

  async init() {
    return true;
  },
  async afterLogin() {
    return true;
  },

  async doAction(store, obj) {
    let action = obj.action;
    let id = obj.id;
    let response = await crud.api.organisations.doAction(id, action);

    if (response) {
      store.commit('UPDATE', response);
    }

    return response;
  },

  async populateLogs(store) {
    let resources = await store.dispatch('doAction', {
      id: 1,
      action: 'send_logs'
    });

    console.log(resources);
  }
};
const mutations = {
  ...crud.mutations
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;
