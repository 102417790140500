import CrudStore from '@/lib/crud-store.js';
var crud = new CrudStore('products');

const state = {
  ...crud.state
};
const getters = {
  ...crud.getters
};
const actions = {
  ...crud.actions
};
const mutations = {
  ...crud.mutations
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;
