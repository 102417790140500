<template>
  <div class="c-json-renderer">
    <pre><code v-highlightjs="{ language: 'json', code: json }"></code></pre>
  </div>
</template>

<script>
export default {
  props: {
    json: {}
  }
};
</script>

<style lang="scss">
.c-json-renderer {
  margin: var(--space-sm) 0;
  border: rgb(92, 92, 92) solid 4px;
  border-radius: var(--border-radius-lg);
  padding: var(--space-sm);

  background-color: #30363d;
  color: #fefefe;

  --primary: #268bff;

  pre {
    margin: 0;
  }

  code {
    display: block;
    font-family: 'JetBrains Mono', monospace;
    font-size: var(--font-size-xs);
  }

  .hljs-comment,
  .hljs-quote {
    color: var(--primary-light);
  }

  /* Red */
  .hljs-variable,
  .hljs-template-variable,
  .hljs-tag,
  .hljs-name,
  .hljs-selector-id,
  .hljs-selector-class,
  .hljs-regexp,
  .hljs-deletion {
    color: var(--primary);
  }

  .hljs-attr {
    color: #4fc26a;
  }

  /* Orange */
  .hljs-number,
  .hljs-built_in,
  .hljs-builtin-name,
  .hljs-literal,
  .hljs-type,
  .hljs-params,
  .hljs-meta,
  .hljs-link {
    color: var(--primary);
    opacity: 0.9;
  }

  /* Yellow */
  .hljs-attribute {
    color: var(--primary);
  }

  /* Green */
  .hljs-string,
  .hljs-symbol,
  .hljs-bullet,
  .hljs-addition {
    color: var(--primary);
    font-weight: 600;
  }

  /* Blue */
  .hljs-title,
  .hljs-section {
    color: var(--primary);
  }

  /* Purple */
  .hljs-keyword,
  .hljs-selector-tag {
    color: var(--primary);
  }

  .hljs {
    display: block;
    overflow-x: auto;
    background: transparent;
    padding: 0.5em;
  }

  .hljs-emphasis {
    font-style: italic;
  }

  .hljs-strong {
    font-weight: bold;
  }
}
</style>
