<template>
  <div class="c-form c-form--login">
    <form @submit.prevent="onSubmit">
      <InputEmail
        label="Email"
        v-model="form.email"
        :validation="$v.form.email"
      ></InputEmail>
      <InputPassword
        label="Password"
        v-model="form.password"
        :validation="$v.form.password"
      ></InputPassword>
      <Button :processing="processing" type="submit">Login</Button>
      <!-- <button class="btn btn-primary" type="submit">

      </button> -->
    </form>
  </div>
</template>

<script>
import Button from '@/components/global/button';
import InputEmail from '@/components/global/forms/input-email';
import InputPassword from '@/components/global/forms/input-password';

import { validationMixin } from 'vuelidate';
import { required, email, minLength, between } from 'vuelidate/lib/validators';

export default {
  components: {
    Button,
    InputEmail,
    InputPassword
  },

  mixins: [validationMixin],

  data: function() {
    return {
      processing: false,
      form: {
        email: '',
        password: ''
      }
    };
  },

  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  },

  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.invalid) {
        return;
      }
      this.processing = true;
      let user = await this.$store.dispatch('users/login', this.form);

      if (user) {
        await this.$store.dispatch('afterLogin');
        this.$emit('submit');
      }
      this.processing = false;
    }
  }
};
</script>

<style lang="scss">
.c-form--login {
  .form-control {
    text-align: center;
  }

  .btn {
    margin-top: var(--space-lg);
  }
}
</style>
