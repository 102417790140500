import Vue from 'vue';
import VueRouter from 'vue-router';
import Styleguide from '../views/styleguide';

import People from '@/components/people';
import PeopleCreate from '@/components/people/create';
import PeopleUpdate from '@/components/people/update';

import Organisations from '@/components/organisations';
import OrganisationsCreate from '@/components/organisations/create';
import OrganisationsUpdate from '@/components/organisations/update';
import OrganisationsDrilldown from '@/components/organisations/drilldown';
import OrganisationReport from '@/components/reports/organisation-report';

import Products from '@/components/products';

import Orders from '@/components/orders';
import OrdersCreate from '@/components/orders/create';
import OrdersUpdate from '@/components/orders/update';

import Rctis from '@/components/rctis';
import RctisCreate from '@/components/rctis/create';
import RctisUpdate from '@/components/rctis/update';

import Locations from '@/components/locations';
import LocationsCreate from '@/components/locations/create';
import LocationsUpdate from '@/components/locations/update';

import Notes from '@/components/notes';

import Reports from '@/components/reports';

import CustomForms from '@/components/custom-forms';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/organisations'
  },
  {
    path: '/styleguide',
    name: 'Styleguide',
    component: Styleguide
  },

  {
    path: '/people',
    name: 'People',
    component: People
  },
  {
    path: '/people/create',
    name: 'PeopleCreate',
    component: PeopleCreate
  },
  {
    path: '/people/update/:id',
    name: 'PeopleUpdate',
    component: PeopleUpdate
  },

  {
    path: '/organisations',
    name: 'Organisations',
    component: Organisations
  },
  {
    path: '/organisations/create',
    name: 'OrganisationsCreate',
    component: OrganisationsCreate
  },
  {
    path: '/organisations/update/:id',
    name: 'OrganisationsUpdate',
    component: OrganisationsUpdate
  },
  {
    path: '/organisations/drilldown/:id',
    name: 'OrganisationsDrilldown',
    component: OrganisationsDrilldown
  },
  {
    path: '/organisations/report',
    name: 'OrganisationReport',
    component: OrganisationReport
  },

  {
    path: '/locations',
    name: 'Locations',
    component: Locations
  },
  {
    path: '/locations/create',
    name: 'LocationsCreate',
    component: LocationsCreate
  },
  {
    path: '/locations/update/:id',
    name: 'LocationsUpdate',
    component: LocationsUpdate
  },

  {
    path: '/notes',
    name: 'Notes',
    component: Notes
  },

  {
    path: '/products',
    redirect: '/products/products'
  },
  {
    path: '/products/products',
    name: 'Products',
    component: Products
  },

  {
    path: '/orders/orders',
    name: 'Orders',
    component: Orders
  },
  {
    path: '/orders',
    redirect: '/orders/orders'
  },
  {
    path: '/orders/orders/create',
    name: 'OrdersCreate',
    component: OrdersCreate
  },
  {
    path: '/orders/orders/update/:id',
    name: 'OrdersUpdate',
    component: OrdersUpdate
  },

  {
    path: '/orders/rctis',
    name: 'Rctis',
    component: Rctis
  },
  {
    path: '/orders/rctis/create',
    name: 'RctisCreate',
    component: RctisCreate
  },
  {
    path: '/orders/rctis/update/:id',
    name: 'RctisUpdate',
    component: RctisUpdate
  },

  {
    path: '/orders/reports',
    name: 'Reports',
    component: Reports
  },

  {
    path: '/custom-forms/:slug',
    name: 'CustomForms',
    component: CustomForms,
    meta: {
      layout: 'no-sidebar'
    }
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
