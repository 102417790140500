import CrudStore from '@/lib/crud-store.js';
var crud = new CrudStore('winthropReceipts');

const state = {
  ...crud.state
};

const getters = {
  ...crud.getters,

  byOrganisation: function(state, getters) {
    let resources = getters.filtered;

    return id => {
      let newResources = [];
      // resources.map(resource => {
      //   if (resource.id === id) {
      //     newResources.push(resource);
      //   }
      // });

      return resources; //newResources;
    };
  }
};

const actions = {
  ...crud.actions,

  async doAction(store, obj) {
    let action = obj.action;
    let id = obj.id;
    let response = await crud.api.rctis.doAction(id, action);

    if (response) {
      store.commit('UPDATE', response);
    }
    return response;
  }
};

const mutations = {
  ...crud.mutations
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;
