<template>
  <nav class="c-pagination">
    <a
      v-if="pages.length > 0"
      :class="[
        'c-pagination__link c-pagination__arrow c-pagination__arrow--prev',
        { 'is-disabled': !hasPrev }
      ]"
      href="#"
      @click.prevent="onPrev"
      ><chevron-left-icon size="1.5x" class="custom-class"></chevron-left-icon
    ></a>
    <ol class="c-pagination__pages">
      <li class="c-pagination__page" v-for="page in pages" :key="page.i">
        <a
          :class="['c-pagination__link', { 'is-active': offset === page.i }]"
          href="#"
          @click="onTravel(page.i)"
          >{{ page.i + 1 }}</a
        >
      </li>
    </ol>
    <a
      v-if="pages.length > 0"
      :class="[
        'c-pagination__link c-pagination__arrow c-pagination__arrow--next',
        { 'is-disabled': !hasNext }
      ]"
      href="#"
      @click.prevent="onNext"
      ><chevron-right-icon size="1.5x" class="custom-class"></chevron-right-icon
    ></a>
  </nav>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from 'vue-feather-icons';
export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon
  },

  data: function() {
    return {
      offset: 0
    };
  },

  watch: {
    offset: function() {
      this.$emit('onOffset', this.offset);
    }
  },

  props: {
    count: {
      type: Number,
      default: 0
    },
    limit: {
      type: Number,
      default: 10
    }
  },

  computed: {
    pagesCount: function() {
      let count = this.count;
      let limit = this.limit;

      let len = Math.ceil(count / limit);

      return len;
    },
    pages: function() {
      let len = this.pagesCount;
      let pages = [];

      for (let i = 0; i < len; i++) {
        pages.push({
          i: i
        });
      }

      return pages;
    },
    hasPrev: function() {
      if (this.offset === 0) {
        return false;
      }
      return true;
    },
    hasNext: function() {
      if (this.offset === this.pagesCount - 1) {
        return false;
      }
      return true;
    }
  },

  methods: {
    onPrev: function() {
      if (this.hasPrev) {
        this.offset -= 1;
      }
    },
    onTravel: function(i) {
      this.offset = i;
    },
    onNext: function() {
      if (this.hasNext) {
        this.offset += 1;
      }
    }
  }
};
</script>

<style lang="scss">
.c-pagination {
  display: flex;
  font-size: var(--font-size-xs);
  font-weight: 500;
  margin-left: auto;
}

.c-pagination__pages {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-pagination__page {
  //margin: 0 var(--space-xxs);
}

.c-pagination__link {
  //border-left: 1px solid var(--color-grey-light);
  //border-right: 1px solid var(--color-grey-light);
  //border: 1px solid var(--color-grey-light);
  //border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--space-xl);
  height: var(--space-lg);
  text-align: center;
  padding: var(--space-xxs) var(--space-xs);
  transition: all 120ms linear;

  &,
  &:hover,
  &:active {
    color: var(--color-black);
  }

  &:hover,
  &:active {
    background-color: var(--color-grey-lightest);
    border-color: var(--color-black);
  }

  &:focus {
    text-decoration: none;
  }

  &.is-active {
    background-color: var(--primary);
    color: var(--color-white);
    border-color: var(--color-grey-lightest);
    font-weight: 700;
  }
}

.c-pagination__arrow {
  background-color: var(--color-grey-lightest);
  font-weight: 700;

  &.is-disabled {
    background-color: transparent;
    border-color: var(--color-grey-lighter);
    color: var(--color-grey-lighter);
    font-weight: 400;
    cursor: not-allowed;
  }

  &--prev {
    //margin-right: var(--space-xxs);
  }

  &--next {
    //margin-left: var(--space-xxs);
  }
}
</style>
