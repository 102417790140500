<template>
  <div class="form-group c-input-textarea">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <textarea
      :class="['form-control']"
      :id="slug"
      type="text"
      :disabled="readonly"
      :value="value"
      @change="onChange"
      :placeholder="placeholder"
    ></textarea>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
  </div>
</template>

<script>
import InputError from './input-error';
import InputDescription from './input-description';
import slugify from '@/lib/slugify';

export default {
  components: {
    InputDescription,
    InputError
  },

  props: {
    label: {
      type: String,
      default: ''
    },
    value: {},
    placeholder: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: null
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    slug: function() {
      return slugify(this.label);
    }
  },

  methods: {
    onChange: function(e) {
      if (this.readonly) {
        return;
      }
      let value = e.target.value;

      this.$emit('input', value);
    }
  }
};
</script>

<style lang="scss">
.c-input-textarea {
}
</style>
