let store = {
  namespaced: true,
  state: {
    infoWindow: {
      type: 'lead',
      id: null,
      trigger: false
    }
  },
  getters: {
    init: function(state) {
      return state.init;
    },
    infoWindow: function(state) {
      return {
        type: state.infoWindow.type,
        id: state.infoWindow.id
      };
    },
    infoWindowTrigger: function(state) {
      return state.infoWindow.trigger;
    }
  },
  mutations: {
    INIT: function(state) {
      state.init = true;
    },
    SET_INFOWINDOW: function(state, infoWindow) {
      state.infoWindow.type = infoWindow.type;
      state.infoWindow.id = infoWindow.id;
    },
    SET_INFOWINDOW_TRIGGER: function(state) {
      state.infoWindow.trigger = !state.infoWindow.trigger;
    }
  },
  actions: {
    async init(store) {},

    async afterLogin(store) {},

    async afterLogout(store) {},

    async setInfoWindow(store, infoWindow) {
      store.commit('SET_INFOWINDOW', infoWindow);
      store.commit('SET_INFOWINDOW_TRIGGER');
    }
  },
  modules: {}
};

export default store;
