<template>
  <div class="c-styleguide">
    <h2>Headings</h2>
    <br />
    <h1>H1 Heading</h1>
    <h2>H2 Heading</h2>
    <h3>H3 Heading</h3>
    <h4>H4 Heading</h4>
    <h5>H5 Heading</h5>
    <h6>H6 Heading</h6>
    <hr />
    <p>
      If you have one off revenue (up-sells, courses, or other products) then
      the net revenue could often be higher than its recurring counterpart. For
      ConvertKit the only factor making them materially different is annual
      plans (paying for a year up front in trade for a discount).
    </p>
    <p class="muted">This is muted text.</p>
    <a href="#">A link to somewhere</a>
    <br />
    <h2>Forms</h2>
    <label> Label </label>
    <br />
    <a href="#" class="btn"> Fancy Primary </a><br /><br />
    <a href="#" class="btn btn-lg"> Fancy default </a><br />
    <br />
    <label for="input"> A label </label>
    <input
      class="form-control"
      id="input"
      type="text"
      value="something"
      placeholder="Something else"
    />
    <div class="colors background">
      <span></span><span></span><span></span><span></span><span></span>
    </div>
    <div class="colors foreground">
      <span></span><span></span><span></span><span></span><span></span>
    </div>
    <div class="colors primary">
      <span></span><span></span><span></span><span></span><span></span>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.c-styleguide {
}
</style>
