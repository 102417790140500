<template>
  <div class="c-contact" @click="onClick">
    <Gravatar :email="email" default-img="monsterid"></Gravatar>
    <span>
      {{ name }}
    </span>
  </div>
</template>

<script>
import Gravatar from 'vue-gravatar';

export default {
  components: {
    Gravatar
  },

  props: {
    email: {
      type: String,
      default: 'test@mailinator.com'
    },
    name: {
      type: String,
      default: 'N/A'
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onClick: function() {
      this.$emit('onClick', this.email);
    }
  }
};
</script>

<style lang="scss">
.c-contact {
  display: inline-flex;
  align-items: center;
  padding: var(--margin) var(--margin);

  font-weight: 500;
  line-height: 1;
  color: rgba(0, 0, 0, 0.75);

  border: var(--color-grey-light) solid 1px;
  border-radius: var(--border-radius-sm);

  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
    margin-right: var(--margin);
    border-radius: 50%;
    object-fit: cover;
    object-position: center center;
    background-color: #fff;
  }
}
</style>
