<template>
  <div class="c-item-user">
    <Contact
      v-if="email"
      :email="email"
      :name="name"
      @onClick="onClick"
    ></Contact>
  </div>
</template>

<script>
import Date from '@/components/global/date';

import Contact from '@/components/global/contact';

export default {
  components: {
    Date,
    Contact
  },

  props: {
    resource: {
      type: Object,
      default: function() {
        return {};
      }
    },
    column: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  computed: {
    email: function() {
      if (this.user) {
        return this.user.email;
      }

      return null;
    },
    name: function() {
      let user = this.user;

      let name = `${user.firstName} ${user.lastName}`;

      if (user.initials) {
        name = `${user.initials} ${name}`;
      }

      return name;
    },
    user: function() {
      // Check if resource has email and firstName and lastName components
      if (
        this.resource.email &&
        this.resource.firstName &&
        this.resource.lastName
      ) {
        return this.resource;
      }

      // Otherwise grab the user from the userId field
      let id = this.resource.userId || null;

      if (!id) {
        return;
      }

      let user = this.$store.getters['users/one'](id);

      return user;
    }
  },

  methods: {
    onClick: function() {
      if (this.column.clickable) {
        this.$emit('click', 'view', this.resource.id, this.column.key);
      }
    }
  }
};
</script>

<style lang="scss">
.c-item-user {
  font-size: var(--font-size-sm);

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
