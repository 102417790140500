import CrudStore from '@/lib/crud-store.js';
var crud = new CrudStore('reports');

const state = {
  ...crud.state
};
const getters = {
  ...crud.getters
};
const actions = {
  ...crud.actions,

  async doAction(store, obj) {
    let action = obj.action || 'report';
    let id = obj.id;
    let resource = obj.resource || null;

    let response = await crud.api.reports.doAction(id, action, resource);

    if (response) {
      //store.commit('UPDATE', response);
    }
    return response;
  }
};
const mutations = {
  ...crud.mutations
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;
