<template>
  <div :class="['c-info-window', { active: active === true }]">
    <XIcon @click="onClose" class="c-info-window__close"></XIcon>
    <Header>
      <slot name="header"></slot>
    </Header>
    <Section
      v-for="section in sections"
      :key="section.name"
      :title="section.name"
    >
      <dl v-if="section.name === 'Contact Details'" class="c-info-window-list">
        <component
          :is="resolveComponent(item)"
          v-for="(item, index) in section.items"
          :key="index"
          :item="item"
        >
        </component>
      </dl>
      <div v-else>
        <component
          :is="resolveComponent(item)"
          v-for="(item, index) in section.items"
          :key="index"
          :item="item"
        >
        </component>
      </div>
    </Section>
    <slot name="section"></slot>
  </div>
</template>

<script>
import hotkeys from 'hotkeys-js';

import Header from './header';
import Section from './section';

import ItemText from './item-text';
import ItemNote from './item-note';

import { XIcon } from 'vue-feather-icons';

/**
 * This is a HOC component for info window
 * Use this to create your own info windows
 *
 * Props
 * - sections - Array of Objects
 *   See the data prop default
 *
 * Slots
 * header slot - use this to populate the header area
 */
export default {
  components: {
    Header,
    Section,
    ItemText,
    ItemNote,
    XIcon
  },

  data: function() {
    return {
      active: false
    };
  },

  props: {
    sections: {
      type: Array,
      default: function() {
        return [
          {
            name: 'Contact Details',
            items: [
              {
                label: 'Email Address',
                value: 'johnsmith@copehealth.com.au'
              },
              {
                label: 'Phone number',
                value: '0400 123 333'
              },
              {
                label: 'Organisation name',
                value: 'COPE Health'
              }
            ]
          },
          {
            name: 'Notes',
            items: []
          }
        ];
      }
    }
  },

  methods: {
    resolveComponent: function(item) {
      if (!item.type) {
        return 'ItemText';
      }
      if (item.type && item.type === 'note') {
        return 'ItemNote';
      }

      return 'ItemText';
    },

    onClose: function() {
      //this.active = false;
      this.$emit('onClose');

      // this.$nextTick(() => {
      //   this.$store.dispatch('app/setInfoWindow', {
      //     type: null,
      //     id: null
      //   });
      // });
    },

    onMouseDown: function(e) {
      let el = e.target.closest('.c-info-window');

      if (!el) {
        this.onClose();
      }
    },

    setup: function() {
      hotkeys('esc', 'info-window', e => {
        this.onClose();
      });

      hotkeys.setScope('info-window');

      window.addEventListener('mousedown', this.onMouseDown);
    },

    activate: function() {
      this.active = true;
    },

    deactivate: function() {
      this.active = false;
    }
  },

  mounted: function() {
    this.setup();
  },
  beforeDestroy: function() {
    hotkeys.deleteScope('info-window');
    window.removeEventListener('mousedown', this.onMouseDown);
  }
};
</script>

<style lang="scss">
.c-info-window {
  background-color: var(--color-white);
  border-radius: var(--border-radius-xl) 0 0 var(--border-radius-xl);
  box-shadow: 0 0 15px #00000029;
  height: 100%;
  padding: var(--space-xl);
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform var(--transition-time) cubic-bezier(0.19, 1, 0.22, 1);
  width: 640px;
  z-index: var(--z-3);

  &.active {
    transform: translateX(0);
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: var(--space-xl);
    top: var(--space-xl);
  }

  &-list {
    margin: 0 0 var(--space-sm);
    overflow: hidden;
  }

  &-list__title {
    clear: left;
    float: left;
    margin-top: var(--space-xs);

    &::after {
      content: ':';
    }
  }

  &-list__content {
    float: left;
    font-weight: 600;
    margin: var(--space-xs) 0 0 var(--space-sm);
  }
}
</style>
