import { statuses } from '@/lib/form-data';

const schema = [
  {
    type: 'accordion',
    label: 'Order Details',
    fields: {
      referenceNumber: {
        component: 'InputText',
        label: 'Reference number',
        description: 'Enter the reference number from the Xero invoice.',
        properties: {
          columnSize: 12
        }
      },
      orders: {
        component: 'InputOrders',
        label: 'Orders'
      }
    }
  }
];

export default schema;
