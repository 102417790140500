<template>
  <div class="c-leads-create">
    <BreadCrumb></BreadCrumb>
    <h1>
      Add New Order
    </h1>
    <FormCreate></FormCreate>
  </div>
</template>

<script>
import BreadCrumb from '@/components/global/breadcrumb';
import FormCreate from './form-create';

export default {
  components: {
    BreadCrumb,
    FormCreate
  }
};
</script>

<style lang="scss">
.c-leads-create {
}
</style>
