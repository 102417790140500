<template>
  <div class="c-layout-default">
    <Sidebar></Sidebar>
    <User />
    <div class="c-layout-default__inner">
      <router-view />
    </div>
  </div>
</template>

<script>
import User from '@/components/global/user';
import Sidebar from '@/components/sidebar';
export default {
  components: {
    User,
    Sidebar
  }
};
</script>

<style lang="scss">
.c-layout-default {
  display: flex;
  padding: var(--space-xl) var(--space-xl) var(--space-lg);
  overflow-y: auto;
  min-height: 100%;

  &__inner {
    background-color: var(--color-white);
    border-radius: var(--border-radius-xl);
    box-shadow: var(--box-shadow-dark);
    flex-grow: 1;
    padding: var(--space-xl);
    position: relative;
    width: calc(100vw - var(--sidebar-width) - var(--space-xl));
  }

  .c-user {
    //margin-top: calc(var(--space-xl) * -1);
  }
}
</style>
