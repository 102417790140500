<template>
  <div
    :class="[
      'form-group c-input-datetimepicker',
      { disabled: readonly === true }
    ]"
  >
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>

    <vc-date-picker v-model="date" mode="date" :input-debounce="500">
      <template v-slot="{ inputValue, inputEvents }">
        <input
          class="form-control"
          :id="slug"
          type="text"
          :value="inputValue"
          v-on="inputEvents"
          :placeholder="placeholder"
        />
      </template>
    </vc-date-picker>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
  </div>
</template>

<script>
import InputError from './input-error';
import InputDescription from './input-description';
import slugify from '@/lib/slugify';

export default {
  components: {
    InputDescription,
    InputError
  },

  data: function() {
    return {
      date: new Date()
    };
  },

  props: {
    label: {
      type: String,
      default: ''
    },
    value: {},
    placeholder: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: null
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    modelConfig: function() {
      return {
        type: 'string',
        mask: 'iso'
      };
    },
    slug: function() {
      return slugify(this.label);
    }
  },

  methods: {
    onClick: function() {},
    onChange: function(e) {
      let value = e.target.value;

      this.$emit('input', value);
    },
    getDateByDays: function(days = 7) {
      let date = new Date();

      let val = date.valueOf();

      let newDate = new Date(val);

      newDate.setDate(newDate.getDate() + days);

      return newDate;
    }
  },

  mounted: function() {
    //let date = this.getDateByDays(7);
    //this.date = date;
  }
};
</script>

<style lang="scss">
.c-input-datetimepicker {
  &.disabled {
    .form-control {
      pointer-events: none;
      background-color: var(--color-bg-3);
    }
  }
}
</style>
