let store = {
  namespaced: true,
  state: {
    messages: {
      serverError: {
        message: 'Something went wrong server side',
        status: 'danger'
      },
      clientError: {
        message: "Couldn't handle your request",
        status: 'warning'
      },
      genericError: {
        message: 'Something went wrong',
        status: 'warning'
      }
    },

    message: null,
    messageTrigger: false
  },
  getters: {
    message: function(state) {
      return state.message;
    },
    messageTrigger: function(state) {
      return state.messageTrigger;
    }
  },
  mutations: {
    SET_MESSAGE: function(state, messageObject) {
      state.message = messageObject;
    },
    TRIGGER: function(state) {
      state.messageTrigger = !state.messageTrigger;
    }
  },
  actions: {
    async init(store) {},

    async sendMessage(store, name) {
      if (!store.rootGetters['users/isAuth']) {
        return;
      }
      let message = null;
      if (typeof name === 'string') {
        message = store.state.messages[name];
      } else {
        message = name;
      }
      store.commit('SET_MESSAGE', message);
      store.commit('TRIGGER');
    },

    // 400, 401, etc
    async onServerError(store, err) {
      store.dispatch('sendMessage', 'serverError');
    },

    // timeout, etc
    async onClientError(store, err) {
      store.dispatch('sendMessage', 'clientError');
    },

    // When yolo
    async onGenericError(store, err) {
      store.dispatch('sendMessage', 'genericError');
    },

    async onResourceUpdate(store, str) {
      store.dispatch('sendMessage', {
        message: str,
        status: 'success'
      });
    },

    async onLogin(store) {
      let user = store.rootGetters['users/user'];
      let message = {
        message: `Welcome back ${user.firstName} ${user.lastName}`
      };
      store.dispatch('sendMessage', message);
    },

    async onSignup(store) {
      let user = store.rootGetters['users/user'];
      let message = {
        message: `Hey ${user.name}!`
      };
      store.dispatch('sendMessage', message);
    },

    async onLogout(store) {
      let user = store.rootGetters['users/user'];
      let message = {
        message: `See you soon ${user.name}`
      };
      store.dispatch('sendMessage', message);
    }
  }
};

export default store;
